import React, { useEffect, useState } from 'react';
import {
  TextField,
} from '@material-ui/core';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { api_call, empty, getLocalTimezone } from '../helpers/untils';

const LOCAL_TIMEZONE = getLocalTimezone()

const TimezoneAutoCompleteDropdown = (props) => {
  const { timezone = LOCAL_TIMEZONE, setTimezone, label = "Timezone", handleChangeTimezone } = props

  // useEffect(()=>{
  //   if(empty(timezone)) {
  //     setTimezone(LOCAL_TIMEZONE)
  //   }
  // }, [timezone])

  const loadTimezoneList = async () => {
    const response = await api_call('/api/timezone-list/all');
    if (response.ok) {
      const data = await response.json()
      const timezoneList = data['timezoneList']
      initTimezoneOptions(timezoneList)
    }
  }

  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const initTimezoneOptions = (timezoneList) => {
    let v_options = []
    timezoneList.map((definition) => {
      v_options.push(definition.timezone)
    })
    setTimezoneOptions(v_options)
    setTimezone(LOCAL_TIMEZONE)
  }

  useEffect(() => {
    loadTimezoneList()
    //setTimezoneOptions(getTimezoneList())

  }, [])

  const timezoneFilter = createFilterOptions({
    stringify: (option) => option,
  });

  const getTimezoneLabel = (timezoneInfo, returnType = "text") => {
    if (returnType === "object") {
      return (
        <div>
          <div>{`${timezoneInfo}`}</div>
        </div>
      )
    } else {
      return timezoneInfo
    }
  }

  const onChangeTimezone = (option) => {
    setTimezone(option);
    if (typeof handleChangeTimezone === 'function') {
      if (option) {
        handleChangeTimezone(option)
      }
    }
  }

  return (
    <>
      <Autocomplete
        onOpen={() => {
          setTimeout(() => {
            const optionEl = document.querySelector(
              `[class="MuiAutocomplete-option"][data-option-index="${timezoneOptions.indexOf(timezone)}"]`
            );
            if (optionEl) {
              optionEl.scrollIntoView();
            }
          }, 1);
        }}
        onChange={(_, option) => {
          console.log("option::::", option)
          onChangeTimezone(option)
        }}
        filterOptions={(options, params) => {
          const filtered = timezoneFilter(options, params);
          return filtered;
        }}
        options={timezoneOptions}
        getOptionLabel={(option) => getTimezoneLabel(option, "text")}
        renderOption={(option) => getTimezoneLabel(option, "text")}
        value={timezone}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={`${label}`} variant="filled" margin="normal" />
        )}
      />
    </>
  );
};

export default TimezoneAutoCompleteDropdown;
