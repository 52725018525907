import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Modal, Snackbar, makeStyles, Button, IconButton, Grid, TextField, Link } from '@material-ui/core';
import { Stack } from '@mui/material';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import CloseIcon from '@material-ui/icons/Close';
import { api_call } from '../helpers/untils';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import UnlimitedTuroCalTextBoxList from './unlimitedTuroCalTextBoxList';
import { APP_NAME, SHRED_TURO_VIDEO_ID } from '../config/constant';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '660px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  formContainer: {
    display: 'block',
    width: '100%',
  }

}));

const TuroCalendarInputModal = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const { open, setOpen, selectedDeviceId, selectedDevice, showToast, pageData = {}, setPageData } = props;

  const [apiLoading, setApiLoading] = useState(false)

  const handleModalClose = () => {
    setOpen("")
  }

  const defaultFormData = {
    deviceId: selectedDeviceId
  }
  const [formData, setFormData] = useState(defaultFormData)
  const onChangeFormData = (values, field_name) => {
    let form_data = { ...formData }
    form_data[field_name] = values
    setFormData(form_data)
  }

  const onClickSaveSetting = () => {
    //setOpenConfirmModal(true)
    saveSetting()
  }

  const saveSetting = async () => {
    setApiLoading(true)
    const url = `/api/links/saveTuroCalLink`;
    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });
    setApiLoading(false)

    if (response.ok) {
      const apiRes = await response.json()
      const turoCalList = apiRes.turoCalList
      const page_data = {
        ...pageData,
        turoCalList: turoCalList
      }
      setPageData(page_data)
    }

    showToast("Turo cal url has been saved successfully")
    handleModalClose()
  }

  const [videoOpen, setVideoOpen] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  //////////////////////// app modal close feature //////////////////////////////////////////
  const appModalCloseTimestamp = useSelector((state) => state.layout.appModalCloseTimestamp);
  useEffect(() => {
    if (appModalCloseTimestamp) {
      setOpen(false)
    }
  }, [appModalCloseTimestamp]);
  ///////////////////////////////////////////////////////////////////////////////////////////

  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);

  const handleConfirmClose = () => {
    setOpenConfirmModal(false);
  };

  const handleConfirmOk = () => {
    setOpenConfirmModal(false);
    //saveSetting()
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={1500}
            message={toastMessage}
          />
          <Stack direction={`row`} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">{`Turo Setup`}</Typography>
            {/* <IconButton size="small" onClick={() => handleModalClose()}><CloseIcon /></IconButton> */}
          </Stack>
          <Box sx={{ paddingTop: 10 }}>
            <Grid container spacing={2} justifyContent="flex-start">
              <Grid item xs={12} sm={12}>
                {
                  (pageData && pageData['turoCalList']) ? (
                    <>
                      <UnlimitedTuroCalTextBoxList
                        defaultName={`turo_cal`}
                        defaultCount={1}
                        defaultValues={pageData['turoCalList']}
                        setValues={(values) => onChangeFormData(values, 'turo_cal')}
                        showAddButton={user.administrator ? false : true}
                      />
                    </>
                  ) : (
                    <></>
                  )
                }
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography variant='p' onClick={() => setVideoOpen(true)} style={{ cursor: 'pointer', textDecoration: 'underline', color: '#1366ff' }}>How To Video</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Stack direction={`row`} justifyContent={`center`}  >
                  <Link href="https://www.youtube.com/results?search_query=auto+forward+emails" target="_blank" underline='none'>
                    Auto Forward all emails from <span style={{ fontWeight: 500 }}>noreply@mail.turo.com</span> to <span style={{ fontWeight: 500 }}>trip@turo.moovetrax.com</span>
                  </Link>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Stack direction={`row`} spacing={1} sx={{ justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={() => onClickSaveSetting()}
                  >
                    Submit
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    size="large"
                    onClick={() => handleModalClose()}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      <React.Fragment>
        {
          (videoOpen && SHRED_TURO_VIDEO_ID !== "") ? (
            <>
              <ModalVideo
                channel='youtube'
                autoplay={1}
                youtube={{
                  autoplay: 1,
                  mute: 1
                }}
                isOpen={videoOpen}
                videoId={SHRED_TURO_VIDEO_ID}
                onClose={() => setVideoOpen(false)}
              />
            </>
          ) : (<></>)
        }

      </React.Fragment>

      <>
        <Dialog open={openConfirmModal} onClose={handleConfirmOk}>
          <DialogTitle>{APP_NAME}</DialogTitle>
          <DialogContent>
            <DialogContentText color='primary'>
              Calendar updates take 4 hours
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmOk}>OK</Button>
          </DialogActions>
        </Dialog>
      </>

    </>
  );
};

export default TuroCalendarInputModal;
