import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Checkbox, Box, Link, Modal, Grid, Toolbar, IconButton, InputLabel, Select, MenuItem, ButtonBase,
} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditAttributesView from './attributes/EditAttributesView';
import SelectField from './form/SelectField';
import deviceCategories from './common/deviceCategories';
import LinkField from './form/LinkField';
import { prefixString } from './common/stringUtils';
import { useTranslation } from './LocalizationProvider';
import useDeviceAttributes from './attributes/useDeviceAttributes';
import { Button } from '@material-ui/core';
import CreditLogsModal from './settings/CreditLogsModal';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import CarTypeTab from './components/CarTypeTab';
import SmartcarDeviceEditPage from './SmartcarDeviceEditPage';
import LockUnlockSettingModal from './settings/LockUnlockSettingModal';
import { addItemToArray, api_call, console_log, empty, get_utc_timestamp_ms, isGpsMT2VProtocolDevice, is_null, removeItemFromArray } from './helpers/untils';
import { APP_NAME, DEVICE_TYPE, DISTANCE_UNIT_LIST, GPS_ID_PLACEHOLDER, ICCID_PLACEHOLDER, IMAGE_CACHE_VERSION, TESLA_PERMISSION_URL } from './config/constant';
import ConfirmDialog from './components/ConfirmDialog';
import { Stack } from '@mui/material';
import { getIsAdmin } from './common/selectors';
import SearchIcon from '@material-ui/icons/Search';
import PaypalPaymentModal from './settings/PaypalPaymentModal';
import { layoutActions } from './store';
import TeslaQrCodeModal from './settings/TeslaQrCodeModal';
import TransferServiceModal from './settings/TransferServiceModal';
import CarTypeTabBar from './components/CarTypeTabBar';
import TeslaDeviceEditPage from './TeslaDeviceEditPage';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  checkboxColumn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  imgSmall: {
    width: '50px',
    height: '42px',
    marginLeft: '5px',
    marginTop: '16px',
    marginBottom: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  normalColumn: {
    padding: '8px 0px'
  },
  normalColumnMargin: {
    marginTop: '4px'
  }
}));

const DevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { id } = useParams();
  const history = useHistory()
  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);

  const deviceAttributes = useDeviceAttributes(t);
  const user = useSelector((state) => state.session.user);
  const [item, setItem] = useState();
  const randomStr = (len) => {
    var ans = '';
    const arr = '0123456789abcdefghijklmnopqrstuvwxyz';
    for (var i = len; i > 0; i--) {
      ans +=
        arr[Math.floor(Math.random() * arr.length)];
    }
    return ans;
  }

  ////////////////////////////////start credit log modal////////////////////////////////
  const showCreditLogsPage = () => {
    setCreditModalOpen(true)
  }
  const [creditModalOpen, setCreditModalOpen] = useState(false);
  /////////////////////////////////end credit modal///////////////////////////////////////////

  const getCarColorListData = () => {
    const carColorList = ['Black', 'White', 'Brown', 'Gray', 'Red', 'Blue', 'Green', 'Gold', 'Silver', 'Yellow']
    const carColorSortList = carColorList.sort();
    let carColorListData = [];
    for (let i in carColorSortList) {
      carColorListData.push({ id: carColorSortList[i], name: carColorSortList[i] })
    }
    return carColorListData;
  }

  const [showTooltip, setShowTooltip] = useState(true) //todo quan

  const iccidImage = `/images/iccid/large1.jpg?v=${IMAGE_CACHE_VERSION}`
  const gpsidImage = `/images/gpsid/large1.jpg?v=${IMAGE_CACHE_VERSION}`
  const [isOpen, setIsOpen] = useState('');
  const [currentImage, setCurrentImage] = useState('');

  ///////////////////////////// for new protocol device //////////////////////////////////////////////////////////
  const [showLockUnlockModal, setShowLockUnlockModal] = useState(false)
  const onClickLockUnlockSetting = () => {
    setShowLockUnlockModal(true)
  }

  const getDeviceDetail = async (deviceId) => {
    let url = `/api/devices/${deviceId}`
    let response = await api_call(url, {
      method: 'GET',
    });

    if (response.ok) {
      const apiRes = await response.json()
      const deviceInfo = apiRes
      return deviceInfo
    } else {
      return false
    }
  }

  const getOpenVoltageConfirmText = (msg, volt) => {
    return (
      <Stack direction={`column`} spacing={2}>
        <Typography variant="body1">{msg}</Typography>
        {
          !is_null(volt) ? (
            <Typography variant="h6">{volt} v</Typography>
          ) : (
            <></>
          )
        }
      </Stack>
    )
  }

  const onClickDoorOpenVoltage = async () => {
    const deviceInfo = await getDeviceDetail(item.id)
    if (deviceInfo) {
      setConfirmAction('door_voltage')
      const msg = "Has the Door been open for 5 or more minutes?"
      setConfirmText(getOpenVoltageConfirmText(msg, deviceInfo?.mt2v_door_volt))
      setShowConfirmModal(true)
      // setCarVoltage("door_voltage")
    }
  }

  const onClickHoodOpenVoltage = async () => {
    const deviceInfo = await getDeviceDetail(item.id)
    if (deviceInfo) {
      setConfirmAction('hood_voltage')
      const msg = "Has the Hood been open for 5 or more minutes?"
      setConfirmText(getOpenVoltageConfirmText(msg, deviceInfo?.mt2v_hood_volt))
      setShowConfirmModal(true)
      // setCarVoltage("hood_voltage")
    }
  }

  const onClickFuelMinVoltage = async () => {
    const deviceInfo = await getDeviceDetail(item.id)
    if (deviceInfo) {
      setConfirmAction('fuel_min_voltage')
      const msg = "Has the fuel been Quarter for more than 5 minutes?"
      const fuelVal = Number(deviceInfo?.fuel) / 10
      setConfirmText(getOpenVoltageConfirmText(msg, Number(fuelVal.toFixed(1))))
      setShowConfirmModal(true)
    }
  }

  const onClickFuelMaxVoltage = async () => {
    const deviceInfo = await getDeviceDetail(item.id)
    if (deviceInfo) {
      setConfirmAction('fuel_max_voltage')
      const msg = "Has the fuel been Full for more than 5 minutes?"
      const fuelVal = Number(deviceInfo?.fuel) / 10
      setConfirmText(getOpenVoltageConfirmText(msg, Number(fuelVal.toFixed(1))))
      setShowConfirmModal(true)
    }
  }

  const [apiCalling, setApiCalling] = useState([])

  const apiLoading = (api_type, status) => {
    let api_calling = [...apiCalling]
    if (status) {
      api_calling = addItemToArray(api_calling, api_type)
    } else {
      api_calling = removeItemFromArray(api_calling, api_type)
    }
    setApiCalling(api_calling)
  }

  const setCarVoltage = async (type) => {
    apiLoading(type, true)

    let url = `/api/device/set-device-voltage`;
    const payload = {
      type: type,
      device_id: item.id,
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    apiLoading(type, false)

    if (response.ok) {
      let responseObj = await response.json()
      alert(`${responseObj.message}`)
    } else {
      alert("Error encounted")
    }
  }

  const onClickAllUserCarABI = async (type) => {
    apiLoading(type, true)

    let url = `/api/users/set-user-devices-abi`;
    const payload = {
      abi: item.abi
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    apiLoading(type, false)

    if (response.ok) {
      let responseObj = await response.json()
      alert(`${responseObj.message}`)
    } else {
      alert("Error encounted")
    }
  }

  const onClickAllUserCarTintAi = async (type) => {
    apiLoading(type, true)

    let url = `/api/users/set-user-devices-tint-ai`;
    const payload = {
      tint_ai: item.tint_ai
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    apiLoading(type, false)

    if (response.ok) {
      let responseObj = await response.json()
      alert(`${responseObj.message}`)
    } else {
      alert("Error encounted")
    }
  }
  ///////////////////////////// confirmation ///////////////////////////////////
  const [confirmAction, setConfirmAction] = useState('')
  const [confirmText, setConfirmText] = useState("")
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const onClickAgreeConfirm = () => {
    if (confirmAction) {
      if (confirmAction === 'pay_balance') {
        onClickPaypalPayment()
      } else {
        setCarVoltage(confirmAction)
      }
      setShowConfirmModal(false)
    }
  }
  const onClickCancelConfirm = () => {
    setShowConfirmModal(false)
  }

  const onChangeAbiCheckbox = (event) => {
    const checked = event.target.checked
    // if(checked) {
    //   if(empty(item.vin)) {
    //     alert("please update your VIN.")
    //     return false
    //   }
    // }
    setItem({ ...item, abi: checked })
  }

  // const [allAbiChecked, setAllAbiChecked] = useState(false)
  // const onChangeAllAbiCheckbox = (event) => {
  //   const checked = event.target.checked
  //   setAllAbiChecked(checked)
  //   setItem({ ...item, allAbiChecked: checked })
  // }

  const onChangeTintAiCheckbox = (event) => {
    const checked = event.target.checked
    // if(checked) {
    //   if(empty(item.vin)) {
    //     alert("please update your VIN.")
    //     return false
    //   }
    // }
    setItem({ ...item, tint_ai: checked })
  }

  const onClickGoUserDevicePage = () => {
    let searchKey = item.uniqueId
    if (item.deviceType === DEVICE_TYPE.SMARTCAR) {
      searchKey = item.vin
    }
    if (item.deviceType === DEVICE_TYPE.TESLA) {
      searchKey = item.vin
    }
    if (searchKey) {
      const url = `/admin/users?search=${searchKey}`
      history.push(url)
    }
  }

  //////////////////////////////////////////////////////////////////////////////////
  const [iccidPrefixList, setIccidPrefixList] = useState([])
  const getPageData = async () => {
    if (id) {
      return false
    }
    if (isAdmin) {
      return false
    }

    let url = `/api/get-iccid-prefix-list`
    let response = await api_call(url, {
      method: 'GET',
    });

    if (response.ok) {
      const apiRes = await response.json()
      setIccidPrefixList(getIccidPrefixListOptions(apiRes['iccidPrefixList']))
      const lastIccidPrefix = apiRes['iccidPrefixList'][0]
      setItem({ ...item, iccid_prefix: lastIccidPrefix?.iccid_prefix ?? "" })
    } else {
      return false
    }
  }
  useEffect(() => {
    getPageData()
  }, [])

  const getIccidPrefixListOptions = (iccid_prefix_ist) => {
    const iccidPrefixListOptions = []
    for (let i in iccid_prefix_ist) {
      iccidPrefixListOptions.push({ id: iccid_prefix_ist[i]['iccid_prefix'], name: iccid_prefix_ist[i]['iccid_prefix'] })
    }
    console_log(`iccidPrefixListOptions:::`, iccidPrefixListOptions)
    return iccidPrefixListOptions;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////
  const showToast = (msg) => {
    alert(`${msg}`)
  }


  const onClickCancelService = () => {
    const credit = item.credit
    console_log(`onClickCancelService, credit:::`, credit)
    if (credit > 0) {
      showPayBalancePopup()
    } else {
      if (window.confirm('Are you sure')) {
        callCancelServiceApi()
      }
    }
  }

  const onClickPayBalance = () => {
    console_log(`onClickPayBalance :::`)
    onClickPaypalPayment()
    setShowConfirmModal(false)
  }

  const getPayBalancePopupText = () => {
    return (
      <Stack direction={`column`} spacing={2} style={{ minWidth: '250px' }}>
        <Typography variant="body1">Please Pay Balance <ButtonBase disableRipple={true} onClick={() => onClickPayBalance()} style={{ color: '#dc004e' }}>${item.credit}</ButtonBase></Typography>
      </Stack>
    )
  }

  const showPayBalancePopup = async () => {
    setConfirmAction('pay_balance')
    setConfirmText(getPayBalancePopupText())
    setShowConfirmModal(true)
  }

  //////////////////////////////////////paypal payment ////////////////////////////////////////////////
  const [paypalPaymentModalOpen, setPaypalPaymentModalOpen] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  const onClickPaypalPayment = () => {
    setPaypalPaymentModalOpen(true)
  }

  const paymentCallback = (result, apiRes) => {
    if (result === 'success') {
      if (apiRes && apiRes['device']) {
        const newDevice = apiRes['device']
        setItem({ ...item, credit: newDevice.credit })
        callCancelServiceApi()
      }
      //showToast("Paid successfully!")
    } else {
      showToast("Paypal payment error!")
    }
  }

  const callCancelServiceApi = async () => {
    const type = 'cancel-service'
    apiLoading(type, true)
    let url = "/api/devices/cancel-service"
    let postData = {
      deviceId: item['id'],
    }

    let response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    });
    apiLoading(type, false)

    if (response.ok) {
      dispatch(layoutActions.setDeviceReloadTimestamp({ value: get_utc_timestamp_ms() }));
      history.push(`/main`)
    } else {
      showToast(`Failed to cancel service`)
    }
    setPaypalPaymentModalOpen(false)
  }

  const [openTeslaQrCodeModal, setOpenTeslaQrCodeModal] = useState(false)
  const showTeslaQrCodeModal = () => {
    setOpenTeslaQrCodeModal(true)
  }

  const onOkTeslaQrCodeModal = () => {
    setOpenTeslaQrCodeModal(false)
  }

  const onClickTransferService = () => {
    showTransferServiceModal();
  }

  const [openTransferServiceModal, setOpenTransferServiceModal] = useState(false)
  const showTransferServiceModal = () => {
    setOpenTransferServiceModal(true)
  }

  const onOkTransferServiceModal = () => {
    setOpenTransferServiceModal(false)
  }

  const childrenAfter = (
    <>
      {
        (item && item.id && !user.administrator) ? (
          <>
            {
              (item.deviceType === DEVICE_TYPE.TESLA) ? (
                <>
                  <Box sx={{ width: '100%', pb: 2 }}>
                    <Stack direction={`row`} justifyContent={`space-between`} alignItems={`center`} spacing={1}>
                      <Button
                        type="button"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        href={TESLA_PERMISSION_URL}
                        target="_blank"
                      >
                        Tesla Permissions
                      </Button>
                      <ButtonBase onClick={() => showTeslaQrCodeModal()} disableRipple={true}>
                        <img src="/assets/img/tesla_qr_code.png" width="50" style={{ maxWidth: '100%', height: 'auto' }} alt="qr code" />
                      </ButtonBase>
                    </Stack>
                  </Box>
                </>
              ) : (
                <></>
              )
            }
            <Box sx={{ width: '100%', pb: 1, pt: 1 }}>
              <Stack direction={`row`} justifyContent={`center`} alignItems={`center`}>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  style={{ backgroundColor: '#fff', border: '1px solid #dc004e', color: '#dc004e' }}
                  onClick={() => onClickTransferService()}
                >
                  Transer Services
                </Button>
              </Stack>
            </Box>
            <Box sx={{ width: '100%', pb: 2 }}>
              <Stack direction={`row`} justifyContent={`center`} alignItems={`center`}>
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  style={{ backgroundColor: '#dc004e' }}
                  disabled={apiCalling.includes("cancel-service")}
                  onClick={() => onClickCancelService()}
                >
                  Cancel Service
                </Button>
              </Stack>
            </Box>
          </>
        ) : (
          <></>
        )
      }
    </>
  )

  return (
    <>
      <EditItemView endpoint="devices" item={item} setItem={setItem} endpointParam1={item?.deviceType} childrenAfter={childrenAfter}>
        {item
          && (
            <>
              <Accordion defaultExpanded>
                {
                  (item.id && item.deviceType === DEVICE_TYPE.SMARTCAR) ? (
                    <>
                      <SmartcarDeviceEditPage
                        id={id}
                        item={item}
                        setItem={setItem}
                        showCreditLogsPage={showCreditLogsPage}
                        getCarColorListData={getCarColorListData}
                        randomStr={randomStr}
                        t={t}
                        onClickGoUserDevicePage={() => onClickGoUserDevicePage()}
                      />
                    </>
                  ) : (item.id && item.deviceType === DEVICE_TYPE.TESLA) ? (
                    <>
                      <TeslaDeviceEditPage
                        id={id}
                        item={item}
                        setItem={setItem}
                        showCreditLogsPage={showCreditLogsPage}
                        getCarColorListData={getCarColorListData}
                        randomStr={randomStr}
                        t={t}
                        onClickGoUserDevicePage={() => onClickGoUserDevicePage()}
                      />
                    </>
                  ) : (
                    <>
                      {
                        (item && item['id']) ? (<></>) : (
                          <CarTypeTabBar
                            currentPage="device"
                          />
                        )
                      }

                      <AccordionDetails>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                          <Typography variant="subtitle1">
                            GPS
                          </Typography>
                          {(item && item['id']) ? (
                            <Button variant="text" onClick={() => { showCreditLogsPage() }}>Balance:&nbsp;<span>{item.credit || '0.00'}</span></Button>
                          ) : (
                            <Button variant="text">Balance:&nbsp;<span>0.00</span></Button>
                          )}

                          <TextField
                            id="outlined-required"
                            label="Monthly ($)"
                            value={item.monthly_cost || '15.00'}
                            onChange={(event) => setItem({ ...item, monthly_cost: event.target.value })}
                            type="number"
                            inputProps={{ step: 0.01 }}
                            style={{ width: "100px" }}
                            disabled={!user.administrator && "true"}
                          />
                        </Box>
                      </AccordionDetails>
                      <AccordionDetails className={classes.details}>
                        <TextField
                          //required
                          margin="normal"
                          value={item.name || ''}
                          onChange={(event) => setItem({ ...item, name: event.target.value })}
                          label="CAR Name"
                          variant="filled"
                          InputLabelProps={{
                            style: {
                              fontStyle: 'italic',
                            }
                          }
                          }
                        />
                        <div item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                          <TextField
                            required
                            fullWidth
                            margin="normal"
                            value={item.uniqueId || ''}
                            onChange={(event) => {
                              const alphanumericRegex = /^[a-zA-Z0-9]*$/;
                              if (alphanumericRegex.test(event.target.value)) {
                                setItem({ ...item, uniqueId: event.target.value })
                              }
                            }}
                            label="GPS ID"
                            placeholder={GPS_ID_PLACEHOLDER}
                            variant="filled"
                            disabled={id && !user.administrator && "true"}
                            InputLabelProps={{
                              style: {
                                fontStyle: 'italic',
                              }
                            }}
                            inputProps={{ style: { textTransform: "uppercase" } }}
                          />
                          {
                            (user.administrator) ? (
                              <>
                                {
                                  (item?.id) ? (
                                    <div style={{ paddingLeft: '8px', paddingTop: '12px' }}>
                                      <Stack direction={`row`} justifyContent={`center`} alignItems={`center`} spacing={2}>
                                        <IconButton size={`medium`} type="button" onClick={() => onClickGoUserDevicePage()}>
                                          <SearchIcon />
                                        </IconButton>

                                        <>
                                          {
                                            (item?.firmwareVersion) ? (
                                              <Tooltip
                                                arrow
                                                title="Firmware version"
                                              >
                                                <span>{item.firmwareVersion}</span>
                                              </Tooltip>
                                            ) : (<></>)
                                          }
                                        </>
                                      </Stack>
                                    </div>
                                  ) : (
                                    <></>
                                  )
                                }
                              </>
                            ) : (
                              <>
                                <img className={classes.imgSmall}
                                  src={`/images/gpsid/small1.jpg?v=${IMAGE_CACHE_VERSION}`}
                                  alt="How to get GPSID"
                                  title="How to get GPSID"
                                  onClick={() => {
                                    setCurrentImage(gpsidImage)
                                    setIsOpen(true)
                                  }}
                                />
                              </>
                            )
                          }
                        </div>

                        {
                          (user.administrator) ? (
                            <>
                              {id && <TextField
                                margin="normal"
                                value={item.iccid || ''}
                                // onChange={(event) => setItem({ ...item, iccid: event.target.value })}
                                label="ICCID"
                                variant="filled"
                                disabled="true"
                              />}
                            </>
                          ) : (
                            <>
                              <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                {
                                  empty(id) ? (
                                    <FormControl fullWidth required margin="normal" variant="filled" style={{ marginRight: '4px', flex: 1 }}>
                                      <InputLabel>{'ICCID Prefix'}</InputLabel>
                                      <Select
                                        value={item.iccid_prefix}
                                        onChange={(event) => setItem({ ...item, iccid_prefix: event.target.value })}
                                      >
                                        {iccidPrefixList.map((item) => (
                                          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  ) : (
                                    <></>
                                  )
                                }

                                <TextField
                                  required
                                  margin="normal"
                                  fullWidth
                                  value={item.iccid || ''}
                                  onChange={(event) => setItem({ ...item, iccid: event.target.value.substring(0, 9) })}
                                  label="ICCID"
                                  placeholder={ICCID_PLACEHOLDER}
                                  variant="filled"
                                  style={{ flex: 1 }}
                                  disabled={id && "true"}
                                  inputProps={{ style: { textTransform: "none" }, type: "number", min: 0 }}
                                />
                                <img className={classes.imgSmall}
                                  src={`/images/iccid/small1.jpg?v=${IMAGE_CACHE_VERSION}`}
                                  alt="How to get ICCID"
                                  title="How to get ICCID"
                                  onClick={() => {
                                    setCurrentImage(iccidImage)
                                    setIsOpen(true)
                                  }}
                                />
                              </Grid>
                            </>
                          )
                        }

                        <TextField
                          margin="normal"
                          value={item.vin || ''}
                          onChange={(event) => setItem({ ...item, vin: event.target.value.toUpperCase() })}
                          label="VIN"
                          variant="filled"
                          disabled={item.deviceType === DEVICE_TYPE.SMARTCAR || item.deviceType === DEVICE_TYPE.TESLA}
                        />
                        <TextField
                          margin="normal"
                          value={item.license_tag || ''}
                          onChange={(event) => setItem({ ...item, license_tag: event.target.value })}
                          label="License Tag"
                          variant="filled"
                        />
                        {/* <SelectField
                          margin="normal"
                          value={item.distance_unit || 'mile'}
                          emptyValue={null}
                          onChange={(event) => setItem({ ...item, distance_unit: event.target.value })}
                          data={DISTANCE_UNIT_LIST.map((unit_item) => ({
                            id: unit_item.id,
                            name: unit_item.name,
                          }))}
                          label={`Unit`}
                          variant="filled"
                        /> */}
                        <TextField
                          margin="normal"
                          value={item.odometer ? Number(item.odometer).toFixed(0) : ''}
                          onChange={(event) => setItem({ ...item, odometer: event.target.value })}
                          label="Odometer"
                          variant="filled"
                        />

                        <TextField
                          margin="normal"
                          value={item.make || ''}
                          onChange={(event) => setItem({ ...item, make: event.target.value })}
                          label={t('deviceMake')}
                          variant="filled"
                        />
                        <TextField
                          margin="normal"
                          value={item.model || ''}
                          onChange={(event) => setItem({ ...item, model: event.target.value })}
                          label={t('deviceModel')}
                          variant="filled"
                        />
                        <SelectField
                          margin="normal"
                          value={item.color || 'Black'}
                          emptyValue={null}
                          onChange={(event) => setItem({ ...item, color: event.target.value })}
                          data={getCarColorListData()}
                          label='color'
                          variant="filled"
                        />
                        <SelectField
                          margin="normal"
                          value={item.category || 'Default'}
                          emptyValue={null}
                          onChange={(event) => setItem({ ...item, category: event.target.value })}
                          data={deviceCategories.map((category) => ({
                            id: category,
                            name: category,
                          }))}
                          label={t('deviceCategory')}
                          variant="filled"
                        />

                        <>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={item.measure_type || ''}
                              onChange={(event) => setItem({ ...item, measure_type: event.target.value })}
                              style={{ justifyContent: "space-around" }}
                              row
                            >
                              <FormControlLabel value="off" control={<Radio />} label="Off" />
                              <FormControlLabel value="smoke" control={<Radio />} label="Smoke" />
                              <FormControlLabel value="gas" control={<Radio />} label="Gas" />
                            </RadioGroup>
                          </FormControl>

                          {id &&
                            <>
                              <TextField
                                margin="normal"
                                value={item.fuel}
                                // onChange={(event) => setItem({ ...item, maxFuel: event.target.value })}
                                label='Current Fuel'
                                variant="filled"
                                disabled="true"
                              />
                              {
                                (item.measure_type === 'smoke') ? (
                                  <>
                                    <Button variant="contained" onClick={() => { setItem({ ...item, no_smoke_volt: item.fuel }) }}>Car Has No Smoke</Button>
                                  </>
                                ) : (item.measure_type === 'gas') ? (
                                  <>
                                    <Button variant="contained" disabled={apiCalling.includes("fuel_max_voltage")} onClick={() => onClickFuelMaxVoltage()}>Full</Button>
                                    <Button variant="contained" disabled={apiCalling.includes("fuel_min_voltage")} onClick={() => onClickFuelMinVoltage()}>Quarter</Button>
                                  </>
                                ) : (<></>)
                              }
                            </>
                          }
                        </>

                        {id &&
                          <>
                            <TextField
                              margin="normal"
                              value={item.apiKey || ''}
                              label="Api-Key"
                              variant="filled"
                              disabled="true"
                            />
                            <Button variant="contained" onClick={() => { setItem({ ...item, apiKey: randomStr(16) }) }}>Generate</Button>
                          </>
                        }

                        {
                          (isGpsMT2VProtocolDevice(item.uniqueId)) && (
                            <>
                              <div className={classes.normalColumnMargin}>
                                <Button variant="contained" fullWidth disabled={apiCalling.includes("door_voltage")} onClick={() => onClickDoorOpenVoltage()}>
                                  Calibrate Door
                                </Button>
                              </div>
                              <div className={classes.normalColumnMargin}>
                                <Button variant="contained" fullWidth disabled={apiCalling.includes("hood_voltage")} onClick={() => onClickHoodOpenVoltage()}>
                                  Calibrate Hood
                                </Button>
                              </div>

                              <div className={classes.normalColumn}>
                                <Button variant="text" onClick={() => onClickLockUnlockSetting()}>{`Lock and Unlock settings >>`}</Button>
                              </div>
                            </>
                          )
                        }

                        <div className={classes.checkboxColumn}>
                          <Stack direction={`row`} alignItems={`center`} style={{ width: '100%' }}>
                            <FormControlLabel
                              control={<Checkbox checked={item.abi} onChange={(event) => onChangeAbiCheckbox(event)} />}
                              label="Share With ABI Insurance"
                            />
                            {
                              !isAdmin ? (
                                <>
                                  <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    style={{ borderRadius: '80px', height: '30px', textTransform: 'none', lineHeight: 1 }}
                                    disabled={apiCalling.includes("all_car_abi")}
                                    onClick={() => onClickAllUserCarABI('all_car_abi')}
                                  >
                                    All Cars
                                  </Button>
                                </>
                              ) : (
                                <></>
                              )
                            }
                          </Stack>

                          {
                            (showTooltip) && (
                              <Tooltip
                                arrow
                                title="By enabling this option you will be sharing your car position and other details with American Business Insurance."
                              >
                                <InfoIcon color="action" />
                              </Tooltip>
                            )
                          }
                        </div>
                        <div className={classes.checkboxColumn}>
                          <Stack direction={`row`} alignItems={`center`} style={{ width: '100%' }}>
                            <FormControlLabel
                              control={<Checkbox checked={item.tint_ai} onChange={(event) => onChangeTintAiCheckbox(event)} />}
                              label="Share With Tint.Ai"
                            />
                            {
                              !isAdmin ? (
                                <>
                                  <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    size="small"
                                    style={{ borderRadius: '80px', height: '30px', textTransform: 'none', lineHeight: 1 }}
                                    disabled={apiCalling.includes("all_car_tint_ai")}
                                    onClick={() => onClickAllUserCarTintAi('all_car_tint_ai')}
                                  >
                                    All Cars
                                  </Button>
                                </>
                              ) : (
                                <></>
                              )
                            }
                          </Stack>

                          {
                            (showTooltip) && (
                              <Tooltip
                                arrow
                                title="By enabling this, you will be sharing your car position and other information with Tint.Ai"
                              >
                                <InfoIcon color="action" />
                              </Tooltip>
                            )
                          }
                        </div>
                        {
                          (item.deviceType === DEVICE_TYPE.MOOVETRAX) ? (
                            <>
                              <div className={classes.checkboxColumn}>
                                <FormControlLabel
                                  control={<Checkbox checked={item.isDoubleLock} onChange={(event) => setItem({ ...item, isDoubleLock: event.target.checked })} />}
                                  label="Double Pulse Lock"
                                />
                                {
                                  (showTooltip) && (
                                    <Tooltip
                                      arrow
                                      title="Some cars need a double pulse to lock or unlock a door, your installer can tell you about your car."
                                    >
                                      <InfoIcon color="action" />
                                    </Tooltip>
                                  )
                                }
                              </div>
                              <div className={classes.checkboxColumn}>
                                <FormControlLabel
                                  control={<Checkbox checked={item.isDoubleUnlock} onChange={(event) => setItem({ ...item, isDoubleUnlock: event.target.checked })} />}
                                  label="Double Pulse Unlock"
                                />
                                {
                                  (showTooltip) && (
                                    <Tooltip
                                      arrow
                                      title="Some cars need a double pulse to lock or unlock a door, your installer can tell you about your car."
                                    >
                                      <InfoIcon color="action" />
                                    </Tooltip>
                                  )
                                }
                              </div>
                              <div className={classes.checkboxColumn}>
                                <FormControlLabel
                                  control={<Checkbox checked={item.enableCycle} onChange={(event) => setItem({ ...item, enableCycle: event.target.checked })} />}
                                  label="Cycle"
                                />
                                {
                                  (showTooltip) && (
                                    <Tooltip
                                      arrow
                                      title="Some cars go to sleep if left for an extended time. This function will wake the car up every 10 hours."
                                    >
                                      <InfoIcon color="action" />
                                    </Tooltip>
                                  )
                                }
                              </div>
                            </>
                          ) : (
                            <></>
                          )
                        }
                        <div className={classes.checkboxColumn}>
                          <FormControlLabel
                            control={<Checkbox checked={item.enableInstaller} onChange={(event) => setItem({ ...item, enableInstaller: event.target.checked })} />}
                            label="Enable Installer"
                          />
                          {
                            (showTooltip) && (
                              <Tooltip
                                arrow
                                title="When this function is on, an installer can test the device using the Serial number and the iccid"
                              >
                                <InfoIcon color="action" />
                              </Tooltip>
                            )
                          }
                        </div>

                        {id && (<>
                          <div className={classes.checkboxColumn}>
                            <FormControlLabel
                              control={<Checkbox checked={item.disabled} onChange={(event) => setItem({ ...item, disabled: event.target.checked })} />}
                              label={t('sharedDisabled')}
                              disabled={id && !user.administrator && "true"}
                            />
                            {
                              (showTooltip) && (
                                <Tooltip
                                  arrow
                                  title="This function will disable the unit."
                                >
                                  <InfoIcon color="action" />
                                </Tooltip>
                              )
                            }
                          </div>
                        </>)}

                      </AccordionDetails>
                    </>
                  )
                }
              </Accordion>
            </>
          )}
      </EditItemView>

      <CreditLogsModal
        open={creditModalOpen}
        setOpen={setCreditModalOpen}
        item={item}
        setItem={setItem}
      />

      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}

      {
        (showLockUnlockModal) && (
          <LockUnlockSettingModal
            open={showLockUnlockModal}
            setOpen={setShowLockUnlockModal}
            item={item}
            setItem={setItem}
          />
        )
      }

      <>
        <PaypalPaymentModal
          open={paypalPaymentModalOpen}
          setOpen={setPaypalPaymentModalOpen}
          amount={item?.credit}
          itemType={`deviceCredit`}
          item={item}
          setItem={setItem}
          updateTimestamp={updateTimestamp}
          setUpdateTimestamp={setUpdateTimestamp}
          callback={paymentCallback}
          user_type={`user`}
          user_id={user?.id}
        />
      </>

      <>
        <ConfirmDialog
          open={showConfirmModal}
          setOpen={setShowConfirmModal}
          title={APP_NAME}
          content={confirmText}
          textYes={`Yes`}
          textNo={`Cancel`}
          onClickYes={() => onClickAgreeConfirm()}
          onClickNo={() => onClickCancelConfirm()}
        />
      </>
      <>
        <TeslaQrCodeModal
          open={openTeslaQrCodeModal}
          setOpen={setOpenTeslaQrCodeModal}
          onOk={() => onOkTeslaQrCodeModal()}
        />
      </>

      <>
        <TransferServiceModal
          uniqueId={item?.uniqueId}
          name={item?.name}
          open={openTransferServiceModal}
          setOpen={setOpenTransferServiceModal}
          onOk={() => onOkTransferServiceModal()}
        />
      </>

    </>
  );
};

export default DevicePage;
