import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { TESLA_API_URL } from './config/constant';
import { Box, Button, Checkbox, FormControlLabel, Grid, Snackbar, TextField, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { api_call } from './helpers/untils';
import { method } from 'lodash';

const TeslaSignupPage = () => {

    const [accessToken, setAccessToken] = useState("")
    const [refreshToken, setRefreshToken] = useState("")
    const [email, setEmail] = useState("")
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [vehicleInfoList, setVehicleInfoList] = useState([]);
    const [deviceList, setDeviceList] = useState([])

    const [checkStatus, setCheckStatus] = useState({})
    const updateCheckStatus = (index, value) => {
        setCheckStatus((prevData) => {
            const newData = { ...prevData };
            newData[index] = value;
            return newData;
        });

        if (index != 'abi_insurance' && index != 'tint_ai') {
            setDeviceList((prevData) => {
                const selectedDevice = vehicleInfoList.filter((item) => item.vin == index)
                if (value) {
                    return [...prevData, ...selectedDevice]
                }
                return prevData.filter((item) => item.vin != index)
            })
        }
    }

    useEffect(() => {
        const newCheckStatus = {};
        vehicleInfoList.forEach(vehicle => {
            newCheckStatus[vehicle.vin] = false;
        });
        setCheckStatus(newCheckStatus);
    }, [vehicleInfoList]);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const showToast = (msg) => {
        setToastMessage(msg)
        setSnackbarOpen(true)
    }


    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const code = params.get("code")

    const history = useHistory()

    useEffect(async () => {
        if (code != null && code != "") {
            const response = await fetch(TESLA_API_URL + '/api/requestAuth?code=' + code)
            if (response.ok) {
                console.log(response);

                const res = await response.json()
                console.log(res);
                if (res.access_token == "" || res.refresh_token == "") {
                    alert("Invalid Credential. Please try again!")
                    history.push("/oem-signup")
                } else {
                    setAccessToken(res.access_token)
                    setRefreshToken(res.refresh_token)
                }
            }
            else {
                alert("Error occurs while signing up. Please try again!")
                history.push("/oem-signup")
            }
        }
    }, [code])

    useEffect(async () => {
        if (accessToken != "") {
            const response = await fetch(TESLA_API_URL + "/api/getAllVehichles?access_token=" + accessToken)
            if (response.ok) {
                const res = await response.json();
                const data = JSON.parse(res.data)
                if (res.status == 200) {
                    if (data.response.length > 0) {
                        setVehicleInfoList(data.response)
                    }
                } else if (res.status == 400) {
                    alert(data.error_description)
                    history.push("/oem-signup")
                }
            }
        }
    }, [accessToken])

    const onSubmit = async (event) => {
        event.preventDefault();
        setOpenConfirmModal(true)
    }

    const onSubmitOk = async () => {
        setSubmitting(true)
        const payload = {
            email: email,
            deviceList: deviceList,
            accessToken: accessToken,
            refreshToken: refreshToken,
            shareInfo: checkStatus,
        }

        const response = await api_call("/api/tesla_device_signup", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
        if (response.ok) {
            const apiRes = await response.json();
            showToast(apiRes.message)
            setTimeout(() => {
                history.push("/login")
            }, 2000)
        } else {
            const errorRes = response
            let errorMessage = "Invalid request"
            if (errorRes['error']) {
                errorMessage = errorRes['error']
            }
            setSubmitting(false)
            showToast(errorMessage)
            setOpenConfirmModal(false)
            return false
        }
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                onClose={() => { setSnackbarOpen(false) }}
                autoHideDuration={3000}
                message={toastMessage}
            />
            {
                !openConfirmModal ? (
                    <Box sx={{ width: '400px' }}>
                        {
                            accessToken && (
                                <Grid container direction="column" spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant='title' component="p" style={{ textAlign: 'center', marginBottom: '20px', fontSize: '24px', fontWeight: 600 }}>OEM Signup</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            label={`Enter your email`}
                                            name="email"
                                            type="email"
                                            value={email}
                                            autoFocus={true}
                                            onChange={(e) => setEmail(e.target.value)}
                                            helperText=""
                                            variant="filled"
                                            disabled={submitting}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box>
                                            <Box>
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkStatus['abi_insurance'] || false} onChange={(event) => updateCheckStatus('abi_insurance', event.target.checked)} />}
                                                    label="Share With ABI Insurance"
                                                />
                                                <Tooltip
                                                    arrow
                                                    title="By enabling this option you will be sharing your car position and other details with American Business Insurance."
                                                >
                                                    <InfoIcon color="action" />
                                                </Tooltip>
                                            </Box>
                                            <Box>
                                                <FormControlLabel
                                                    control={<Checkbox checked={checkStatus['tint_ai'] || false} onChange={(event) => updateCheckStatus('tint_ai', event.target.checked)} />}
                                                    label="Share With Tint.Ai"
                                                />
                                                <Tooltip
                                                    arrow
                                                    title="By enabling this, you will be sharing your car position and other information with Tint.Ai."
                                                >
                                                    <InfoIcon color="action" />
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                        <Box sx={{ ml: 4, mt: 2 }}>
                                            {
                                                (vehicleInfoList != null && vehicleInfoList.length > 0) ? (
                                                    vehicleInfoList.map((vehicleInfo, index) => {
                                                        let vehicleLabel = vehicleInfo.display_name == null ? "" : vehicleInfo.display_name
                                                        vehicleLabel += " " + vehicleInfo.vin
                                                        return (
                                                            <Box sx={{ mt: 2 }} key={index}>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={checkStatus[`${vehicleInfo.vin}`] || false} onChange={(event) => updateCheckStatus(`${vehicleInfo.vin}`, event.target.checked)} />}
                                                                    label={vehicleLabel}
                                                                />
                                                            </Box>
                                                        )
                                                    })
                                                ) : (
                                                    <Typography>No vehicle found</Typography>
                                                )
                                            }
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={email == "" || vehicleInfoList.length === 0 || submitting || deviceList.length == 0}
                                            fullWidth
                                            onClick={onSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Box>
                ) : (
                    <Box style={{ width: '400px', maxWidth: '100%' }}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='title' component="p" style={{ textAlign: 'center', marginBottom: '20px', fontSize: '22px', fontWeight: 600 }}>
                                    Payment Setup Required
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle' component="p">
                                    Please check your email or junk folder for a MooveTrax login, After login, click on the balance in order to setup payment and to get full activation
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle' component="p" color="error">
                                    Disruption of Payment requires re-activation
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    disabled={submitting}
                                    fullWidth
                                    onClick={() => onSubmitOk()}
                                >
                                    Ok
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )
            }
        </Box>
    )
}

export default TeslaSignupPage