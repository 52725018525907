import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, Grid, InputLabel, TextField
} from '@material-ui/core';

import MUIDataTable from "mui-datatables";
import { useParams } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ReportLayout from './ReportLayout';

import moment from 'moment';
import { api_call, console_log, empty, is_null } from '../helpers/untils';
import { devicesActions } from '../store';
import DeviceAutoCompleteDropdown from '../components/DeviceAutoCompleteDropdown';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Stack } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
}));

const DailyMileageDataTable = (props) => {
  const { id, devices } = props
  const classes = useStyles();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);

  const getDeviceName = (deviceId) => {
    for (const key in devices) {
      if (devices.hasOwnProperty(key) && devices[key].id === deviceId) {
        return devices[key].name;
      }
    }
    return "Unknown or Deleted device"
  }

  const todayDate = moment().format('YYYY-MM-DD')
  const defaultFormData = {
    from: moment().subtract(7, 'day').endOf('day'),
    to: moment()
  }
  const [formData, setFormData] = useState(defaultFormData)

  const onChnageDate = (e) => {
    console.log(`onChnageDate e.target.value:::`, e.target.value)
    const file_name = e.target.name
    const _dob = moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL)
    console.log(`onChnageDob _dob:::`, _dob)
    const form_data = { ...formData }
    form_data[file_name] = _dob
    setFormData(form_data)
  }

  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const sortOrder = {}

  // mock async function
  const xhrRequest = async (page, sortOrder = {}, filter_options = {}) => {
    if (empty(user)) {
      return false
    }

    setIsLoading(true);
    let api_url = "/api/get-device-mileage-data"
    let post_data = {}
    post_data['sortOrder'] = sortOrder

    const filterOptions = {
      ...filter_options
    }

    const dateFrom = filterOptions['from']
    const dateTo = filterOptions['to']
    filterOptions['date_from'] = dateFrom.format('YYYY-MM-DD')
    filterOptions['date_to'] = dateTo.format('YYYY-MM-DD')

    const response = await api_call(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...post_data, ...filterOptions }),
    });
    if (response.ok) {
      const res = await response.json();
      setData(res.data)
      setIsLoading(false)
      return res
    } else {
      return false
    }
  };

  const reduxSelectedDeviceId = useSelector((state) => state.devices.selectedDeviceId);
  const [userDevices, setUserDevices] = useState([]); // device name for dropdown
  const [selectedDeviceId, setSelectedDeviceId] = useState(null)

  const getDefaultSelect = () => {
    var defaultSelect = [];
    if (!empty(reduxSelectedDeviceId)) {
      defaultSelect = [reduxSelectedDeviceId]
    } else {
      if (id) {
        defaultSelect = [];
        for (const key in devices) {
          if (devices[key].id === Number(id)) {
            defaultSelect = [devices[key].id];
            return defaultSelect
          }
        }
      }
    }
    return defaultSelect
  }

  const getUserDevices = () => {
    var items = [];
    //items.push({ id: "", name: "All" });
    for (const key in devices) items.push({ id: devices[key].id, name: devices[key].name });
    return items;
  }

  const defaultSelectedDeviceIds = []
  const [selectedDeviceIds, setSelectedDeviceIds] = useState(defaultSelectedDeviceIds)

  useEffect(() => {
    let defaultSelectedDeviceIds = []
    setUserDevices(getUserDevices())
    if (selectedDeviceId === null) {
      defaultSelectedDeviceIds = getDefaultSelect()
    }
    console_log(`defaultSelectedDeviceIds:::`, defaultSelectedDeviceIds)
    setSelectedDeviceIds(defaultSelectedDeviceIds)
  }, [id, user]);

  const loadMileageData = async () => {
    if (selectedDeviceIds && selectedDeviceIds.length > 0) {
      xhrRequest(0, sortOrder, { deviceIds: selectedDeviceIds, ...formData })
    }
  }

  useEffect(() => {
    loadMileageData()
  }, [id, user, selectedDeviceIds, formData]);

  const getMileageDate = (item, index = 0) => {
    let dateStr = "Today"
    //dateStr = moment().subtract(index, 'days').format('dddd MMM Do YY') //let dateStr = moment(item.date).format('dddd MMM Do YY')
    dateStr = item['date_str']
    return dateStr
  }

  return (
    <>
      {
        (!empty(devices)) ? (
          <>
            <div className='MuiPaper-root MuiAccordion-root Mui-expanded MuiAccordion-rounded MuiPaper-elevation1 MuiPaper-rounded'>
              <div style={{ padding: '24px' }}>
                <div className="">
                  <Stack direction={`${xsDown ? 'column' : 'row'}`} alignItems={`${xsDown ? 'flex-start' : 'center'}`} justifyContent={`${xsDown ? 'flex-start' : 'space-between'}`} spacing={3}>
                    <Box sx={{ width: xsDown ? '100%' : '300px' }}>
                      <DeviceAutoCompleteDropdown
                        label={`Select Devices`}
                        definitions={userDevices}
                        value={selectedDeviceIds}
                        setValue={setSelectedDeviceIds}
                        multiple={true}
                      />
                    </Box>
                    <Box sx={{ width: xsDown ? '100%' : '360px' }}>
                      <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`flex-end`} spacing={1}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <FormControl variant="filled" fullWidth>
                              <InputLabel shrink>From</InputLabel>
                              <TextField
                                variant="filled"
                                label=""
                                type="date"
                                name="from"
                                value={formData.from.format("YYYY-MM-DD")}
                                onChange={(e) => onChnageDate(e)}
                                fullWidth
                                style={{ width: '100%' }}
                                inputProps={{
                                  max: todayDate
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl variant="filled" fullWidth>
                              <InputLabel shrink>To</InputLabel>
                              <TextField
                                variant="filled"
                                label=""
                                type="date"
                                name="to"
                                value={formData.to.format("YYYY-MM-DD")}
                                onChange={(e) => onChnageDate(e)}
                                fullWidth
                                style={{ width: '100%' }}
                                inputProps={{
                                  max: todayDate
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Box>
                  </Stack>
                </div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Device</TableCell>
                        <TableCell>Miles</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        (empty(selectedDeviceIds) || selectedDeviceIds.length === 0) ? (
                          <>
                            <TableRow>
                              <TableCell colSpan={3} align='center'>No device selected</TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <>
                            {
                              (data && !isLoading) ? (
                                <>
                                  {
                                    (data.length > 0) ? (
                                      <>
                                        {data.map((item, index) => (
                                          <TableRow key={item.id}>
                                            <TableCell>{getMileageDate(item, index)}</TableCell>
                                            <TableCell>{item.deviceName}</TableCell>
                                            <TableCell>{item.mileage}</TableCell>
                                          </TableRow>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        <TableRow>
                                          <TableCell colSpan={3} align='center'>No data found</TableCell>
                                        </TableRow>
                                      </>
                                    )
                                  }
                                </>
                              ) : (
                                <>
                                  <TableRow>
                                    <TableCell colSpan={3} align='center'>Loading...</TableCell>
                                  </TableRow>
                                </>
                              )
                            }
                          </>
                        )
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </>
        ) : (
          <></>
        )
      }
    </>
  )
}

const DailyMileageReportPage = () => {
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.devices.items);
  const { id } = useParams();
  return (
    <>
      <ReportLayout>
        {
          (!empty(devices)) ? (
            <DailyMileageDataTable
              id={id}
              devices={devices}
            />
          ) : (
            <></>
          )
        }
      </ReportLayout>
    </>
  )
}
export default DailyMileageReportPage
