import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  CircularProgress,
  IconButton,
  TextField,
  makeStyles,
} from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/DeleteForever';


import { useTranslation } from '../LocalizationProvider';

import moment from 'moment';
import { api_call, console_log, empty, validateEmail } from '../helpers/untils';
import { useHistory } from 'react-router-dom';
import { Stack } from '@mui/material';
import AddCoHostDeviceDialog from '../devices/AddCoHostDeviceDialog';

const useStyles = makeStyles((theme) => ({
  tableToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
    },
  },
  tableToolBarLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    maxWidth: '340px',
    paddingTop: '1rem',
    [theme.breakpoints.only('xs')]: {

    },
  },
  tableToolBarRight: {
    maxWidth: '300px',
    width: '100%',
    paddingTop: '1rem',
    paddingLeft: '4px',
    textAlign: 'right',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'left',
      paddingTop: '0.5rem',
    },
  },
  tableSearchText: {
    '& input.MuiInput-input': {
      paddingRight: '46px'
    }
  },
  tableSearchBtn: {
    borderRadius: 0,
    marginLeft: '-46px'
  }
}));

const CoHostDeviceDataTable = (props) => {
  const { user, devices, addDialogShown, setAddDialogShown } = props;
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory()

  useEffect(() => {
    loadUserDevices();
  }, [devices]);

  const [userDevices, setUserDevices] = useState([])
  const getCoHostedDeviceIds = () => {
    const deviceIds = []
    if (data) {
      for (let k in data) {
        deviceIds.push(data[k]['device_id'])
      }
    }
    return deviceIds
  }
  const loadUserDevices = () => {
    //const deviceIds = getCoHostedDeviceIds()
    let user_devices = [];
    for (let k in devices) {
      const deviceInfo = devices[k]
      if (deviceInfo.co_host_device) {
        continue
      } else {
        if (user.administrator) {
          const users = deviceInfo['users']
          if (users) {
            const checkUser = users.filter((el) => el.id === user.id);
            if (checkUser.length > 0) {
              user_devices.push(deviceInfo)
              // if(!deviceIds.includes(deviceInfo.id)) {
              // }
            }
          }
        } else {
          user_devices.push(deviceInfo)
        }
      }
    }
    console.log("user_devices:::", user_devices)
    setUserDevices(user_devices)
  }
  ////////////////////////////////////////////////////////////////////////
  const OnClickDeleteRow = (id) => {
    console.log("id:::", id)
    if (window.confirm("Are you sure you want to delete?")) {
      DeleteRow(id)
    }
  }

  const DeleteRow = async (id) => {
    const url = `/api/devices/delete-co-host`
    const params = { id: id }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    });

    if (response.ok) {
      let responseObj = await response.json()
      reloadTable()
    } else {
      let responseObj = response
      console_log("error response:::", responseObj)
    }
  }

  const handleAddResult = async (newItem) => {
    console.log("newItem", newItem)
    if (!newItem) {
      setAddDialogShown(false);
      return false
    }

    const email = newItem['email']
    if (!validateEmail(email)) {
      alert("Please enter a valid email address")
      return false
    }

    newItem['owner_user_id'] = user.id
    const url = `/api/devices/add-co-host`
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });

    if (response.ok) {
      let responseObj = await response.json()
      setAddDialogShown(false);
      reloadTable()
    } else {
      let responseObj = response
      console_log("error response:::", responseObj)
      //alert("Duplicated GPS ID")
      setAddDialogShown(false);
    }
  }

  /////////////////////////////radio button///////////////////////////////////////
  const dataRef = useRef([])

  const columnDefs = [
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{value}</div>
          );
        },
      }
    },
    {
      name: "device_name",
      label: "Device Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{value}</div>
          );
        },
      }
    },
    {
      name: "uniqueId",
      label: "Device ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className='td-div'>{value}</div>
          );
        },
      }
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>{moment(value).format('LLL')}</div>
          );
        },
      }
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log('customBodyRender', value, tableMeta, updateValue);
          return (
            <div className='td-div'>
              <IconButton color="primary" size="small" onClick={() => OnClickDeleteRow(value)}>
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      }
    },
  ];

  const [page, setPage] = useState(0)
  const [count, setCount] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [sortOrder, setSortOrder] = useState({ name: "id", direction: "asc" })
  const [data, setData] = useState([])
  const [columns, setColumns] = useState(columnDefs)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState()

  const [searchText, setSearchText] = useState("");
  const onChangeSearchText = (e) => {
    const filter = e.target.value
    setSearchText(filter)
    if (filter === "") {
      onSubmitChangeSearchText(filter)
    }
  }

  // mock async function
  const xhrRequest = async (page, rowsPerPage, sortOrder = {}, filter_options = {}) => {
    if (empty(user)) {
      return false
    }

    setIsLoading(true);
    let api_url = "/api/devices/co-host/get-data-table"
    let post_data = {}
    post_data['page'] = page
    post_data['rowsPerPage'] = rowsPerPage
    post_data['sortOrder'] = sortOrder

    const filterOptions = {
      ...filter_options
    }

    const response = await api_call(api_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...post_data, ...filterOptions }),
    });
    if (response.ok) {
      const res = await response.json();
      setData(res.data)
      dataRef.current = res.data
      setPage(res.page)
      setSortOrder(sortOrder)
      setIsLoading(false)
      setCount(res.total)
      return res
    } else {
      return false
    }
  }

  const sort = async (page, sortOrder) => {
    const filterOptions = {
      search: searchText,
    }
    await xhrRequest(page, rowsPerPage, sortOrder, filterOptions);
  }

  const changePage = async (page, sortOrder) => {
    const filterOptions = {
      search: searchText,
    }
    await xhrRequest(page, rowsPerPage, sortOrder, filterOptions)
  }

  const onChangeRowsPerPage = (r) => {
    setRowsPerPage(r)
    setPage(0)
    const filterOptions = {
      search: searchText,
    }
    xhrRequest(0, r, sortOrder, filterOptions)
  }

  const onSubmitChangeSearchText = async (text) => {
    setPage(0)
    const filterOptions = {
      search: text,
    }
    await xhrRequest(0, rowsPerPage, sortOrder, filterOptions)
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log("handleSearchSubmit:::")
    onSubmitChangeSearchText(searchText)
  }

  const reloadTable = () => {
    const filterOptions = {
      search: searchText
    }
    xhrRequest(page, rowsPerPage, sortOrder, filterOptions)
  }

  useEffect(() => {
    const filterOptions = {
      search: searchText
    }
    xhrRequest(0, rowsPerPage, sortOrder, filterOptions)
  }, [selectedUserId]);

  const options = {
    filter: false,
    filterType: 'dropdown',
    responsive: 'standard',
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    onChangeRowsPerPage: onChangeRowsPerPage,
    sortOrder: sortOrder,
    download: false,
    viewColumns: false,
    print: false,
    search: false,
    selectableRowsHideCheckboxes: true,

    onTableChange: (action, tableState) => {
      // console.log("action, tableState:::", action, tableState);

      // a developer could react to change on an action basis or
      // examine the state as a whole and do whatever they want
      switch (action) {
        case 'changePage':
          changePage(tableState.page, tableState.sortOrder);
          break;
        case 'sort':
          sort(tableState.page, tableState.sortOrder);
          break;
        default:
        //console.log('action not handled.');
      }
    },
  }

  return (
    <>
      <div className="custom-data-table-container">
        <MUIDataTable
          title={
            <Box className={classes.tableToolBar}>
              <div className={classes.tableToolBarLeft}>
                {<CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 5, visibility: isLoading ? 'visible' : 'hidden' }} />}
              </div>

              <div className={classes.tableToolBarRight}>
                <form onSubmit={handleSearchSubmit}>
                  <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={0}>
                    <TextField
                      className={classes.tableSearchText}
                      fullWidth
                      value={searchText}
                      onChange={(e) => onChangeSearchText(e)}
                      label={`Search...`}
                      inputProps={{ type: 'text' }}
                    />
                    <IconButton size={`medium`} className={classes.tableSearchBtn} type="submit">
                      <SearchIcon />
                    </IconButton>
                  </Stack>
                </form>
              </div>
            </Box>
          }
          data={data}
          columns={columns}
          options={options}
          isLoading={isLoading}
        />
      </div>

      <AddCoHostDeviceDialog
        open={addDialogShown}
        onResult={handleAddResult}
        definitions={userDevices}
        definitionLength={userDevices?.length}
      />

    </>
  )
}

export default CoHostDeviceDataTable;
