import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  Modal, Snackbar, Grid, Typography, Box, makeStyles
} from '@material-ui/core';
import { api_call, empty, get_utc_timestamp } from '../../helpers/untils';
import BatchGenerateLinkRow from './BatchGenerateLinkRow';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '1800px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};
const useStyles = makeStyles((theme) => ({
  formRowInlineController: {
    flex: 1
  },
  flexibleLineBox: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    [theme.breakpoints.down('md')]: {
      direction: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  }
}));

const BatchGenerateLinkModal = (props) => {
  const {
    open,
    setOpen,
    updateTimestamp,
    setUpdateTimestamp,
    pageData,
    setPageData,
    showTuroCalendarInputModal,
    setShowTuroCalendarInputModal
  } = props

  //console_log(`pageData:::::::::::::::::::`, props)

  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.session.user);

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [toastMessage, setToastMessage] = useState(false)
  const showToast = (msg) => {
    setToastMessage(msg)
    setSnackbarOpen(true)
  }

  const handleModalClose = () => {
    setOpen("")
  }

  const sortWithTripStartDateAsc = (a, b) => {
    const curTimestamp = get_utc_timestamp()

    if (a < curTimestamp) {
      return 1
    }
    else if (b < curTimestamp) {
      return -1
    }
    else {
      return a - b
    }
  }

  const sortTuroTripList = (deviceTripList) => {
    if (deviceTripList && deviceTripList.length > 0) {
      deviceTripList = deviceTripList.sort((a, b) => {
        return sortWithTripStartDateAsc(a.start_timestamp, b.start_timestamp)
      })
    }
    return deviceTripList
  }

  const [tripList, setTripList] = useState([]);
  const [usedTripIds, setUsedTripIds] = useState([]) // useState(getSettingPersist(usedTripKey, []))
  const [batchGeneratedLinkList, setBatchGeneratedLinkList] = useState([]);
  const [userDeviceList, setUserDeviceList] = useState([]);

  const loadPageData = async () => {
    const url = `/api/get-batch-generate-links-page-data`
    const response = await api_call(url);
    if (response.ok) {
      const res = await response.json();
      setTripList(sortTuroTripList(res.turoTripList))
      setUsedTripIds(res.usedTripIdList)
      setBatchGeneratedLinkList(res.batchGeneratedLinkList ?? [])
      setUserDeviceList(res.userDeviceList ?? [])
      setPageData(res)

      if (empty(res.turoTripList) || res.turoTripList.length === 0) {
        setShowTuroCalendarInputModal(true)
        setOpen(false)
      }
    } else {
      setTripList([])
      setUsedTripIds([])
      setBatchGeneratedLinkList([])
    }
  }

  const [reloadTimestamp, setReloadTimestamp] = useState(0);

  useEffect(() => {
    loadPageData()
  }, [reloadTimestamp])

  const devices = useSelector((state) => Object.values(state.devices.items));

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={modalStyle}>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={3000}
            message={toastMessage}
          />
          <Stack direction={`row`} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">{`Batch Generate Shared Link`}</Typography>
            {/* <IconButton size="small" onClick={() => handleModalClose()}><CloseIcon /></IconButton> */}
          </Stack>
          <Box sx={{ paddingTop: '20px', maxHeight: 'calc(100vh - 120px)', overflowY: 'auto', overflowX: 'hidden' }}>
            <Grid container spacing={3}>
              {
                (tripList && tripList.length > 0) ? (
                  <>
                    {
                      tripList.map((item, index) => {
                        return (
                          <BatchGenerateLinkRow
                            key={index}
                            trip={item}
                            devices={devices}
                            batchGeneratedLinkList={batchGeneratedLinkList}
                            userDeviceList={userDeviceList}
                            reloadTimestamp={reloadTimestamp}
                            setReloadTimestamp={setReloadTimestamp}
                            updateTimestamp={updateTimestamp}
                            setUpdateTimestamp={setUpdateTimestamp}
                            showToast={showToast}
                          />
                        )
                      })
                    }
                  </>
                ) : (
                  <></>
                )
              }
            </Grid>
          </Box>
        </Box>
      </Modal>

    </>
  )
}

export default BatchGenerateLinkModal;
