import React, { useEffect, useState } from 'react';

import {
  makeStyles,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { getAddressFromGoogleGeoLocation } from '../helpers/untils';
import TemporaryStatusView from './TemporaryStatusView';
import { GOOGLE_MAP_API_KEY } from '../config/constant';

const useStyles = makeStyles((theme) => ({
  temporaryContainer: {
    width: '100vw',
    height: '100vh',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#abd3df'
  },
  tempStatusWrapper: {
    padding: '10px',
    background: '#ffffff'
  }
}));

const TemporaryMainBlock = (props) => {
  const { positions, temporaryPage = true, currentLink, loadLinkInfo, pageType, showPaypalCreditLogsPage, commands, enablePopup = true, isTest = false } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const device = useSelector((state) => state.devices.items[currentLink.deviceId]);

  useEffect(() => { //////////////////

  }, [device]);

  return (
    <div className={classes.temporaryContainer}>
      <>
        {
          (device && device.id) ? (
            <div className={classes.tempStatusWrapper}>
              <TemporaryStatusView
                currentLink={currentLink}
                loadLinkInfo={loadLinkInfo}
                deviceId={currentLink.deviceId}
                address={``}
                lat={device.latitude}
                lng={device.longitude}
                onShowDetails={(positionId) => history.push(`/position/${positionId}`)}
                onShowHistory={(deviceId) => history.push(`/temporary-replay/${deviceId}`)}
                onShareLink={(deviceId) => history.push(`/share/${deviceId}`)}
                onEditClick={(deviceId) => history.push(`/device/${deviceId}`)}
                onLogClick={(deviceId) => history.push(`/reports/event/${deviceId}`)}
                history={history}
                commandsData={commands}
                userDeviceItem={device}
                isTest={isTest}
                containerLayout="box"
              />
            </div>
          ) : (
            <></>
          )
        }
      </>
    </div>
  );
}

export default TemporaryMainBlock;
