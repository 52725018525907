import React, { useState, useRef } from 'react';

function Test2() {
  const [imageDataURL, setImageDataURL] = useState(null);
  const [facingMode, setFacingMode] = useState('environment');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null); // Reference to store the stream

  const startCamera = async (mode) => {
    if (streamRef.current) {
      // Stop the previous stream
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    
    const constraints = {
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        aspectRatio: { ideal: 1.7777777778 }, // 16:9 aspect ratio
        facingMode: mode
      }
    };
    
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      streamRef.current = stream; // Store the stream reference
      videoRef.current.srcObject = stream;
      videoRef.current.play();
      videoRef.current.onloadedmetadata = () => {
        const videoWidth = videoRef.current.videoWidth;
        const videoHeight = videoRef.current.videoHeight;
        const isLandscape = videoWidth > videoHeight;
        if (isLandscape) {
          canvasRef.current.width = videoWidth;
          canvasRef.current.height = videoHeight;
        } else {
          canvasRef.current.width = videoHeight;
          canvasRef.current.height = videoWidth;
        }
      };
    } catch (error) {
      console.error('Error accessing camera: ', error);
    }
  };

  const toggleFacingMode = () => {
    const newFacingMode = facingMode === 'environment' ? 'user' : 'environment';
    setFacingMode(newFacingMode);
    startCamera(newFacingMode);
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageDataURL = canvas.toDataURL('image/png');
    setImageDataURL(imageDataURL);
    video.srcObject.getTracks().forEach(track => track.stop());
  };

  const uploadImage = async () => {
    if (imageDataURL) {
      try {
        const response = await fetch('http://localhost:5000/upload', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ image: imageDataURL })
        });
        const result = await response.json();
        console.log('Image uploaded successfully:', result);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  return (
    <div className="App">
      <h1>Upload Photo</h1>
      <button onClick={() => startCamera(facingMode)}>Start Camera</button>
      <button onClick={toggleFacingMode}>
        Switch to {facingMode === 'environment' ? 'Front' : 'Rear'} Camera
      </button>
      <div className="video-container">
        <video ref={videoRef} className="full-screen-video" />
      </div>
      <button onClick={takePhoto}>Capture</button>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      {imageDataURL && (
        <div>
          <div id="preview">
            <img src={imageDataURL} alt="Captured" style={{ maxWidth: '100%' }} />
          </div>
          <button onClick={uploadImage}>Upload Image</button>
        </div>
      )}
    </div>
  );
}

export default Test2;