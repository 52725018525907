import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  makeStyles, withStyles, Paper, Grid, Button, FormControl, RadioGroup, FormControlLabel, Radio, Typography, Box, useTheme, useMediaQuery
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';

import LinearProgress from "@material-ui/core/LinearProgress";
import { styled } from "@material-ui/styles";
import { Snackbar } from '@material-ui/core';
import { Stack } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import {
  formatPosition, formatDate
} from '../common/formatter';
import { useAttributePreference } from '../common/preferences';
import RemoveDialog from '../RemoveDialog';
import { getPosition } from '../common/selectors';
import { useTranslation } from '../LocalizationProvider';
import moment from 'moment';
import { api_call, console_log, getDistanceFromMiles, getSpeedFromMiles, get_utc_timestamp_ms, validateEmail } from '../helpers/untils';
import { DEVICE_TYPE } from '../config/constant';
import GoogleGeolocationAddressBox from '../components/GoogleGeolocationAddressBox';
import DeviceSignalBox from '../components/DeviceSignalBox';
import { sleep } from '../helpers/misc';

const useStyles = makeStyles((theme) => ({
  ...theme.palette.colors,
  statusViewPopup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '640px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '480px',
    },
    position: 'relative'
  },
  installerStatusViewContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '15px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  flex1: {
    flex: 1,
    width: '100%'
  },
  controlBtn: {
    background: 'rgba(76, 175, 80, 0.1)',
    border: '2px solid rgba(76, 175, 80, 1)',
    marginBottom: 10,
    // paddingTop: 3,
    // paddingBottom: 3,
  },
  signalBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  signalPercent: {
    cursor: 'pointer',
    marginRight: 5,
  },
  minusMarginTop: {
    marginTop: '-25px',
  },
  inviteBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '30px',
    paddingBottom: '0px',
    gap: '10px',
  },
  inviteBlockLabel: {
    minWidth: '110px'
  },
  inviteContainer: {
    paddingBottom: '15px',
  },
  paperCloseBtn: {
    cursor: 'pointer',
    position: 'absolute',
    background: '#ffffff',
    right: '-18px',
    top: '-18px',
    padding: '7px',
    borderRadius: '30px',
    border: '1px solid #cccccc',
    [theme.breakpoints.down('md')]: {
      right: '8px',
      top: '8px',
    },
    zIndex: 2
  },
  installerSignupLink: {
    fontSize: '1rem',
    textDecoration: 'underline',
    marginBottom: '1rem'
  }
}));

const MyListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "rgba(76, 175, 80, 0.2)",
    },
    "&$selected:hover": {
      backgroundColor: "rgba(76, 175, 80, 0.2)",
    },
    "&:hover": {
      backgroundColor: "rgba(76, 175, 80, 0.2)",
    }
  },
  selected: {}
})(ListItem);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  width: 150,
  borderRadius: 5,
}));

const InstallerStatusView = ({
  deviceId, onShowDetails, onShowHistory, onShareLink, onEditClick, onLogClick, onClickLogout, deviceInfo = {}, refreshTimestamp, setRefreshTimestamp
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const [removeDialogShown, setRemoveDialogShown] = useState(false);

  const device = useSelector((state) => state.devices.items[deviceId]);
  const position = useSelector(getPosition(deviceId));

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');

  const handleRemoveResult = () => {
    setRemoveDialogShown(false);
  };

  const [commandsDetail, setCommandsDetail] = useState(null);

  const handleSendCommand = async () => {
    var url = `/api/installer-commandsDetail/${deviceId}`;
    const response = await api_call(url);
    if (response.ok) {
      setCommandsDetail(await response.json());
    }
  };

  //////////////////// for loop call api ///////////////////////////////////////////////
  const c_loopFlag = useRef(null)
  const startLoopGetCommandStatus = async () => {
    while (c_loopFlag.current) {
      await handleSendCommand()
      await sleep(3000)
      // if (MACHINE_ENVIRONMENT === 'dev') {
      //   c_loopFlag.current = false
      // }
    }
  }

  useEffect(() => {
    c_loopFlag.current = true
    startLoopGetCommandStatus();
    return () => c_loopFlag.current = false
  }, []);
  /////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {

  }, []);

  const [item, setItem] = useState(device);
  useEffect(() => {
    const item_data = {
      ...device
    }
    if (deviceInfo && deviceInfo.id) {
      item_data['isDoubleLock'] = deviceInfo.isDoubleLock ? true : false
      item_data['isDoubleUnlock'] = deviceInfo.isDoubleUnlock ? true : false
    }
    setItem(item_data)
  }, [device, deviceInfo]);


  //////////////////////////////////////////////////////////
  const [commandList, setCommandList] = useState([])
  const commandLabels = {
    Unlock: "Unlock",
    Lock: "Lock",
    Horn: "Honk Horn",
    Unkill: "Unkill",
    Kill: "Kill",
  }
  useEffect(() => {
    if (device?.deviceType) {
      let commands = [];
      const deviceType = device?.deviceType
      if (deviceType === DEVICE_TYPE.MOOVETRAX || deviceType === DEVICE_TYPE.SMARTCAR || deviceType === DEVICE_TYPE.TESLA) {
        commands = ['Unlock', 'Lock', 'Horn', 'Unkill', 'Kill'];
      } else if (deviceType === DEVICE_TYPE.KILLSWITCH || deviceType === DEVICE_TYPE.RLKS) {
        commands = ['Unkill', 'Kill'];
      } else {
        commands = [];
      }
      setCommandList(commands)
    }
  }, [device?.deviceType])

  const handleListItemClick = async (value) => {
    let msg = "Sent Command"
    const response = await api_call(`/api/installer-command?command=${value}&deviceId=${device.id}&userId=-1`); // -1 means installer
    if (response.ok) {
      msg = "Sent Command"
    } else {
      msg = "Failed sending command"
    }
    await handleSendCommand()
    //setNotificationMessage(msg);
    //setNotificationShow(true);
  }

  const getDetail = (command) => {
    // var tempCommand = command;
    // if(command === "Kill/Unkill") tempCommand = "Kill";
    for (var key in commandsDetail) {
      if (key === command) {
        if (key === "Overspeed") return commandsDetail[key].count + "mph";
        else if (key === "Geofence") return commandsDetail[key].count + "mile";
        return formatDate(commandsDetail[key].createdAt);
      }
    }
    return false;
  }
  const getStatus = (command) => {
    return commandsDetail[command].status;
  }

  const [notificationShow, setNotificationShow] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  let sendingSingal = false;
  const OnClickSingal = async () => {
    if (sendingSingal) {
      return false;
    }
    sendingSingal = true;
    console_log("OnClickSingal::::");
    let value = "Iccid"
    let response = await api_call(`/api/installer-command?command=${value}&deviceId=${device.id}&userId=${0}`);
    if (response.ok) {
      console_log("Sent Iccid Command.");
    }
    value = "Rssid"
    response = await api_call(`/api/installer-command?command=${value}&deviceId=${device.id}&userId=${0}`);
    if (response.ok) {
      console_log("Sent Rssid Command.");
    }
    sendingSingal = false;
  }

  const onChangeDoubleCheckbox = (field_name, event) => {
    console_log("field_name, event", field_name, event.target.value)
    const field_value = (event.target.value === "true")
    updateDeviceDoubleStatus(field_name, field_value)
  }

  const updateDeviceDoubleStatus = async (field_name, field_value) => {
    console_log("field_name, field_value::::", field_name, field_value);
    const update_data = {
      id: item.id,
      uniqueId: item.uniqueId
    }
    update_data[field_name] = field_value

    let url = `/api/installer-devices/${item.id}`;
    const response = await api_call(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(update_data),
    });

    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      if (responseObj['error']) {
        alert(responseObj['error'])
      } else {
        setItem({ ...item, ...update_data })
        setRefreshTimestamp(get_utc_timestamp_ms())
      }
    } else {
      alert("Duplicated GPS ID")
    }
  }

  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteLicenseTag, setInviteLicenseTag] = useState("");
  const [inviteVin, setInviteVin] = useState("");

  const onClickInviteUser = async () => {
    if (inviteEmail === "") {
      alert("Enter the invlite email address");
      return false
    }
    if (!validateEmail(inviteEmail)) {
      alert("Please enter valid email address");
      return false
    }

    let url = `/api/users/invite-user-device`;
    const row = {
      email: inviteEmail,
      phone: "",
      name: "",
      uniqueId: item.uniqueId,
      license_tag: inviteLicenseTag.trim(),
      vin: inviteVin.trim().toUpperCase()
    }
    const payload = [row]
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      let msg = responseObj?.message ?? responseObj?.error
      if (msg) {
        alert(msg)
      }
    } else {
      const errorMsg = response?.message ?? (response?.error ?? "Internal server error")
      alert(errorMsg)
    }
  }

  return (
    <>
      <div className={classes.statusViewPopup}>
        <Paper className={classes.paper} elevation={0} square>
          <div className={classes.paperCloseBtn} onClick={() => onClickLogout()}> <CloseIcon /></div>
          <Grid className={classes.installerStatusViewContainer}>
            <Grid item className={classes.flex1}>
              <List>
                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary={`${device.name}:`} />
                </ListItem>
                {
                  (device.deviceType === DEVICE_TYPE.MOOVETRAX) ? (
                    <>
                      <ListItem classes={{ container: classes.listItemContainer }}>
                        <ListItemText primary="Signal" />

                        <DeviceSignalBox
                          viewLocation="InstallerStatusView"
                          device={device}
                          callbackOnClickSingal={() => OnClickSingal()}
                        />
                      </ListItem>
                    </>
                  ) : (
                    <></>
                  )
                }

                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary="Last Position" />
                  <>
                    {formatDate(device.lastPosition)}
                  </>
                </ListItem>

                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary="Last Connect" />
                  <>
                    {formatDate(device.lastConnect)}
                  </>
                </ListItem>

                <ListItem classes={{ container: classes.listItemContainer }}>
                  {/* <ListItemText primary="Address" /> */}
                  <>
                    <GoogleGeolocationAddressBox
                      lat={device.latitude}
                      lng={device.longitude}
                      autoShow={true}
                    />
                  </>
                </ListItem>

                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary={t('positionSpeed')} />
                  {(moment().diff(moment(device.lastPosition), "seconds") < 60) && <>
                    {getSpeedFromMiles(position?.speed, device.distance_unit)}
                  </>}
                </ListItem>
                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary="Odometer" />
                  <>
                    {getDistanceFromMiles(position?.attributes?.distance, device.distance_unit)}
                  </>
                </ListItem>
                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary={t('positionDirection')} />
                  <>
                    {formatPosition(position?.direction, 'direction', t)}
                  </>
                </ListItem>

                <div className='hidden d-none temporary-hidden'>
                  <ListItem classes={{ container: classes.listItemContainer }}>
                    <ListItemText primary="Door" />
                    <ListItemSecondaryAction>
                      {device.door ? "Closed" : "Open"}
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>

                {/* <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary={"Fuel " + ((device.fuel - device.minFuel) / (device.maxFuel - device.minFuel) * 100).toFixed(0) + "%"} />
                  <ListItemSecondaryAction>
                    <BorderLinearProgress variant="determinate" value={(device.fuel - device.minFuel) / (device.maxFuel - device.minFuel) * 100} />
                  </ListItemSecondaryAction>
                </ListItem> */}

                {/* <div className='mt-2'></div> */}
              </List>
            </Grid>

            <Grid item className={classes.flex1}>
              <List>
                {
                  (device.deviceType === DEVICE_TYPE.MOOVETRAX || device.deviceType === DEVICE_TYPE.SMARTCAR || device.deviceType === DEVICE_TYPE.TESLA) ? (
                    <>
                      <ListItem>
                        <ListItemText primary={`Lock`} />
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={item.isDoubleLock}
                            onChange={(event) => onChangeDoubleCheckbox("isDoubleLock", event)}
                            style={{ justifyContent: "space-around" }}
                            row
                          >
                            <FormControlLabel value={true} control={<Radio />} label="Double" />
                            <FormControlLabel value={false} control={<Radio />} label="Single" />
                          </RadioGroup>
                        </FormControl>
                      </ListItem>
                      <ListItem className={classes.minusMarginTop}>
                        <ListItemText primary={`Unlock`} />
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={item.isDoubleUnlock}
                            onChange={(event) => onChangeDoubleCheckbox("isDoubleUnlock", event)}
                            style={{ justifyContent: "space-around" }}
                            row
                          >
                            <FormControlLabel value={true} control={<Radio />} label="Double" />
                            <FormControlLabel value={false} control={<Radio />} label="Single" />
                          </RadioGroup>
                        </FormControl>
                      </ListItem>
                    </>
                  ) : (
                    <></>
                  )
                }
                <div>
                  {(commandsDetail) && commandList.map((command) => (
                    <MyListItem button onClick={() => handleListItemClick(command)} key={command} className={classes.controlBtn}>
                      <ListItemText primary={commandLabels[command]} secondary={getDetail(command)} />
                      {getDetail(command) &&
                        (
                          getStatus(command) === 'success' ?
                            <DoneAllIcon color="secondary" /> :
                            <CheckIcon color="disabled" />
                        )
                      }
                    </MyListItem>
                  ))}
                </div>
              </List>
            </Grid>
          </Grid>

          <div className={classes.inviteContainer}>
            <div className={classes.inviteBlock}>
              <Stack direction={matchesXs ? `column` : `row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1} style={{ width: '100%' }}>
                <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} style={{ width: '100%' }}>
                  <Typography className={classes.inviteBlockLabel} variant='body1' component={`span`}>License Plate</Typography>
                  <TextField
                    margin="none"
                    value={inviteLicenseTag}
                    onChange={(event) => setInviteLicenseTag(event.target.value)}
                    label=""
                    placeholder=''
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        fontStyle: 'normal',
                      }
                    }}
                    className={classes.flex1}
                  />
                </Stack>
                <Box sx={{ width: matchesXs ? '100%' : 'auto', display: matchesXs ? 'none' : 'block' }}>
                  <Button type="button" color="primary" variant="contained" fullWidth onClick={() => { onClickInviteUser() }} style={{ visibility: 'hidden' }}>
                    Submit
                  </Button>
                </Box>
              </Stack>
            </div>
            <div className={classes.inviteBlock}>
              <Stack direction={matchesXs ? `column` : `row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1} style={{ width: '100%' }}>
                <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} style={{ width: '100%' }}>
                  <Typography className={classes.inviteBlockLabel} variant='body1' component={`span`}>VIN</Typography>
                  <TextField
                    margin="none"
                    value={inviteVin}
                    onChange={(event) => setInviteVin(event.target.value.toUpperCase())}
                    label=""
                    placeholder=''
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        fontStyle: 'normal',
                      }
                    }}
                    className={classes.flex1}
                  />
                </Stack>
                <Box sx={{ width: matchesXs ? '100%' : 'auto', display: matchesXs ? 'none' : 'block' }}>
                  <Button type="button" color="primary" variant="contained" fullWidth onClick={() => { onClickInviteUser() }} style={{ visibility: 'hidden' }}>
                    Submit
                  </Button>
                </Box>
              </Stack>
            </div>
            <div className={classes.inviteBlock}>
              <Stack direction={matchesXs ? `column` : `row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1} style={{ width: '100%' }}>
                <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} style={{ width: '100%' }}>
                  <Typography className={classes.inviteBlockLabel} variant='body1' component={`span`}>Invite Owner</Typography>
                  <TextField
                    margin="none"
                    value={inviteEmail}
                    onChange={(event) => setInviteEmail(event.target.value)}
                    label=""
                    placeholder='Enter car owner email address'
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        fontStyle: 'normal',
                      }
                    }}
                    className={classes.flex1}
                  />
                </Stack>
                <Box sx={{ width: matchesXs ? '100%' : 'auto' }}>
                  <Button type="button" color="primary" variant="contained" fullWidth onClick={() => { onClickInviteUser() }}>
                    Submit
                  </Button>
                </Box>
              </Stack>
            </div>
          </div>
          <div className={classes.inviteContainer}>
            <div className={classes.inviteBlock}>
              <Link to="/installer-signup" className={classes.installerSignupLink} target="_blank">Installer Signup</Link>
            </div>
          </div>

        </Paper>
      </div>

      <RemoveDialog open={removeDialogShown} endpoint="devices" itemId={deviceId} onResult={handleRemoveResult} />
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={notificationShow}
        message={notificationMessage}
        autoHideDuration={1500}
        onClose={() => { setNotificationShow(false) }}
      />
    </>

  );
};

export default InstallerStatusView;
