import { Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom"
import FrontSiteNavbar from './FrontSiteNavbar';
import { api_call, console_log, empty, get_data_value, isEmpty } from './helpers/untils';

export default function ShopIntroSite() {
    const history = useHistory()
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "./assets/js/contact.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [appSetting, setAppSetting] = useState({});

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                onClose={() => { setSnackbarOpen(false) }}
                autoHideDuration={5000}
                message={`Thank you, If you do not hear back in 24 hours, please give us at call at 202-979-TRAX`}
            />

            <FrontSiteNavbar
                setAppSetting={setAppSetting}
            />
            <main id="content" role="main">
                <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
                    <div className="shop-intro-box">
                        {/* <div className="card-header border-bottom text-center">
                            <h3 className="card-header-title">Shop</h3>
                        </div> */}
                        <div className="shop-intro-box-inner">
                            <div className="row">
                                <div className="col-lg-6 mb-9 mb-lg-0 text-center">
                                    <a className="shop-item" href={get_data_value(appSetting, 'SHOPPING_AMAZON_LINK')} aria-label="Amazon" target="_blank">
                                        <img className="navbar-brand-logo" src="/assets/img/shop/amazon-shop.webp" alt="Amazon" />
                                    </a>
                                </div>
                                <div className="col-lg-6 mb-9 mb-lg-0 text-center">
                                    <a className="shop-item" href={get_data_value(appSetting, 'SHOPPING_CART_LINK')} aria-label="Shopify" target="_blank">
                                        <img className="navbar-brand-logo" src="/assets/img/logo.png" alt="Shopify" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <a className="js-go-to go-to position-fixed" href="javascript:;" style={{ visibility: 'hidden' }}
                data-hs-go-to-options='{
            "offsetTop": 700,
            "position": {
                "init": {
                "right": "2rem"
                },
                "show": {
                "bottom": "2rem"
                },
                "hide": {
                "bottom": "-2rem"
                }
            }
            }'>
                <i className="bi-chevron-up"></i>
            </a>
        </React.Fragment>
    )
}
