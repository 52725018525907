import React, { useState } from 'react';
import { useHistory } from "react-router-dom"
import { makeStyles, Button } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { api_call, console_log, get_utc_timestamp_ms } from '../helpers/untils';
import CameraUploadModal from '../components/CameraUploadModal/CameraUploadModal';

const useStyles = makeStyles((theme) => ({
    uploadBoxContainer: {
        maxWidth: '400px',
        margin: '15px auto',
        padding: '30px',
        backgroundColor: 'rgba(127,127,127,0)',
        border: '1px solid rgba(127,127,127,0.2)',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
    },
    uploadButtonList: {
        marginBottom: '10px',
        maxHeight: 'calc(100vh - 420px)',
        overflowY: 'auto'
    },
    uploadButton: {
        width: '100%',
        border: '2px solid rgba(76, 175, 80, 1)',
        backgroundColor: 'rgba(76, 175, 80, 0.1)',
        minHeight: '52px',
        height: 'auto',
        marginBottom: '10px',
        color: 'rgba(0, 0, 0, 0.7)',
        textTransform: 'none',
        "&$selected": {
            backgroundColor: "rgba(76, 175, 80, 0.2)",
        },
        "&$selected:hover": {
            backgroundColor: "rgba(76, 175, 80, 0.2)",
        },
        "&:hover": {
            backgroundColor: "rgba(76, 175, 80, 0.2)",
        }
    },
    uploadGrayButton: {
        width: '100%',
        border: '2px solid rgb(202 202 202)',
        backgroundColor: 'rgb(234 234 234)',
        minHeight: '52px',
        height: 'auto',
        marginBottom: '10px',
        color: 'rgba(0, 0, 0, 0.7)',
        textTransform: 'none',
    },
    uploadFileInput: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        cursor: 'pointer',
        opacity: 0
    },
}));

const TemporaryCameraUploadSection = (props) => {
    const { link, setLink, current_timestamp, type = "avail", approve_status = "", loadLinkInfo, isTest = false } = props;
    const classes = useStyles()
    const history = useHistory()
    const [apiLoading, setApiLoading] = useState(false)
    const [cameraModalOpen, setCameraModalOpen] = useState(false)
    const [cameraOpenTimestamp, setCameraOpenTimestamp] = useState(0)

    const [cameraPhotos, setCameraPhotos] = useState({})
    const [files, setFiles] = useState({});
    const handleFileChange = (e, key) => {
        console_log("e, key::::", e, key)
        if (e.target.files) {
            const updatedFiles = {
                ...files
            }
            updatedFiles[key] = e.target.files[0]
            setFiles({ ...updatedFiles });
            console_log("updatedFiles", updatedFiles)
        }
    }

    const checkValidation = () => {
        const share_settings_obj = JSON.parse(link.share_settings)
        let keyList = share_settings_obj[type]
        if (keyList && keyList.length > 0) {
            for (let i in keyList) {
                let k = keyList[i]
                if (cameraPhotos[k]) {
                    continue;
                } else {
                    alert("Please upload " + k);
                    return false;
                }
            }
        }
        return true
    }
    const submitPhotos = async () => {
        const isValid = checkValidation()
        console_log("isValid", isValid)
        if (isValid) {
            const url = `/api/links/uploadCameraPhoto`;
            const payload = {}
            payload['type'] = type
            payload['linkId'] = link.id
            payload['cameraPhotos'] = cameraPhotos
            setApiLoading(true)
            let response = await api_call(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' }, //should be json
                body: JSON.stringify(payload),
            })
            setApiLoading(false)
            if (response.ok) {
                const apiData = await response.json()
                const newLink = apiData.link
                loadLinkInfo()
            } else {
                const apiError = response
                console_log("apiError:::", apiError)
                if (apiError.error) {
                    alert(apiError.error);
                }
            }
        }
    }

    const [currentFileKey, setCurrentFileKey] = useState("")
    const onPickupPhoto = (imageDataUrl) => {
        console.log(`imageDataUrl::::`, imageDataUrl)
        const camera_photos = {
            ...cameraPhotos
        }
        const fileKey = currentFileKey
        camera_photos[fileKey] = imageDataUrl
        setCameraPhotos(camera_photos)
    }
    const [cameraSide, setCameraSide] = useState('')
    const onClickCameraPhotoUpload = (fileKey) => {
        setCurrentFileKey(fileKey)
        let camera_side = ''
        if (fileKey === 'Selfie With License' || fileKey === 'Selfie With Car License Plate') {
            camera_side = 'front'
        } else {
            camera_side = 'back'
        }
        setCameraSide(camera_side)
        setCameraOpenTimestamp(get_utc_timestamp_ms())
        setCameraModalOpen(true)
    }

    const uploadButtonItems = (share_settings, key = "avail") => {
        const share_settings_obj = JSON.parse(share_settings)
        return (
            <>
                {share_settings_obj[key].map((i, index) => {
                    if (i) {
                        return (
                            <React.Fragment key={index}>
                                <div className={classes.uploadButtonItem}>
                                    <Button
                                        size="large"
                                        variant="outlined"
                                        endIcon={<CameraAltIcon />}
                                        className={cameraPhotos[i] ? classes.uploadGrayButton : classes.uploadButton}
                                        onClick={() => onClickCameraPhotoUpload(i)}
                                    >
                                        {i}
                                    </Button>
                                </div>
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={index}></React.Fragment>
                        )
                    }
                })}
            </>
        )
    }

    return (
        <React.Fragment>
            <div className="row justify-content-between align-items-center flex-grow-1">
                <div className="col-md-12">
                    <div className={classes.uploadBoxContainer}>
                        {
                            (approve_status === 'pending') ? (
                                <>
                                    <h5 className={classes.uploadBoxTitle}>Please wait for approval</h5>
                                </>
                            ) : (
                                <>
                                    <h5 className={classes.uploadBoxTitle}>{`Please Upload files below:`}</h5>
                                    {
                                        (link && link.share_settings) ? (
                                            <>
                                                <div className={classes.uploadButtonList}>
                                                    {
                                                        uploadButtonItems(link.share_settings, type)
                                                    }
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    <Button
                                        fullWidth
                                        type="button"
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        onClick={() => submitPhotos()}
                                        disabled={apiLoading}
                                        style={{ textTransform: 'none' }}
                                    >
                                        {apiLoading ? 'Please wait...' : `Submit ${isTest ? '(Test Mode)' : ''}`}
                                    </Button>
                                </>
                            )
                        }

                    </div>
                </div>
            </div>

            <CameraUploadModal
                open={cameraModalOpen}
                setOpen={setCameraModalOpen}
                onPickupPhoto={onPickupPhoto}
                cameraSide={cameraSide}
                cameraOpenTimestamp={cameraOpenTimestamp}
                setCameraOpenTimestamp={setCameraOpenTimestamp}
            />

        </React.Fragment>
    )
}
export default TemporaryCameraUploadSection;