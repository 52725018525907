import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Checkbox, Box, Link, Modal, Grid, Toolbar,
} from '@material-ui/core';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditAttributesView from './attributes/EditAttributesView';
import SelectField from './form/SelectField';
import deviceCategories from './common/deviceCategories';
import LinkField from './form/LinkField';
import { prefixString } from './common/stringUtils';
import { useTranslation } from './LocalizationProvider';
import useDeviceAttributes from './attributes/useDeviceAttributes';
import { Button } from '@material-ui/core';
import CreditLogsModal from './settings/CreditLogsModal';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import CarTypeTab from './components/CarTypeTab';
import SmartcarDeviceEditPage from './SmartcarDeviceEditPage';

import moment from 'moment';
import { console_log } from './helpers/untils';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
  checkboxColumn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  imgSmall: {
    width: '50px',
    height: '42px',
    marginLeft: '5px',
    marginTop: '16px',
    marginBottom: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'center'
  },
}));

const LinkPage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { id } = useParams();

  const history = useHistory()

  const user = useSelector((state) => state.session.user);
  const [item, setItem] = useState();

  const [from, setFrom] = useState(moment());
  const [to, setTo] = useState(moment().add(1, 'day'));

  const current = moment();

  useEffect(() => {
    if (item?.id) {
      if (moment(item.from) < current && current < moment(item.to)) { // if item is active
        setFrom(moment(item.from))
        setTo(moment(item.to))
      } else {
        history.push("/settings/links")
      }
    }
  }, [item?.id])

  const getDiffDays = (_from, _to) => {
    const fromStr = _from.format('YYYY-MM-DD HH:mm')
    const toStr = _to.format('YYYY-MM-DD HH:mm')
    console_log(`fromStr, toStr::::`, fromStr, toStr)
    const _from1 = moment(`${fromStr}:00`, 'YYYY-MM-DD HH:mm:ss')
    const _to1 = moment(`${toStr}:00`, 'YYYY-MM-DD HH:mm:ss')
    console_log(`_from1, _to1::::`, _from1, _to1)
    let diff_seconds = _to1.diff(_from1, 'seconds')
    if (diff_seconds < 0) {
      console_log(`diff_seconds::::`, diff_seconds)
      return 0
    }

    let diff_days = diff_seconds / (24 * 3600);  //let diff_days = _to1.diff(_from1, 'days')
    diff_days = Math.ceil(diff_days)

    console_log(`diff_days::::`, diff_days)
    // if (diff_days === 0) {
    //   diff_days = 1
    // }
    return diff_days
  }

  const getAllotPerDay = () => {
    const form_data = { ...item }
    const allot_miles = Number(form_data['allot_miles'])
    const _from = moment(form_data['from']) 
    const _to = moment(form_data['to']) 
    const diff_days = getDiffDays(_from, _to)
    let allot_per_day = allot_miles / diff_days
    console_log(`allot_miles, allot_per_day:::`, allot_miles, allot_per_day)
    allot_per_day = Math.floor(allot_per_day * 1000000000) / 1000000000
    return allot_per_day
  }

  const onChangeFormData = (fieldName, fieldValue) => {
    const itemData = { ...item }
    if (fieldName === 'to') {    
      let allot_per_day = getAllotPerDay()
      const diff_days = getDiffDays(moment(itemData['from']), fieldValue)
      let allot_miles = diff_days * allot_per_day
      console_log(`allot_miles:::`, allot_miles)
      allot_miles = Math.ceil(allot_miles)
      itemData['allot_miles'] = allot_miles

      setTo(fieldValue)
      fieldValue = fieldValue.toISOString()
    }
    else if (fieldName === 'from') {
      let allot_per_day = getAllotPerDay()
      const diff_days = getDiffDays(fieldValue, moment(itemData['to']))
      let allot_miles = diff_days * allot_per_day
      console_log(`allot_miles:::`, allot_miles)
      allot_miles = Math.ceil(allot_miles)
      itemData['allot_miles'] = allot_miles
      
      setFrom(fieldValue)
      fieldValue = fieldValue.toISOString()
    }

    itemData[fieldName] = fieldValue
    setItem({ ...itemData })
  }
  return (
    <>
      <EditItemView endpoint="links" item={item} setItem={setItem}>
        {item
          && (
            <>
              <Accordion defaultExpanded disabled={true}>
                <>
                  <AccordionSummary>
                    <Typography variant="subtitle1">
                      Edit Link
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2} justifyContent="flex-start">
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="filled"
                          label="From"
                          type="datetime-local"
                          value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                          onChange={(e) => onChangeFormData("from", moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))}
                          fullWidth
                          // disabled={true}
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="filled"
                          label="To"
                          type="datetime-local"
                          value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                          onChange={(e) => onChangeFormData("to", moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL))}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </>
              </Accordion>
            </>
          )}
      </EditItemView>
    </>
  );
};

export default LinkPage;
