import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Typography,
  Divider,
  Drawer,
  makeStyles,
  IconButton,
  Hidden,
  Button,
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SideNav from '../../components/SideNav';
import NavBar from '../../components/NavBar';
import useRoutes from './useRoutes';
import { useTranslation } from '../../LocalizationProvider';
import { getIsAdmin } from '../../common/selectors';
import { useSelector } from 'react-redux';
import { api_call } from '../../helpers/untils';
import { getSettingPersist, setSettingPersist } from '../../helpers/misc';
import FooterBox from './FooterBox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawerContainer: {
    width: theme.dimensions.drawerWidthDesktop,
  },
  drawer: {
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('md')]: {
      width: theme.dimensions.drawerWidthTablet,
    },
  },
  content: {
    flex: 1,
    padding: theme.spacing(5, 3, 3, 3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(10),
    },
  },
  drawerHeader: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  toolbar: {
    [theme.breakpoints.down('md')]: {
      ...theme.mixins.toolbar,
    },
  },
  drawerFooter: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  drawerFooterContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    opacity: 0.7
  },
  drawerFooterContentItem: {
    marginBottom: theme.spacing(1),
  },
  drawerFooterContentItemLabel: {
    minWidth: '5em',
    display: 'inline-block'
  }
}));

const OptionsLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const routes = useRoutes();
  const t = useTranslation();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [optionTitle, setOptionTitle] = useState();

  const isAdmin = useSelector(getIsAdmin);

  const defaultServerInfo = {
    serverPrivateIp: "",
    serverPublicIp: ""
  }

  const getServerInfoFromLocalStorage = () => {
    const server_info = getSettingPersist('serverInfo', defaultServerInfo)
    return server_info
  }

  const [serverInfo, setServerInfo] = useState(getServerInfoFromLocalStorage())

  const getServerInfo = async () => {
    let url = `/api/get-env`
    let response = await api_call(url, {
      method: 'GET',
    });

    if (response.ok) {
      const server_info = await response.json()
      setServerInfo(server_info)
      setSettingPersist('serverInfo', server_info)
    } else {
      return false
    }
  }

  useEffect(() => {
    if (isAdmin) {
      getServerInfo()
    }
  }, []);

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.indexOf(route.href) > -1) {
        setOptionTitle(route.name);
      } else if (location.pathname.indexOf("/devices") > -1) {
        setOptionTitle("Devices");
      } else if (location.pathname.indexOf("/device/") > -1) {
        setOptionTitle("Device Detail");
      } else if (location.pathname.indexOf("/device") > -1 || location.pathname.indexOf("/smartcar-device") > -1) {
        setOptionTitle("Device Detail");
      } else if (location.pathname.indexOf("/user/") > -1) {
        setOptionTitle("User Detail");
      } else if (location.pathname.indexOf("/link/") > -1) {
        setOptionTitle("Edit Link");
      } else if (location.pathname.indexOf("/smartcar-device") > -1) {
        setOptionTitle("Smartcar Vehicles");
      } else if (location.pathname.indexOf("/tesla-device") > -1) {
        setOptionTitle("Tesla Vehicles");
      } else if (location.pathname.indexOf("/oem-device") > -1) {
        setOptionTitle("OEM Car App");
      } else if (location.pathname.indexOf("/geofence") > -1) {
        setOptionTitle("Geofence");
      }
    });
  }, [location, routes]);

  return (
    <div className={classes.root}>
      <Hidden lgUp>
        <NavBar setOpenDrawer={setOpenDrawer} title={`${t('settingsTitle')} / ${optionTitle}`} />
        <Drawer
          variant="temporary"
          open={openDrawer}
          onClose={() => setOpenDrawer(!openDrawer)}
          classes={{ paper: classes.drawer }}
        >
          <SideNav routes={routes} />

          {
            (isAdmin) ? (
              <FooterBox serverInfo={serverInfo} isAdmin={isAdmin} />
            ) : (
              <FooterBox serverInfo={serverInfo} isAdmin={isAdmin} />
            )
          }

        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          variant="permanent"
          classes={{ root: classes.drawerContainer, paper: classes.drawer }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => history.push('/main')}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              {t('settingsTitle')}
            </Typography>
          </div>
          <Divider />
          <SideNav routes={routes} />

          {
            (isAdmin) ? (
              <FooterBox serverInfo={serverInfo} isAdmin={isAdmin} />
            ) : (
              <FooterBox serverInfo={serverInfo} isAdmin={isAdmin} />
            )
          }

        </Drawer>
      </Hidden>

      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default OptionsLayout;
