import { Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from "react-router-dom"
import { RECAPTCHA_KEY } from './config/constant';
import FrontSiteNavbar from './FrontSiteNavbar';
import { api_call, console_log, empty, getToken, isEmpty } from './helpers/untils';
import { Stack } from '@mui/material';

export default function ContactSite() {

    const history = useHistory()

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = "./assets/js/animation.js";
    //     script.async = true;
    //     document.body.appendChild(script);
    //     return () => {
    //         document.body.removeChild(script);
    //     }
    // }, []);

    const LoginEvent = () => {
        history.push('/login')
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "./assets/js/contact.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const defaultFormData = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        business: "",
        state: "",
        zip_code: "",
        questions: ""
    }
    const [formData, setFormData] = useState(defaultFormData);
    const [errorField, setErrorField] = useState([]);
    const requiredFieldList = ["first_name", "last_name", "email"]

    const onChangeFormFIeld = (field_name, field_value) => {
        const updatedData = { ...formData }
        updatedData[field_name] = field_value
        validateFields(updatedData, field_name)
        setFormData(updatedData)
    }
    const validateFields = (updatedData = null, field_name = null) => {
        if (updatedData === null) {
            updatedData = { ...formData }
        }
        var errorList = [...errorField]
        if (field_name !== null) {
            if (requiredFieldList.includes(field_name)) {
                errorList = isEmpty(updatedData, field_name, errorList);
            }
        } else {
            for (let i = 0; i < requiredFieldList.length; i++) {
                errorList = isEmpty(updatedData, requiredFieldList[i], errorList);
            }
        }
        setErrorField([...errorList]);
        return errorList
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [apiLoading, setApiLoading] = useState(false)

    const onClickSubmit = async () => {
        if (!window.checkRecaptchaIsValid()) {
            alert("You are not a robot?");
            return false;
        }

        let errorList = validateFields();
        if (errorList.length > 0) {
            return false;
        }

        setApiLoading(true)

        //let response = {ok:true};
        let response = await api_call('/api/contact/submit', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });
        setApiLoading(false)

        setFormData(defaultFormData)
        if (response.ok) {
            setSnackbarOpen(true);
        }

    }

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                onClose={() => { setSnackbarOpen(false) }}
                autoHideDuration={5000}
                message={`Thank you, If you do not hear back in 24 hours, please give us at call at 202-979-TRAX`}
            />

            <FrontSiteNavbar />
            <main id="content" role="main">
                {/* <!-- Contact Form --> */}
                <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2">
                    <div className="row">
                        <div className="col-lg-6 mb-9 mb-lg-0">
                            {/* <!-- Heading --> */}
                            <div className="mb-5">
                                <h1>Get in touch</h1>
                            </div>
                            {/* <!-- End Heading --> */}

                     
                            {/* <div className="overflow-hidden">
                                <div id="map" className="leaflet mb-5"
                                    data-hs-leaflet-options='{
                            "map": {
                            "scrollWheelZoom": false,
                            "coords": [28.54023214696275, -81.3715127476033]
                            },
                            "marker": [
                            {
                                "coords": [28.54023214696275, -81.3715127476033],
                                "icon": {
                                "iconUrl": "./assets/svg/components/map-pin.svg",
                                "iconSize": [50, 45]
                                },
                                "popup": {
                                "text": "Orlando, FL 32806, United States"
                                }
                            }
                            ]
                            }'></div>
                            </div> */}

                            <div className="row">

                                <div className="col-sm-6">
                                    <h5 className="mb-1">Email us:</h5>
                                    <p>support@moovetrax.com</p>
                                </div>
                                {/* <!-- End Col --> */}

                                <div className="col-sm-6">
                                    <h5 className="mb-1">Address:</h5>
                                    <p>Orlando FL 32827</p>
                                </div>

                                <div className="col-sm-6">
                                    <h5 className="mb-1">Phone:</h5>
                                    <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={2} sx={{ mb: 1 }}>
                                        <p style={{ marginBottom: 0 }}>1-(202) 979-TRAX</p>
                                        <a href="https://wa.me/12029798729" target="_blank">
                                            <img src='/assets/img/landing/whatsapp.png' className='img-responsive' alt="moovetrax-whatsapp" style={{ width: '20px', height: 'auto' }} />
                                        </a>
                                    </Stack>
                                    <p>1-(202) 979-8729</p>
                                </div>

                                <div className="col-sm-12">
                                    <div className='mt-2'>
                                        <a href='https://www.facebook.com/groups/moovetrax/' target="_blank">
                                            <img src='/assets/img/landing/facebook-logo.png' className='img-responsive' alt="moovetrax-facebook" style={{ width: '28px', height: 'auto' }} />
                                        </a>
                                    </div>
                                </div>

                                {/* <!-- End Col --> */}
                            </div>
                            {/* <!-- End Row --> */}
                        </div>
                        {/* <!-- End Col --> */}

                        <div className="col-lg-6">
                            <div className="ps-lg-5">
                                {/* <!-- Card --> */}
                                <div className="card">
                                    <div className="card-header border-bottom text-center">
                                        <h3 className="card-header-title">Contact Us</h3>
                                    </div>

                                    <div className="card-body">
                                        {/* <!-- Form --> */}
                                        <form>
                                            <div className="row gx-3">
                                                <div className="col-sm-6">
                                                    {/* <!-- Form --> */}
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="first_name">First name *</label>
                                                        <input
                                                            type="text"
                                                            className={`form-control form-control-lg ${errorField.includes("first_name") ? "is-invalid" : ""
                                                                }`}
                                                            value={formData["first_name"]}
                                                            onChange={(e) => {
                                                                onChangeFormFIeld("first_name", e.target.value);
                                                            }}
                                                            name="first_name"
                                                            id="first_name"
                                                            placeholder=""
                                                            aria-label="" required />
                                                    </div>
                                                    {/* <!-- End Form --> */}
                                                </div>
                                                {/* <!-- End Col --> */}

                                                <div className="col-sm-6">
                                                    {/* <!-- Form --> */}
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="last_name">Last name *</label>
                                                        <input type="text"
                                                            className={`form-control form-control-lg ${errorField.includes("last_name") ? "is-invalid" : ""
                                                                }`}
                                                            value={formData["last_name"]}
                                                            onChange={(e) => {
                                                                onChangeFormFIeld("last_name", e.target.value);
                                                            }}
                                                            name="last_name" id="last_name" placeholder="" aria-label="" required />
                                                    </div>
                                                    {/* <!-- End Form --> */}
                                                </div>
                                                {/* <!-- End Col --> */}
                                            </div>
                                            {/* <!-- End Row --> */}

                                            <div className="row gx-3">
                                                <div className="col-sm-6">
                                                    {/* <!-- Form --> */}
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="email">Email address *</label>
                                                        <input type="email"
                                                            className={`form-control form-control-lg ${errorField.includes("email") ? "is-invalid" : ""
                                                                }`}
                                                            value={formData["email"]}
                                                            onChange={(e) => {
                                                                onChangeFormFIeld("email", e.target.value);
                                                            }}
                                                            name="email" id="email" placeholder="email@site.com" aria-label="email@example.com" required />
                                                    </div>
                                                    {/* <!-- End Form --> */}
                                                </div>
                                                {/* <!-- End Col --> */}

                                                <div className="col-sm-6">
                                                    {/* <!-- Form --> */}
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="phone">Phone</label>
                                                        <input type="tel"
                                                            className={`form-control form-control-lg ${errorField.includes("phone") ? "is-invalid" : ""
                                                                }`}
                                                            value={formData["phone"]}
                                                            onChange={(e) => {
                                                                onChangeFormFIeld("phone", e.target.value);
                                                            }}
                                                            name="phone" id="phone" aria-label="+x(xxx)xxx-xx-xx" />
                                                    </div>
                                                    {/* <!-- End Form --> */}
                                                </div>
                                                {/* <!-- End Col --> */}
                                            </div>
                                            {/* <!-- End Row --> */}

                                            <div className="row gx-3">
                                                <div className="col-sm-12">
                                                    {/* <!-- Form --> */}
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="business">Business</label>
                                                        <input type="text"
                                                            className={`form-control form-control-lg ${errorField.includes("business") ? "is-invalid" : ""
                                                                }`}
                                                            value={formData["business"]}
                                                            onChange={(e) => {
                                                                onChangeFormFIeld("business", e.target.value);
                                                            }}
                                                            name="business" id="business" placeholder="" aria-label="email@site.com" />
                                                    </div>
                                                    {/* <!-- End Form --> */}
                                                </div>
                                                {/* <!-- End Col --> */}
                                            </div>
                                            {/* <!-- End Row --> */}

                                            <div className="row gx-3">
                                                <div className="col-sm-6">
                                                    {/* <!-- Form --> */}
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="state">State</label>
                                                        <input type="text"
                                                            className={`form-control form-control-lg ${errorField.includes("state") ? "is-invalid" : ""
                                                                }`}
                                                            value={formData["state"]}
                                                            onChange={(e) => {
                                                                onChangeFormFIeld("state", e.target.value);
                                                            }}
                                                            name="state" id="state" placeholder="" aria-label="" />
                                                    </div>
                                                    {/* <!-- End Form --> */}
                                                </div>
                                                {/* <!-- End Col --> */}

                                                <div className="col-sm-6">
                                                    {/* <!-- Form --> */}
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="zip_code">Zip Code</label>
                                                        <input type="text"
                                                            className={`form-control form-control-lg ${errorField.includes("zip_code") ? "is-invalid" : ""
                                                                }`}
                                                            value={formData["zip_code"]}
                                                            onChange={(e) => {
                                                                onChangeFormFIeld("zip_code", e.target.value);
                                                            }}
                                                            name="zip_code" id="zip_code" placeholder="" aria-label="" />
                                                    </div>
                                                    {/* <!-- End Form --> */}
                                                </div>
                                                {/* <!-- End Col --> */}
                                            </div>
                                            {/* <!-- End Row --> */}

                                            {/* <!-- Form --> */}
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="questions">Questions / Comments</label>
                                                <textarea
                                                    className={`form-control form-control-lg ${errorField.includes("questions") ? "is-invalid" : ""
                                                        }`}
                                                    value={formData["questions"]}
                                                    onChange={(e) => {
                                                        onChangeFormFIeld("questions", e.target.value);
                                                    }}
                                                    name="questions" id="questions" placeholder="" aria-label="" rows="4"></textarea>
                                            </div>

                                            <div className="mb-3">
                                                <div
                                                    className="g-recaptcha"
                                                    data-sitekey={RECAPTCHA_KEY}
                                                    data-callback="recaptchaCallback"
                                                    data-expired-callback="recaptchaErrorCallback"
                                                    data-error-callback="recaptchaErrorCallback">
                                                </div>
                                            </div>

                                            {/* <!-- End Form --> */}

                                            <div className="d-grid">
                                                <button
                                                    type="button"
                                                    id="btnSubmit"
                                                    className="btn btn-primary btn-lg"
                                                    disabled={apiLoading}
                                                    onClick={() => onClickSubmit()}
                                                >Submit</button>
                                            </div>
                                        </form>
                                        {/* <!-- End Form --> */}
                                    </div>
                                </div>
                                {/* <!-- End Card --> */}
                            </div>
                        </div>
                        {/* <!-- End Col --> */}
                    </div>
                    {/* <!-- End Row --> */}
                </div>
                {/* <!-- End Contact Form --> */}


                <div className="bg-dark overflow-hidden">
                    <div className="container content-space-2 ">
                        <div className="w-md-75 w-lg-60 text-center mx-md-auto mb-5 mb-md-6">
                            <h3 className="text-white mb-3">This device is hard wired and we will help you locate a local installer.</h3>
                        </div>

                        <div className="d-md-flex justify-content-center align-items-md-center text-center">
                            <div className="d-md-flex justify-content-start align-items-md-center text-center">
                                <h4 className="text-white text-indigo my-2">Instructions for installers can be found at:</h4>
                                <a className="my-2 ms-sm-3 btn d-block d-sm-inline-block btn-primary btn-transition" href="https://docs.google.com/document/d/e/2PACX-1vSoMlBHCAwoLdX24TbvOpfv8pUgCRIA0DCWHVBtA_iLO5XCOi9U5EnfQYRS486ha7HyMuIwfvcV-2sz/pub?fbclid=IwAR32Amfg6100hcXaS0Djp_McUrQ6ItvPapFVg7Kk4_eIxNrXWlaIhgKDJqs" target="_blank">&nbsp;Here&nbsp;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <a className="js-go-to go-to position-fixed" href="javascript:;" style={{ visibility: 'hidden' }}
                data-hs-go-to-options='{
            "offsetTop": 700,
            "position": {
                "init": {
                "right": "2rem"
                },
                "show": {
                "bottom": "2rem"
                },
                "hide": {
                "bottom": "-2rem"
                }
            }
            }'>
                <i className="bi-chevron-up"></i>
            </a>
        </React.Fragment>
    )
}
