import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { Snackbar, Box } from '@material-ui/core';
import { useEffectAsync } from './reactHelper';
import OptionsLayout from './settings/OptionsLayout';
import { useTranslation } from './LocalizationProvider';
import { api_call, console_log, empty, get_utc_timestamp_ms } from './helpers/untils';
import { getIsAdmin } from './common/selectors';
import { sleep } from './helpers/misc';
import { layoutActions } from './store';
import { DEVICE_TYPE } from './config/constant';
import { sessionActions } from './store';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

const EditItemView = ({
  children, endpoint, item, setItem, endpointParam1, childrenAfter, hideButtons = false, callbackAfterSave
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const t = useTranslation();

  const { id } = useParams();
  const userId = useSelector((state) => state.session.user.id);
  const isAdmin = useSelector(getIsAdmin);

  useEffect(() => {
    loadItemData()
  }, [id]);

  const loadItemData = async () => {
    if (id) {
      const response = await api_call(`/api/${endpoint}/${id}`);
      if (response.ok) {
        const itemInfo = await response.json()
        if (endpoint === 'devices') {
          if (!empty(id)) {
            if (itemInfo?.editable !== true) {
              redirectToMainPage()
              return true
            }
          }
        }
        setItem(itemInfo);
      }
    }
    else {
      let defaultItem = {}
      if (endpoint === 'devices') {
        if (endpointParam1 !== DEVICE_TYPE.SMARTCAR && endpointParam1 !== DEVICE_TYPE.TESLA) {
          defaultItem['isDoubleUnlock'] = 1
          defaultItem['enableInstaller'] = 1
          defaultItem['enableCycle'] = 1
          defaultItem['uniqueId'] = ""
          defaultItem['measure_type'] = "smoke"
          defaultItem['iccid_prefix'] = ""
        } else {
          return false;
        }
      } else if (endpoint === 'user-profile') {
        const response = await api_call(`/api/${endpoint}`);
        if (response.ok) {
          setItem(await response.json());
        }
        return true
      } else if (endpoint === 'geofences') {
        // do nothing if geofence route 
        return true
      }
      setItem(defaultItem);
    }
  }

  const handleSave = async () => {
    let url = `/api/${endpoint}`;
    if (endpoint === "devices") item = { ...item, userId: userId, isAdmin: isAdmin };
    if (id) {
      url += `/${id}`;
    }

    const isValid = checkFormValidate()
    if (!isValid) {
      return false;
    }

    if (endpoint === "devices") {
      await processBeforeDeviceSaveApi()
    }

    const response = await api_call(url, {
      method: !id ? 'POST' : 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(item),
    });

    if (endpoint === "devices") {
      await processAfterDeviceSaveApi(response, item)
      return true
    }

    if (response.ok) {
      let responseObj = await response.json()
      console.log("responseObj::::", responseObj)

      if (responseObj['error']) {
        alert(responseObj['error'])
      } else {
        if (endpoint === "devices") {
          history.push("/main")
        }
        else if (endpoint === "geofences") {
          history.push("/geofences")
        }
        else if (endpoint === "user-profile") {
          alert("Profile has been save successfully")
        }
        else {
          history.goBack();
        }
      }
    } else {
      console.log("response not ok:::", response)
      const errorMsg = response?.message ?? (response?.error ?? "Internal server error")
      alert(errorMsg)
    }
  };

  const checkFormValidate = () => {
    //console.log("item, endpointParam1:::::::", item, endpointParam1)
    let isValid = true;
    if (endpoint === 'devices') {
      if (endpointParam1 === DEVICE_TYPE.SMARTCAR || endpointParam1 === DEVICE_TYPE.TESLA) {
        if (empty(item?.vehicleId)) {
          if (empty(item['vehicle_idxs']) || item['vehicle_idxs'].length === 0) {
            alert("No vehicle has been selected")
            isValid = false;
            return isValid;
          }
        }
      } else {
        if (isAdmin) { // check gps id only
          if (empty(item['uniqueId'])) {
            alert("GPS ID is empty")
            isValid = false;
            return isValid;
          }
        } else { // check gpsId and iccid
          if (empty(item['uniqueId'])) {
            alert("GPS ID is empty")
            isValid = false;
            return isValid;
          }
          if (empty(item['iccid'])) {
            alert("ICCID is empty")
            isValid = false;
            return isValid;
          }
          if (!id) {
            if (empty(item['iccid_prefix'])) {
              alert("Please choose ICCID prefix")
              isValid = false;
              return isValid;
            }
          }
        }
      }
    }
    return isValid;
  }

  const OnClickCancel = () => {
    if (endpoint === 'devices') {
      history.push("/main")
      return true
    }
    history.goBack()
    return true;
  }

  const processBeforeDeviceSaveApi = async () => {
    if (isAdmin) {
      //
    }
    else {
      if (id) {
        return false
      } else {
        setToast("Please wait, Process can take up to 20 minutes.")
        setSnackbarOpen(true)
        setSubmitting(true)
        window.scrollTo(0, 0)
      }
    }

  }
  const processAfterDeviceSaveApi = async (response, post_data) => {
    if (response.ok) {
      let responseObj = await response.json()
      console_log("device responseObj::::", responseObj)

      if (isAdmin) {
        if (responseObj['error']) {
          alert(responseObj['error'])
        } else {
          redirectToMainPage(responseObj)
        }
      }
      else {
        if (responseObj['error']) {
          setToast(responseObj['error'])
          setSnackbarOpen(true);
          setSubmitting(false)
          window.scrollTo(0, 0)
          delayHideToast()
        } else {
          if (responseObj['status'] === 'waiting') {
            await scanCheckGpsIccidMatched({ ...post_data })
            if (responseObj['message']) {
              setToast(responseObj['message'])
              setSnackbarOpen(true);
            }
          } else {
            redirectToMainPage(responseObj)
          }
        }
      }
    } else {
      console_log("device response not ok:::", response)
      const errorMsg = response?.message ?? (response?.error ?? "Internal server error")
      setToast(errorMsg)
      setSnackbarOpen(true);
      setSubmitting(false)
      window.scrollTo(0, 0)
      delayHideToast()
    }
  }

  const scanCheckGpsIccidMatched = async (data) => { // check every 30s for 10 minutes
    let i = 0;
    let cnt = 18; //18
    for (i = 0; i < cnt; i++) {
      let is_final = 0
      if (i === cnt - 1) {
        is_final = 1
      }
      const rslt = await checkGpsIccidMatched(data, is_final);
      if (rslt) { // if true, don't need to scan again
        return false
      }
      await sleep(30 * 1000)  //30 * 1000
    }
  }

  const checkGpsIccidMatched = async (data, is_final) => { // check every 30s for 10 minutes
    const response = await api_call('/api/devices/check-gpsid-iccid-matched', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data, is_final: is_final }),
    });
    if (response.ok) {
      const sessionData = await response.json();
      console_log("sessionData:::::::", sessionData);
      if (sessionData['error']) {
        if (is_final === 1) {
          const errorMsg = sessionData['error']
          setToast(errorMsg)
          setSnackbarOpen(true);
          setSubmitting(false)
          window.scrollTo(0, 0)
          delayHideToast()
          return true
        } else {
          return false // scan again
        }
      } else {
        redirectToMainPage(sessionData)
        return true
      }
    } else {
      if (is_final === 1) {
        const errorMsg = response?.message ?? (response?.error ?? "Internal server error")
        setToast(errorMsg)
        setSnackbarOpen(true);
        setSubmitting(false)
        window.scrollTo(0, 0)
        delayHideToast()
        return true
      } else {
        return false // scan again
      }
    }
  }

  const redirectToMainPage = (responseObj) => {
    if (endpoint === "devices") {
      if (empty(id)) { //temporary true for all
        const deviceInfo = responseObj
        if (deviceInfo) {
          if (deviceInfo['deviceType'] === DEVICE_TYPE.SMARTCAR) { // temporary true
            dispatch(layoutActions.setDeviceReloadTimestamp({ value: get_utc_timestamp_ms() }));
            history.push('/main', { showCreditLogdeviceInfo: deviceInfo })
            return true
          }
          else if (deviceInfo['deviceType'] === DEVICE_TYPE.TESLA) { // temporary true
            setSnackbarOpen(false)
            setSubmitting(false)
            dispatch(layoutActions.setDeviceReloadTimestamp({ value: get_utc_timestamp_ms() }));
            ////////////// history.push('/main')
            if (typeof callbackAfterSave === 'function') {
              callbackAfterSave(deviceInfo)
            }
            return true
          }
        }

      }
    }

    history.push("/main")
  }
  const delayHideToast = () => {
    setTimeout(() => {
      setSnackbarOpen(false)
    }, 3000)
  }

  const [submitting, setSubmitting] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toast, setToast] = useState("");
  const showToast = (msg) => {
    setToast(msg)
    setSnackbarOpen(true)
  }

  const handleDeleteAccount = async () => {
    if (item.devices.length) {
      alert("All cars must first be Deleted!")
    } else if (!item.escrow_balance * 1) {
      alert("Escrow must have no balance in order to delete!")
    } else {
      const userConfirmed = window.confirm('Are you sure you want to permanently delete this account?');
      if (userConfirmed) {
        api_call('/api/user-profile/delete', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userID: item.id }),
        }).then((response) => {
          if (response.msg) {
            alert(response.msg)
          } else {
            if (response.ok && response.status == 200) {
              window.localStorage.removeItem('session');
              dispatch(sessionActions.updateUser({ id: 0 }));
              history.push('/login');
            }
          }
        }).catch((error) => {
          console.log(error);
        })
      }
    }
  }

  return (
    <OptionsLayout>
      <div className='position-relative'>
        <div className='custom-snakebar'>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={10 * 60 * 1000}
            message={toast}
          >
          </Snackbar>
        </div>
      </div>

      <Container maxWidth="xs" className={classes.container}>
        {children}
        {
          (hideButtons) ? (
            <></>
          ) : (
            <>
              <FormControl fullWidth margin="normal">
                <div className={classes.buttons}>
                  <Button type="button" color="primary" variant="outlined" onClick={() => OnClickCancel()} disabled={submitting}>
                    {t('sharedCancel')}
                  </Button>
                  <Button type="button" color="primary" variant="contained" disabled={submitting} onClick={handleSave}>
                    {t('sharedSave')}
                  </Button>
                </div>
                {
                  (endpoint === "user-profile") && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                      <Button type="button" variant="outlined" style={{ width: '80%', color: '#CC2222', borderColor: '#CC2222' }} onClick={handleDeleteAccount} >
                        Delete Account
                      </Button>
                    </Box>
                  )
                }
              </FormControl>
            </>
          )
        }

        {
          (childrenAfter) && (
            <>{childrenAfter}</>
          )
        }

      </Container>
    </OptionsLayout>
  );
};

export default EditItemView;
