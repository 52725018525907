import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link, useHistory } from "react-router-dom"
import "../public/assets/css/main.css"
import FrontSiteNavbar from './FrontSiteNavbar';
import { empty, getToken, get_data_value } from './helpers/untils';
import { useState } from 'react';
import { Stack } from '@mui/material';
import { ButtonBase } from '@material-ui/core';
import ConfirmDialog from './components/ConfirmDialog';
import { APP_NAME } from './config/constant';

export default function PricingSiteV3() {
    const theme = useTheme();
    const history = useHistory()
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "./assets/js/animation.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    const [appSetting, setAppSetting] = useState({});

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const onClickOem = () => {
        setShowConfirmModal(true)
    }

    const onClickAgreeConfirm = () => {
        setShowConfirmModal(false)
    }

    return (
        <React.Fragment>

            <FrontSiteNavbar
                setAppSetting={setAppSetting}
            />

            <main id="content" role="main">
                <div className="gradient-x-overlay-sm-primary position-relative overflow-hidden">
                    <div className="container content-space-t-3 content-space-t-lg-4 content-space-b-1 content-space-b-md-2">
                        <div className="w-lg-65 text-center mx-lg-auto">
                            <div className="">
                                <h2 className="">
                                    Version 3 &nbsp; Pricing
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white overflow-hidden'>
                        <div className="container content-space-1 content-space-md-2" style={{ fontSize: "1rem", wordBreak: 'break-word' }}>
                            <div className='w-lg-65 text-left mx-lg-auto'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div class="table-responsive-lg">
                                            <table class="table table-lg table-striped table-borderless table-nowrap table-vertical-border-striped" style={{ border: '.0625rem solid rgba(33, 50, 91, .1)' }}>
                                                <thead class="table-text-center">
                                                    <tr>
                                                        <th scope="col" style={{ textAlign: 'left', width: '0%', padding: 0 }}></th>
                                                        <th scope="col" style={{ textAlign: 'left', width: '40%' }}>
                                                            <span class="d-block">Cars</span>
                                                        </th>

                                                        <th scope="col" style={{ textAlign: 'left', width: isTablet ? '60%' : '30%' }}>
                                                            <span class="d-block">Equipment</span>
                                                        </th>

                                                        {
                                                            (isTablet) ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    <th scope="col" style={{ textAlign: 'left', width: '30%' }}>
                                                                        <span class="d-block">Shipping</span>
                                                                    </th>
                                                                </>
                                                            )
                                                        }

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    <tr>
                                                        <td class="text-dark" style={{ padding: 0 }}></td>
                                                        <td class="text-dark">1 - 11</td>
                                                        <td class="text-dark">$175.00</td>

                                                        {
                                                            (isTablet) ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    <td class="text-dark">Buy on Amazon</td>
                                                                </>
                                                            )
                                                        }

                                                    </tr>
                                                    <tr>
                                                        <td class="text-dark" style={{ padding: 0 }}></td>
                                                        <td class="text-dark">12</td>
                                                        <td class="text-dark">$170.00</td>

                                                        {
                                                            (isTablet) ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    <td class="text-dark">Free</td>
                                                                </>
                                                            )
                                                        }

                                                    </tr>
                                                    <tr>
                                                        <td class="text-dark" style={{ padding: 0 }}></td>
                                                        <td class="text-dark">20</td>
                                                        <td class="text-dark">$165.00</td>

                                                        {
                                                            (isTablet) ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    <td class="text-dark">Free</td>
                                                                </>
                                                            )
                                                        }


                                                    </tr>
                                                    <tr>
                                                        <td class="text-dark" style={{ padding: 0 }}></td>
                                                        <td class="text-dark">50</td>
                                                        <td class="text-dark">$150.00</td>

                                                        {
                                                            (isTablet) ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    <td class="text-dark">Free</td>
                                                                </>
                                                            )
                                                        }

                                                    </tr>
                                                    <tr>
                                                        <td class="text-dark" style={{ padding: 0 }}></td>
                                                        <td class="text-dark">100+</td>
                                                        <td class="text-dark">$140.00</td>


                                                        {
                                                            (isTablet) ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    <td class="text-dark">Free</td>
                                                                </>
                                                            )
                                                        }

                                                    </tr>

                                                    <tr>
                                                        <td colSpan={isTablet ? 3 : 4} class="text-dark">
                                                            <Stack direction={`column`} justifyContent={`flex-start`} alignItems={`flex-start`} spacing={2} sx={{ width: '100%' }}>
                                                                <div style={{ display: 'block', width: '100%', whiteSpace: 'break-spaces' }}>
                                                                    MooveTrax Monthly $15 Per Car
                                                                </div>

                                                                <div style={{ display: 'block', width: '100%', whiteSpace: 'break-spaces' }}>
                                                                    <ButtonBase disableRipple onClick={() => onClickOem()} style={{ color: '#377dff' }}>
                                                                        OEM Connector Monthly $10 Per Car
                                                                    </ButtonBase>
                                                                </div>
                                                            </Stack>
                                                        </td>
                                                    </tr>

                                                    {/* <tr>
                                                        <td colSpan={isTablet ? 3 : 5} class="text-dark"><div style={{ display: 'block', width: '100%', whiteSpace: 'break-spaces', color: '#377dff' }}>* Quantities above 9 can incur credit card fees.</div></td>
                                                    </tr> */}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="shop-intro-box-1">
                                <div className="shop-intro-box-inner">
                                    <div className="row">
                                        <div className="col-lg-6 mb-3 mb-lg-0 text-center">
                                            <a className="shop-item" href={get_data_value(appSetting, 'SHOPPING_AMAZON_LINK')} aria-label="Amazon" target="_blank">
                                                <img className="navbar-brand-logo" src="/assets/img/shop/amazon-shop.webp" alt="Amazon" />
                                            </a>
                                        </div>
                                        <div className="col-lg-6 mb-3 mb-lg-0 text-center">
                                            <a className="shop-item" href={get_data_value(appSetting, 'SHOPPING_CART_LINK')} aria-label="Shopify" target="_blank">
                                                <img className="navbar-brand-logo" src="/assets/img/logo.png" alt="Shopify" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </main>
            <a className="js-go-to go-to position-fixed" href="javascript:;" style={{ visibility: 'hidden' }}
                data-hs-go-to-options='{
            "offsetTop": 700,
            "position": {
                "init": {
                "right": "2rem"
                },
                "show": {
                "bottom": "2rem"
                },
                "hide": {
                "bottom": "-2rem"
                }
            }
            }'>
                <i className="bi-chevron-up"></i>
            </a>

            <>
                <ConfirmDialog
                    open={showConfirmModal}
                    setOpen={setShowConfirmModal}
                    title={APP_NAME}
                    content={`OEM is for cars which have a factory app. MooveTrax is able to interface with those app and control lock and unlock.`}
                    textYes={`OK`}
                    onClickYes={() => onClickAgreeConfirm()}
                />
            </>

        </React.Fragment>
    )
}
