import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from '../store';

import {
  FormControl, InputLabel, Select, MenuItem, Button, TextField, Grid, Typography, Box, Modal, Snackbar, makeStyles,
  TableContainer, Table, TableRow, TableCell, TableHead, TableBody, IconButton, Link,
} from '@material-ui/core';

import { Autocomplete, createFilterOptions } from '@material-ui/lab';


import { useParams } from 'react-router-dom';
import Pagination from '../components/pagination'

import { useEffectAsync } from '../reactHelper';
import { useTranslation } from '../LocalizationProvider';

import SelectField from '../form/SelectField';

import moment from 'moment';
import { api_call, console_log, empty, getUnixTimestampFromTimezone, get_utc_timestamp, get_utc_timestamp_ms, trimPhone } from '../helpers/untils';
import PaypalPaymentModal from './PaypalPaymentModal';
import { BASE_SITE_URL, MIN_CREDIT_AMOUNT, PAYPAL_PLAN_NAME, PAYPAL_PLAN_PRICE, PAYPAL_PRODUCT_ID } from '../config/constant';

const TollSearchListTable = (props) => {
  const { selectedDevice, updateTimestamp, setUpdateTimestamp, items } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setCurrentTableData] = useState([]);
  const PageSize = 5;

  useEffect(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    setCurrentTableData(items.slice(firstPageIndex, lastPageIndex))
  }, [currentPage, items]);

  return (
    <>
      <Typography variant="h6" component="h2" style={{ marginBottom: 16 }}>Toll Query List</Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell>JPG</TableCell>
              <TableCell>PDF</TableCell>
              <TableCell>HTML</TableCell>
              <TableCell>Email to</TableCell>
              <TableCell>Post on Turo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              (currentTableData.length > 0) ? (
                currentTableData.map((item) =>
                  <TableRow key={item.id}>
                    <TableCell>{moment.unix(item.from).format("MM/DD/YYYY h:mm a")}</TableCell>
                    <TableCell>{moment.unix(item.to).format("MM/DD/YYYY h:mm a")}</TableCell>
                    <TableCell>{item.paymentMethod}</TableCell>
                    <TableCell>
                      {
                        (item.jpg_created) ? (
                          <Link
                            href={`${BASE_SITE_URL}/uploads/tolls/toll_report_${item.id}.jpg`}
                            target={`_blank`}
                          >
                            {`jpg`}
                          </Link>
                        ) : (
                          <div style={{ textAlign: 'center' }}>-</div>
                        )
                      }
                    </TableCell>
                    <TableCell>
                      <Link
                        href={`${BASE_SITE_URL}/uploads/tolls/toll_report_${item.id}.pdf`}
                        target={`_blank`}
                      >
                        {`pdf`}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        href={`/toll-list/${item.id}`}
                        target={`_blank`}
                      >
                        {`html`}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <TextField
                        placeholder="Enter emails with comma separated"
                      />
                    </TableCell>
                    <TableCell>
                      <Button>Send</Button>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow key={`no-data`}>
                  <TableCell colSpan={7} align="center">No item found</TableCell>
                </TableRow>
              )
            }

          </TableBody>
        </Table>
        <div style={{ marginTop: '5px' }}></div>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={PageSize}
          totalCount={items.length}
          onPageChange={page => setCurrentPage(page)}

        />
      </TableContainer>
    </>
  )
}

export default TollSearchListTable;
