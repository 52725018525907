import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { isMobile, osName } from 'react-device-detect';
import { positionsActions, devicesActions, sessionActions } from './store';
import { useEffectAsync } from './reactHelper';
import { useTranslation } from './LocalizationProvider';
import { prefixString } from './common/stringUtils';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { map } from "./map/Map";
import { api_call, console_log, empty, getShareUri, getToken } from './helpers/untils';
import { SOCKET_URL } from './config/constant';
import { layoutActions } from './store';
import { sleep } from './helpers/misc';

// const client = new W3CWebSocket(`wss://${process.env.REACT_APP_URL_NAME}/websocket`);
console.log("SOCKET_URL:::", SOCKET_URL)
const client = new W3CWebSocket(SOCKET_URL);

var authenticated;

const SocketController = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslation();

  const devices = useSelector((state) => state.devices.items);
  authenticated = useSelector((state) => !!state.session.user);

  const clearCache = useSelector((state) => state.layout.clearCache);
  const forceLogin = useSelector((state) => state.layout.forceLogin);

  const socketRef = useRef();

  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const user = useSelector((state) => state.session.user);

  const socketConnectedRef = useRef(false);

  const connectSocket = () => {
    console.log('trying connect WebSocket!!!');
    socketConnectedRef.current = true

    client.onopen = () => {
      console.log('WebSocket Client Connected!!!');
    }
    client.onmessage = (event) => {
      const data = JSON.parse(event.data);
      //console_log("onmessage data:::", data)

      if (data.type) {
        if (data.type === "GET_DEVICE_POSITION_DATA") {
          if (data.devices) {
            dispatch(devicesActions.update(data.devices));
          }
          if (data.positions) {
            //console.log("data.positions:::::", data)
            dispatch(positionsActions.update(data.positions));
          }
          if (data.events) {
            setEvents(data.events);
          }

        }
        else if (data.type === "CACHE_CLEAR") {
          if (data.clearCache) {
            // window.localStorage.removeItem('session');
            // dispatch(sessionActions.updateUser(null));
          }
          if (data.forceLogin) {
            window.localStorage.removeItem('m_token');
            dispatch(sessionActions.updateUser({ id: 0 }));
            //window.location.href = "/login"
          }
          window.location.reload(true)
        }
      }
      else {
        if (data.devices) {
          dispatch(devicesActions.update(data.devices));
        }
        if (data.positions) {
          //console.log("data.positions:::::", data)
          dispatch(positionsActions.update(data.positions));
        }
        if (data.events) {
          setEvents(data.events);
        }
      }
    }
    client.onclose = (evt) => {
      console.log("WebSocket Closed!!!", evt)
      if (evt.code == 3001) {
        console.log('ws closed');
      } else {
        console.log('ws connection error');
        setTimeout(() => connectSocket(), 10 * 1000);
      }
    }
    client.onerror = () => {
      console.log("WebSocket Error!!!")
      setTimeout(() => connectSocket(), 10 * 1000);
    }
  }

  useEffectAsync(async () => {
    if (clearCache) {
      console.log("cache clear!!!!!");
      client.send(JSON.stringify({ "type": "CACHE_CLEAR", clearCache: clearCache, forceLogin: forceLogin }));
    }
    // if(forceLogin) {
    //   window.location.href = "/login"
    // }else{
    //   window.location.reload()
    // }
    dispatch(layoutActions.revokeClearCache());

  }, [clearCache]);

  useEffectAsync(async () => {
    // const response = await api_call('/api/server');
    const serverInfo = {
      "id": 1,
      "attributes": {
      },
      "registration": true,
      "readonly": false,
      "deviceReadonly": false,
      "map": null,
      "bingKey": null,
      "mapUrl": null,
      "latitude": 28,
      "longitude": -81,
      "zoom": 14,
      "twelveHourFormat": false,
      "forceSettings": false,
      "coordinateFormat": null,
      "limitCommands": false,
      "poiLayer": null,
      "announcement": null,
      "version": "4.15",
      "emailEnabled": false
    }
    dispatch(sessionActions.updateServer(serverInfo));
  }, []);

  const loadDeviceList = async () => {
    if (user.id !== 0) {
      var url = `/api/devices?source=websocket`;
      const response = await api_call(url);
      if (response.ok) {
        const devices = await response.json();

        console.log("websocket devices::::", devices.length)

        if (!user.administrator) {
          let filtered_device_list = devices ? devices.filter((item) => (item.connected_device_id === 0)) : []
          dispatch(devicesActions.refresh(filtered_device_list));
          dispatch(devicesActions.refreshUserDeviceItems(filtered_device_list));
        } else {
          dispatch(devicesActions.refresh(devices));
          dispatch(devicesActions.refreshUserDeviceItems(devices));
        }
      }
    }
  }

  useEffect(() => {
    if (authenticated) {
      loadDeviceList()
      connectSocket();
      return () => {
        console.log("useEffectAsync close socket!!!!")
        const socket = socketRef.current;
        if (socket) {
          socket.close();
        }
      };
    }
    return null;
  }, [authenticated]);

  useEffect(() => {
    //console_log("events:::::", events);
    setNotifications(events.map((event, index) => {
      if (devices[event.deviceId]) {
        return {
          id: event.id,
          message: `${devices[event.deviceId]?.name}: ${event.type}`,
          show: true,
        }
      } else {
        return false;
      }

    }));
  }, [events, devices]);

  /////////////////////////////////////////////websocketclient get device data/////////////////////////////////////////////////
  const websocketClientIntervalMsDefault = 5 * 1000 // 5s
  const websocketClientIntervalMsAdmin = 5 * 60 * 1000 // 5 min

  const websocketClientIntervalMs = useRef(websocketClientIntervalMsDefault)

  ////////////////////////////////////////// tab active checker ///////////////////////////////////////////////////
  const onFocus = () => {
    console_log("Tab is in focus!!!!!!!!!!!");
    if (user?.administrator) {
      websocketClientIntervalMs.current = websocketClientIntervalMsAdmin
    } else {
      websocketClientIntervalMs.current = websocketClientIntervalMsDefault
    }
    requestGetDeviceData()
  }
  const onBlur = () => {
    console_log("Tab is in blur!!!!!!!!!!!");
    if (user?.administrator) {
      websocketClientIntervalMs.current = websocketClientIntervalMsAdmin
    } else {
      websocketClientIntervalMs.current = websocketClientIntervalMsDefault * 2
    }
  }

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener('blur', onBlur);
    }
  }, [user?.id]);

  useEffect(() => {
    if (user?.id) {
      let invervalMs = websocketClientIntervalMsDefault
      if (user.administrator) {
        invervalMs = websocketClientIntervalMsAdmin
      } else {
        invervalMs = websocketClientIntervalMsDefault
      }
      console_log(`websocketClientIntervalMs settingInvervalMs::::::`, invervalMs)
      websocketClientIntervalMs.current = invervalMs
    }
  }, [user?.id]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const getAuthTokenData = () => {
    const m_token = getToken()
    const shareUri = getShareUri()
    const tokenData = {
      m_token: m_token,
      shareUri: shareUri
    }
    return tokenData
  }

  const requestGetDeviceData = async () => {
    try {
      const tokenData = getAuthTokenData()
      if (empty(tokenData.m_token) && empty(tokenData.shareUri)) {
        console_log(`tokenData is empty::::`, tokenData)
        return false
      }

      const socketData = {
        "type": "GET_DEVICE_POSITION_DATA", tokenData: tokenData
      }
      //console_log(`socketData, client::::`, socketData, client)

      if (client.readyState === 3) {
        if (isMobile || (osName === 'iOS' || osName === 'Mac OS')) {
          //do nothing
        } else {
          //alert(`client.readyState,isMobile,osName:${client.readyState}, ${isMobile}, ${osName}`)
          window.location.reload(true)
          return false
        }
      }
      if (client.readyState === 1) {
        //console_log(`socketData send successfully:::::`, socketData)
        client.send(JSON.stringify(socketData));
      }
      return true
    } catch (e) {
      console.log(`requestGetDeviceData error:::`, e)
      return false
    }
  }

  const runWebSocketClientGetDeviceData = async () => {
    await requestGetDeviceData();
    console_log(`websocketClientIntervalMs.current::::`, websocketClientIntervalMs.current)
    await sleep(websocketClientIntervalMs.current)
    await runWebSocketClientGetDeviceData()
  }

  const startWebSocketClientGetDeviceData = async () => {
    while (!socketConnectedRef.current) {
      await sleep(100)
    }
    runWebSocketClientGetDeviceData()
  }

  useEffect(() => {
    startWebSocketClientGetDeviceData()
  }, [])
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {
        (user && user.id && !user.administrator) ? (
          <>
            {notifications.map((notification) => (
              <Snackbar
                key={notification.id}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={notification.show}
                message={notification.message}
                autoHideDuration={10000}
                onClose={() => setEvents(events.filter((e) => e.id !== notification.id))}
              />
            ))}
          </>
        ) : (
          <></>
        )
      }
    </>
  )
}

export default connect()(SocketController);

export const trueAuthenticated = () => { authenticated = true };