import React, { useState, useRef } from 'react';

function App() {
  const [imageDataURL, setImageDataURL] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const startCamera = async () => {
    const constraints = {
      video: { facingMode: 'user' } //user, environment
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (error) {
      console.error('Error accessing camera: ', error);
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageDataURL = canvas.toDataURL('image/png');
    setImageDataURL(imageDataURL);
    // Stop all video streams.
    video.srcObject.getTracks().forEach(track => track.stop());
  };

  const uploadImage = async () => {
    if (imageDataURL) {
      try {
        const response = await fetch('http://localhost:5000/upload', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ image: imageDataURL })
        });
        const result = await response.json();
        console.log('Image uploaded successfully:', result);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  return (
    <div className="App" style={{ textAlign: 'center' }}>
      <h1>Upload Photo React</h1>
      <input type="file" name="file_upload" capture="camera" />

      <button onClick={startCamera}>Take Photo</button>
      <video ref={videoRef} />
      <button onClick={takePhoto}>Capture</button>
      <canvas ref={canvasRef} style={{ display: 'none' }} />
      {imageDataURL && (
        <div>
          <div id="preview">
            <img src={imageDataURL} alt="Captured" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
          <button onClick={uploadImage}>Upload Image</button>
        </div>
      )}
    </div>
  );
}

export default App;
