import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom"
import "../public/assets/css/main.css"
import FrontSiteNavbar from './FrontSiteNavbar';
import { empty, getToken } from './helpers/untils';

export default function ApiDocSite() {

    const history = useHistory()

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "./assets/js/animation.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);


    return (
        <React.Fragment>

            <FrontSiteNavbar />

            <main id="content" role="main">
                <div className="gradient-x-overlay-sm-primary position-relative overflow-hidden">
                    <div className="container content-space-t-3 content-space-t-lg-4 content-space-b-1 content-space-b-md-2">
                        <div className="w-lg-65 text-center mx-lg-auto">
                            <div className="">
                                <h2 className="">
                                    API Documentation
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className='bg-white overflow-hidden'>
                        <div className="container content-space-1 content-space-md-2" style={{ fontSize: "1rem", wordBreak: 'break-word' }}>
                            <div className='w-lg-65 text-left mx-lg-auto'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className="mb-4">
                                            <p className=''>In this example the key is 1234567890</p>
                                        </div>

                                        <ul>
                                            <li>
                                                <div className="api-row">
                                                    <h5 className='api-item-title'>Return last known location,alive and location time. </h5>
                                                    <p className='api-item-desc'>https://moovetrax.com:8088/api/command/device/location?key=1234567890</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="api-row">
                                                    <h5 className='api-item-title'>Send unlock command.</h5>
                                                    <p className='api-item-desc'>https://moovetrax.com:8088/api/command/device/unlock?key=1234567890</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="api-row">
                                                    <h5 className='api-item-title'>Send lock command.</h5>
                                                    <p className='api-item-desc'>https://moovetrax.com:8088/api/command/device/lock?key=1234567890 </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="api-row">
                                                    <h5 className='api-item-title'>Send panic command to honk the horn (5 times).</h5>
                                                    <p className='api-item-desc'>https://moovetrax.com:8088/api/command/device/panic?key=1234567890</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="api-row">
                                                    <h5 className='api-item-title'>Send kill command.</h5>
                                                    <p className='api-item-desc'>https://moovetrax.com:8088/api/command/device/kill?key=1234567890</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="api-row">
                                                    <h5 className='api-item-title'>Send unkill command.</h5>
                                                    <p className='api-item-desc'>https://moovetrax.com:8088/api/command/device/unkill?key=1234567890</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="api-row">
                                                    <h5 className='api-item-title'>Set speed limit.</h5>
                                                    <p className='api-item-desc'>https://moovetrax.com:8088/api/command/device/speed?value?key=1234567890</p>
                                                </div>
                                            </li>
                                            {/* <li>
                                                <div className="api-row">
                                                    <h5 className='api-item-title'>Set geofencing distance from current location (in Miles).</h5>
                                                    <p className='api-item-desc'>https://moovetrax.com:8088/api/command/device/fence?value?key=1234567890</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="api-row">
                                                    <h5 className='api-item-title'>Reboot the device.</h5>
                                                    <p className='api-item-desc'>https://moovetrax.com:8088/api/command/device/reboot?key=1234567890</p>
                                                </div>
                                            </li> */}
                                            <li>
                                                <div className="api-row">
                                                    <h5 className='api-item-title'>Read miles.</h5>
                                                    <p className='api-item-desc'>https://moovetrax.com:8088/api/command/device/mileage?key=1234567890</p>
                                                </div>
                                            </li>

                                        </ul>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>



            </main>
            <a className="js-go-to go-to position-fixed" href="javascript:;" style={{ visibility: 'hidden' }}
                data-hs-go-to-options='{
            "offsetTop": 700,
            "position": {
                "init": {
                "right": "2rem"
                },
                "show": {
                "bottom": "2rem"
                },
                "hide": {
                "bottom": "-2rem"
                }
            }
            }'>
                <i className="bi-chevron-up"></i>
            </a>
        </React.Fragment>
    )
}
