import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';

import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import {
  Grid, useMediaQuery, makeStyles, InputLabel, Select, MenuItem, FormControl, Button, TextField,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sessionActions } from '../../store';
import { useLocalization, useTranslation } from '../../LocalizationProvider';
import StartPage from '../../StartPage';
import usePersistedState from '../../common/usePersistedState';
import { api_call, console_log } from '../../helpers/untils';
import { GPS_ID_PLACEHOLDER, ICCID_PLACEHOLDER, IMAGE_CACHE_VERSION, RECAPTCHA_ENABLED, RECAPTCHA_KEY } from '../../config/constant';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { sleep } from '../../helpers/misc';
import { Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  imgSmall: {
    width: '50px',
    height: '40px',
    marginLeft: '5px',
    marginTop: '5px',
    borderRadius: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
    objectPosition: 'center'
  },
  customSnakebar: {
    position: 'absolute',
    top: '140px'
  }
}));

const TestInstallerLoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const t = useTranslation();

  const { languages, language, setLanguage } = useLocalization();
  const languageList = Object.entries(languages).map((values) => ({ code: values[0], name: values[1].name }));

  const [failed, setFailed] = useState(false);

  const formDefaultData = {
    iccid: "", // "000000000"
    uniqueId: "", // "MT09G00000"
    phone_email: "",
  }
  const [formData, setFormData] = useState(formDefaultData);

  const [recaptcha, setRecaptcha] = useState(RECAPTCHA_ENABLED === "false" ? "xxxxxxxxxxxxxxxxx" : "");

  const [isOpen, setIsOpen] = useState('');
  const [currentImage, setCurrentImage] = useState('');

  const [submitting, setSubmitting] = useState(false);

  const iccidImage = `/images/iccid/large1.jpg?v=${IMAGE_CACHE_VERSION}`
  const gpsidImage = `/images/gpsid/large1.jpg?v=${IMAGE_CACHE_VERSION}`

  const handleSubmit = async (event) => {
    event.preventDefault();
    const rand = () => Math.floor((1 + Math.random()) * 0x100000000).toString(16).substring(1);
    const session = "installer-" + rand();
    var data = { ...formData, session: session };

    setToast("Please wait, Process can take up to 20 minutes.")
    setSnackbarOpen(true)
    setSubmitting(true)

    const response = await api_call('/api/installer-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const sessionData = await response.json();
      console_log("sessionData:::::::", sessionData);
      if (sessionData['error']) {
        setToast(sessionData['error'])
        setSnackbarOpen(true);
        setSubmitting(false)
      } else {
        if (sessionData['status'] === 'waiting') {
          await scanCheckGpsIccidMatched({ ...data })
          if (sessionData['message']) {
            setToast(sessionData['message'])
            setSnackbarOpen(true);
          }
        } else {
          redirectToMainPage(sessionData)
          return true
        }
      }
    } else {
      setFailed(true);
      //setFormData(formDefaultData);
      setToast("Invalid combination")
      setSnackbarOpen(true)
      setSubmitting(false)
    }

    setTimeout(() => {
      setSnackbarOpen(false)
    }, 3000)
  }


  const scanCheckGpsIccidMatched = async (data) => { // check every 30s for 10 minutes
    let i = 0;
    let cnt = 18; //18
    for (i = 0; i < cnt; i++) {
      let is_final = 0
      if (i === cnt - 1) {
        is_final = 1
      }
      const rslt = await checkGpsIccidMatched(data, is_final);
      if (rslt) { // if true, don't need to scan again
        return false
      }
      await sleep(30 * 1000)  //30 * 1000
    }
  }

  const checkGpsIccidMatched = async (data, is_final) => { // check every 30s for 10 minutes
    const response = await api_call('/api/check-gpsid-iccid-matched', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...data, is_final: is_final }),
    });
    if (response.ok) {
      const sessionData = await response.json();
      console_log("sessionData:::::::", sessionData);
      if (sessionData['error']) {
        if (is_final === 1) {
          setToast(sessionData['error'])
          setSnackbarOpen(true);
          setSubmitting(false)
          return true
        } else {
          return false // scan again
        }
      } else {
        redirectToMainPage(sessionData)
        return true
      }
    } else {
      if (is_final === 1) {
        const errorMsg = response?.message ?? (response?.error ?? "Internal server error")
        setToast(errorMsg)
        setSnackbarOpen(true)
        setSubmitting(false)
        return true
      } else {
        return false // scan again
      }
    }
  }

  const redirectToMainPage = (sessionData) => {
    console_log("sessionData::::", sessionData)
    //return false;
    window.localStorage.setItem('installer-session', sessionData['token']);
    window.localStorage.removeItem('session');
    window.location.href = '/installer-main';
  }

  const handleSpecialKey = (e) => {
    if (e.keyCode === 13 && formData['iccid'] && formData['uniqueId'] && recaptcha) {
      handleSubmit(e);
    }
  };
  const onChange = (value) => {
    console.log(value);
    setRecaptcha(value);
  }

  useEffect(() => {
    window.localStorage.removeItem('installer-session');
  }, []);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toast, setToast] = useState("");
  return (
    <>
      <StartPage>
        <div className='custom-snakebar'>
          <Snackbar
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            open={snackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            autoHideDuration={10 * 60 * 1000}
            message={toast}
          >
          </Snackbar>
        </div>


        <Grid container direction="column" spacing={2}>
          {useMediaQuery(theme.breakpoints.down('md'))
            && (
              <Grid item className={classes.logoContainer}>
                <Link to="/">
                  <img height="85" width="240" src="/logo.png" alt="Mobile Logo Login" />
                </Link>
              </Grid>
            )}

          <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
            <TextField
              required
              fullWidth
              error={failed}
              label={`GPS ID`}
              name="uniqueId"
              value={formData['uniqueId']}
              type="text"
              onChange={(e) => setFormData({ ...formData, uniqueId: e.target.value })}
              onKeyUp={handleSpecialKey}
              variant="filled"
              inputProps={{ style: { textTransform: "uppercase" }, maxLength: "10" }}
              placeholder={GPS_ID_PLACEHOLDER}
              disabled={submitting}
            />
            <img className={classes.imgSmall}
              src={`/images/gpsid/small1.jpg?v=${IMAGE_CACHE_VERSION}`}
              alt="How to get GPSID"
              title="How to get GPSID"
              onClick={() => {
                setCurrentImage(gpsidImage)
                setIsOpen(true)
              }}
            />
          </Grid>
          <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
            <TextField
              required
              fullWidth
              error={failed}
              label={`ICCID`}
              name="iccid"
              value={formData['iccid']}
              autoComplete="iccid"
              autoFocus={true}
              onChange={(e) => setFormData({ ...formData, iccid: e.target.value.substring(0, 9) })}
              onKeyUp={handleSpecialKey}
              //helperText={failed && 'The combination you entered is not found'}
              variant="filled"
              inputProps={{ style: { textTransform: "none" }, type: "number", min: 0 }}
              placeholder={ICCID_PLACEHOLDER}
              disabled={submitting}
            />
            <img className={classes.imgSmall}
              src={`/images/iccid/small1.jpg?v=${IMAGE_CACHE_VERSION}`}
              alt="How to get ICCID"
              title="How to get ICCID"
              onClick={() => {
                setCurrentImage(iccidImage)
                setIsOpen(true)
              }}
            />
          </Grid>
          <Grid item>

          </Grid>
          <Grid item>
            <TextField
              required
              fullWidth
              error={failed}
              label={`Installer Phone or Email`}
              name="phone_email"
              value={formData['phone_email']}
              type="text"
              onChange={(e) => setFormData({ ...formData, phone_email: e.target.value })}
              onKeyUp={handleSpecialKey}
              variant="filled"
              disabled={submitting}
            />
          </Grid>

          {
            (RECAPTCHA_ENABLED === "true") && (
              <Grid item>
                <ReCAPTCHA
                  // sitekey = "6LcLyiIfAAAAACo511xcuJZcxKwIg1kYKFhE3nue"
                  sitekey={RECAPTCHA_KEY}
                  // 6LeMLwcfAAAAAOPKgdVdoh8mcpM7wrUiAwDS5QA7
                  onChange={onChange}
                />
              </Grid>
            )
          }

          <Grid item>
            <Button
              onClick={handleSubmit}
              onKeyUp={handleSpecialKey}
              variant="contained"
              color="secondary"
              disabled={!formData['iccid'] || !formData['uniqueId'] || !formData['phone_email'] || !recaptcha || submitting}
              fullWidth
            >
              {`${(submitting) ? 'Processing' : 'Login'}`}
            </Button>
          </Grid>
        </Grid>

        {isOpen && (
          <Lightbox
            mainSrc={currentImage}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}

      </StartPage>
    </>

  );
};

export default TestInstallerLoginForm;
