import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Select, InputLabel, Checkbox, ListItemText, OutlinedInput, Box, Grid
} from '@material-ui/core';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditAttributesView from './attributes/EditAttributesView';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { useTranslation } from './LocalizationProvider';
import useGeofenceAttributes from './attributes/useGeofenceAttributes';
import { useSelector } from 'react-redux';
import { api_call } from './helpers/untils';
import { Stack } from '@mui/material';
import { useLocation } from "react-router-dom"

const GEOFENCE_DEFAULT_ENTER_SUBJECT = '{{Car_Name}} has entered {{Fence_Name}}'
const GEOFENCE_DEFAULT_LEAVE_SUBJECT = '{{Car_Name}} has left {{Fence_Name}}'

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const GeofencePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const geofenceAttributes = useGeofenceAttributes(t);
  const user = useSelector((state) => state.session.user);

  const [item, setItem] = useState({ deviceIds: [] });

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const q = params.get("q")

  const parseQueryParam = (q) => {
    try {
      if (item?.id) {
        return {}
      } else {
        const queryParamStr = decodeURIComponent(q)
        const queryParamObj = JSON.parse(queryParamStr)
        console.log(`queryParamObj:::::::`, queryParamObj)
        if (queryParamObj) {
          return queryParamObj
        } else {
          return {}
        }
      }
    } catch (e) {
      console.log(`parseQueryParam error::::`, e)
      return {}
    }
  }
  ////////////////////////////////////////////////////////////////////////

  const [enterEmailNotify, setEnterEmailNotify] = useState(false)
  const [enterPushNotify, setEnterPushNotify] = useState(false)
  const [enterLockChecked, setEnterLockChecked] = useState(false)
  const [leaveEmailNotify, setLeaveEmailNotify] = useState(false)
  const [leavePushNotify, setLeavePushNotify] = useState(false)
  const [leaveLockChecked, setLeaveLockChecked] = useState(false)

  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(0)
  const [selectedDevice, setSelectedDevice] = useState(null);

  const getDeviceIdFromName = (deviceName) => {
    //console.log("devices::::", devices)
    for (const key in devices) {
      if (devices.hasOwnProperty(key) && devices[key].name === deviceName) {
        return devices[key].id;
      }
    }
    return 0
  }

  const getDeviceNameFromId = (deviceId) => {
    //console.log("devices::::", devices)
    for (const key in devices) {
      if (devices.hasOwnProperty(key) && devices[key].id === deviceId) {
        return devices[key].name;
      }
    }
    return ""
  }

  const onChangeSelectedDevices = async (deviceIds) => {
    setItem({ ...item, deviceIds: deviceIds })
  }

  const getUserDeviceList = async () => {
    let url = '/api/device-name-list'
    const response = await api_call(url);
    if (response.ok) {
      const device_list = await response.json()
      setDevices(device_list)
    }
  }

  const [defaultValue, setDefaultValue] = useState(null)

  useEffect(() => {
    getUserDeviceList()
  }, [])

  const filter = createFilterOptions({
    stringify: (option) => option.name + "   " + option.uniqueId,
  });

  const getDefaultValue = () => {
    if (item && item.deviceId) {
      const device_list = [...devices]
      const defaultItem = device_list.find(element => element.id === item.deviceId);
      console.log("item:::", item)
      console.log("defaultItem:::", defaultItem)
      return defaultItem
    }
    return null
  }

  useEffect(() => {
    setDefaultValue(getDefaultValue())
    if (item?.post_turo) {
      const notifyVal = item.post_turo
      if (notifyVal >= 8) {
        setEnterEmailNotify(true)
      } else {
        setEnterEmailNotify(false)
      }
      if ((notifyVal >= 4 && notifyVal < 8) || (notifyVal >= 12 && notifyVal < 16)) {
        setEnterPushNotify(true)
      } else {
        setEnterPushNotify(false)
      }
      if (notifyVal % 4 == 2 || notifyVal % 4 == 3) {
        setLeaveEmailNotify(true)
      } else {
        setLeaveEmailNotify(false)
      }
      if (notifyVal % 2 == 1) {
        setLeavePushNotify(true)
      } else {
        setLeavePushNotify(false)
      }
    }

    if (item?.id) {
      setEnterLockChecked(item.enter_lock_checked)
      setLeaveLockChecked(item.leave_lock_checked)
    }
  }, [item, devices])

  const renderSelectedDeviceNames = (selectedIds) => {
    const deviceNameList = []
    if (selectedIds) {
      for (let k in selectedIds) {
        const deviceName = getDeviceNameFromId(selectedIds[k])
        if (deviceName) {
          deviceNameList.push(deviceName)
        }
      }
      return deviceNameList.join(', ')
    } else {
      return ""
    }
  }

  useEffect(() => {
    let enterE = enterEmailNotify ? "1" : "0"
    let enterP = enterPushNotify ? "1" : "0"
    let leaveE = leaveEmailNotify ? "1" : "0"
    let leaveP = leavePushNotify ? "1" : "0"
    const decimalVal = parseInt(enterE + enterP + leaveE + leaveP, 2)
    const newItemObj = { ...item, post_turo: decimalVal, enter_lock_checked: enterLockChecked, leave_lock_checked: leaveLockChecked }
    const queryItemObj = parseQueryParam(q)
    setItem({ ...queryItemObj, ...newItemObj, userId: user.id })
  }, [enterEmailNotify, enterPushNotify, leaveEmailNotify, leavePushNotify, enterLockChecked, leaveLockChecked, q])

  return (
    <EditItemView endpoint="geofences" item={item} setItem={setItem}>
      {item
        && (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">Geofence</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                fullWidth
                value={item.name || ''}
                onChange={(event) => setItem({ ...item, name: event.target.value })}
                label={t('sharedName')}
                variant="filled"
              />
              <FormControl margin="normal" variant="filled" fullWidth>
                <InputLabel>Device</InputLabel>
                <Select
                  fullWidth
                  multiple={true}
                  value={item.deviceIds || []}
                  onChange={(event) => onChangeSelectedDevices(event.target.value)}
                  renderValue={(selected) => renderSelectedDeviceNames(selected)}
                  disabled={devices.length === 0}
                >
                  {devices.map((deviceItem) => (
                    <MenuItem key={deviceItem.id} value={deviceItem.id}>
                      <Checkbox checked={item.deviceIds.indexOf(deviceItem.id) > -1} />
                      <ListItemText primary={deviceItem.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box sx={{
                display: 'flex',
                mt: 2
              }}>
                <Checkbox
                  checked={enterEmailNotify}
                  onChange={(event) => setEnterEmailNotify(event.target.checked)}
                />
                <TextField
                  fullWidth
                  value={item.notify_email_enter || ''}
                  onChange={(event) => setItem({ ...item, notify_email_enter: event.target.value })}
                  label={`Email address to notify if car enters fence:`}
                  variant="filled"
                  inputProps={{ type: 'email' }}
                  sx={{ mt: 4 }}
                />
              </Box>
              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={enterPushNotify}
                      onChange={(event) => setEnterPushNotify(event.target.checked)}
                    />
                    <Typography sx={{ display: 'flex' }}>Push</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={enterLockChecked}
                        onChange={(event) => setEnterLockChecked(event.target.checked)}
                      />
                      <Typography sx={{ display: 'flex' }}>Lock After</Typography>
                    </Box>
                    <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1}>
                      <TextField
                        value={item.enter_lock_miutes || ''}
                        onChange={(event) => setItem({ ...item, enter_lock_miutes: event.target.value })}
                        label={``}
                        variant="filled"
                        inputProps={{ type: 'number' }}
                        style={{ mt: 0, width: '50px' }}
                      />
                      <Typography sx={{ display: 'flex' }}>Minutes</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>

              <TextField
                fullWidth
                value={item.enter_subject || GEOFENCE_DEFAULT_ENTER_SUBJECT}
                onChange={(event) => setItem({ ...item, enter_subject: event.target.value })}
                label={`Subject`}
                variant="filled"
                inputProps={{ type: 'text' }}
                margin="normal"
              />

              <Box sx={{
                display: 'flex',
              }}>
                <Checkbox
                  checked={leaveEmailNotify}
                  onChange={(event) => setLeaveEmailNotify(event.target.checked)}
                />
                <TextField
                  fullWidth
                  value={item.notify_email_leave || ''}
                  onChange={(event) => setItem({ ...item, notify_email_leave: event.target.value })}
                  label={`Email address to notify if car leaves fence:`}
                  variant="filled"
                  inputProps={{ type: 'email' }}
                  margin="normal"
                />
              </Box>

              <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={leavePushNotify}
                      onChange={(event) => setLeavePushNotify(event.target.checked)}
                    />
                    <Typography sx={{ display: 'flex' }}>Push</Typography>
                  </Box>
                </Grid>
                <Grid item>
                  <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={leaveLockChecked}
                        onChange={(event) => setLeaveLockChecked(event.target.checked)}
                      />
                      <Typography sx={{ display: 'flex' }}>Lock After</Typography>
                    </Box>
                    <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1}>
                      <TextField
                        value={item.leave_lock_miutes || ''}
                        onChange={(event) => setItem({ ...item, leave_lock_miutes: event.target.value })}
                        label={``}
                        variant="filled"
                        inputProps={{ type: 'number' }}
                        style={{ mt: 0, width: '50px' }}
                      />
                      <Typography sx={{ display: 'flex' }}>Minutes</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>

              <TextField
                fullWidth
                value={item.leave_subject || GEOFENCE_DEFAULT_LEAVE_SUBJECT}
                onChange={(event) => setItem({ ...item, leave_subject: event.target.value })}
                label={`Subject`}
                variant="filled"
                inputProps={{ type: 'text' }}
                margin="normal"
              />
            </AccordionDetails>
          </Accordion>
        )}
    </EditItemView>
  );
};

export default GeofencePage;
