import { Snackbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from "react-router-dom"
import FrontSiteNavbar from './FrontSiteNavbar';
import { api_call, console_log, convertUnixTimestampToTimezone, empty, getLocalTimezone, get_data_value, isEmpty } from './helpers/untils';
import moment from 'moment';

export default function TollListSite() {
    const { searchId } = useParams();
    const history = useHistory()
    const [pageData, setPageData] = useState({})

    const loadPageInfo = async () => {
        if (searchId) {
            const response = await api_call(`/api/device-tollguru/get-search-info/${searchId}`);

            if (response.ok) {
                const data = await response.json();
                setPageData(data)
            }
        } else {
            alert("Invalid request!")
        }
    }

    useEffect(() => {
        loadPageInfo()
    }, [searchId]);

    const getNextTollCost = (item, currentPriceType) => {
        //return item[currentPriceType];
        let cost = 0;
        if (item.dataValues) {
            item = item.dataValues
        }
        // ['tagCost', 'cashCost', 'licensePlateCost', 'tagPriCost', 'tagSecCost', 'prepaidCardCost']
        const priceTypeList = ['tagCost', 'cashCost', 'licensePlateCost', 'tagPriCost', 'tagSecCost', 'prepaidCardCost']
        const priceIndex = priceTypeList.indexOf(currentPriceType);
        const priceTypeLength = priceTypeList.length

        for (let i = 1; i <= priceTypeLength; i++) {
            let index = priceIndex + i
            if (index >= priceTypeLength) {
                index = index % priceTypeLength
            }
            const priceType = priceTypeList[index]
            cost = item[priceType]
            if (empty(cost) || Number(cost) === 0) {
                cost = 0
            } else {
                cost = Number(cost)
                return cost
            }
        }
        return cost
    }

    const getTollCost = (item, paymentMethod) => {
        // 'Transponder', //tagCost
        // 'Cash', //cashCost
        // 'License', //licensePlateCost
        // 'Most Expensive',
        // 'Least Expensive'

        let cost = 0;
        if (item.dataValues) {
            item = item.dataValues
        }
        if (paymentMethod === 'Transponder') {
            cost = item['tagCost']
            if (empty(cost) || Number(cost) === 0) {
                cost = getNextTollCost(item, 'tagCost')
            }
        }
        else if (paymentMethod === 'Cash') {
            cost = item['cashCost']
            if (empty(cost) || Number(cost) === 0) {
                cost = getNextTollCost(item, 'cashCost')
            }
        }
        else if (paymentMethod === 'License') {
            cost = item['licensePlateCost']
            if (empty(cost) || Number(cost) === 0) {
                cost = getNextTollCost(item, 'licensePlateCost')
            }
        }
        else if (paymentMethod === 'Most Expensive') {
            cost = Math.max(item['tagCost'], item['tagPriCost'], item['tagSecCost'], item['cashCost'], item['licensePlateCost'], item['prepaidCardCost'])
        }
        else if (paymentMethod === 'Least Expensive') {
            cost = Math.min(item['tagCost'], item['tagPriCost'], item['tagSecCost'], item['cashCost'], item['licensePlateCost'], item['prepaidCardCost'])
        }

        if (cost) {
            // continue
        } else {
            cost = 0;
        }
        return cost

    }

    const getTotalTollCost = () => {
        let totalCost = 0
        let currency = ""
        const tollList = pageData['tollSearchInfo']['tollList']
        for (let k in tollList) {
            const item = tollList[k]
            const cost = getTollCost(item, pageData['tollSearchInfo']['paymentMethod'])
            totalCost += cost
            currency = item['currency']
        }
        return `${totalCost.toFixed(2)} ${currency}`
    }

    const getTransactionDate = (item) => {
        let dateTime = convertUnixTimestampToTimezone(item['transaction_date_timestamp'], "MM/DD/YYYY h:mm a", pageData['tollSearchInfo']['timezone']) //let dateTime = moment.unix(item['transaction_date_timestamp']).format("MM/DD/YYYY h:mm a")
        return dateTime
    }
    return (
        <React.Fragment>
            {
                (pageData['device']) ? (
                    <main id="content" role="main" style={{ backgroundColor: 'rgb(82, 86, 89)', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className="container pdf-container">
                            <div className="pdf-wrapper" style={{ minHeight: '100vh', width: '840px', margin: 'auto', padding: '40px 40px 40px 40px', background: '#ffffff' }}>
                                <div className='pdf-header-wrapper' style={{ display: 'flex', width: '100%', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                                    <div className='pdf-logo-wrapper'>
                                        <img src="/assets/img/logo.png" style={{ width: '200px', height: 'auto' }} />
                                    </div>
                                    <div className='pdf-subject-wrapper' style={{ flex: 1 }}>
                                        <div className='pdf-device-info-wrapper' style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-around' }}>
                                            <div className='pdf-device-info-list'>
                                                <h4>MooveTrax Toll Report</h4>
                                                <div>
                                                    <div className='pdf-device-info'>
                                                        <b>VIN: </b> <span>{pageData['device']['vin']}</span>
                                                    </div>
                                                    <div className='pdf-device-info'>
                                                        <b>PLATE: </b> <span>{pageData['device']['license_tag']}</span>
                                                    </div>
                                                    <div className='pdf-device-info'>
                                                        <b>MAKE: </b> <span>{pageData['device']['make']}</span>
                                                    </div>
                                                    <div className='pdf-device-info'>
                                                        <b>MODEL: </b> <span>{pageData['device']['model']}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '100%', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '20px' }}>
                                    <div className='start-date'>
                                        <b>START: </b> <span>{convertUnixTimestampToTimezone(pageData['tollSearchInfo']['from'], "MM/DD/YYYY h:mm a", pageData['tollSearchInfo']['timezone'])}</span>
                                    </div>
                                    <div className='end-date' style={{ marginLeft: '30px' }}>
                                        <b>END: </b> <span>{convertUnixTimestampToTimezone(pageData['tollSearchInfo']['to'], "MM/DD/YYYY h:mm a", pageData['tollSearchInfo']['timezone'])}</span>
                                    </div>
                                </div>
                                <div className='pdf-body' style={{ marginTop: '20px', paddingTop: '20px', borderTop: '2px solid #333333' }}>
                                    <div className='pdf-item-table'>
                                        <table className='strip-table' style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ fontWeight: 600 }}>TRANSACTION DATE</th>
                                                    <th style={{ fontWeight: 600 }}>LOCATION</th>
                                                    <th style={{ fontWeight: 600 }}>ROAD</th>
                                                    <th style={{ fontWeight: 600 }}>STATE</th>
                                                    <th style={{ fontWeight: 600, textAlign: 'right' }}>COST</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (pageData['tollSearchInfo']['tollList'].map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{getTransactionDate(item)}</td>
                                                                <td>{item['name']}</td>
                                                                <td>{item['road']}</td>
                                                                <td>{item['state']}</td>
                                                                <td style={{ textAlign: 'right' }}>{`${getTollCost(item, pageData['tollSearchInfo']['paymentMethod']).toFixed(2)} ${item['currency']}`}</td>
                                                            </tr>
                                                        )
                                                    }))

                                                }
                                                <tr>
                                                    <td colSpan={5} style={{ paddingTop: '10px', paddingBottom: '10px', fontWeight: 600, textAlign: 'right' }}>TOTAL: &nbsp; {getTotalTollCost()}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </main>
                ) : (
                    <></>
                )
            }

        </React.Fragment>
    )
}
