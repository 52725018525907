import React, { useEffect } from 'react';
import { Link, useHistory } from "react-router-dom"
import CmsPageLayout from './layout/CmsPageLayout';

export default function PrivacyPolicySite() {

    return (
        <CmsPageLayout title="MooveTrax Privacy Policy" lastUpdated={true}>
            <React.Fragment>
                <p className="page-subtitle no-margin"><b>Article 1: Introduction</b></p>

                <p>
                    At moovetrax.com (“us”, “we”, “our” or “MooveTrax”) we value your privacy and the importance of safeguarding your data. This Privacy Policy ("Privacy Policy") describes our privacy practices for the activities set out below. As per your rights, we inform you how we collect, store, access, and otherwise process information relating to individuals. In this Policy, "Personal Data" refers to any information that on its own, or in combination with other available information, can distinguish an individual.
                </p>

                <p className="page-subtitle"><b>Article 2: Scope</b></p>
                <p>
                    This Policy does not apply to third-party applications, websites, products, services or platforms that may be accessed through links that we may provide to you.
                </p>
                <p>
                    These sites are owned and operated independently from us, and they have their own separate privacy and data collection practices.
                    Any personal data that you provide to these websites will be governed by the third party's own privacy policy. We cannot accept liability for the actions or policies of these independent sites, and we are not responsible for the content or privacy practices of such sites.
                </p>
                <p className="page-subtitle"><b>Article 3:  Processing Activities</b></p>
                <p>
                    This Privacy Policy applies when you interact with us by doing any of the following :
                </p>

                <ul>
                    <li>Make use of our products and services as an authorized user</li>
                    <li>Visit any of our websites that link to this Privacy Statement</li>
                    <li>Receive any communication from us including newsletters, emails, calls, or texts.</li>
                </ul>

                <p className="page-subtitle"><b>Article 4:  Personal Data We Collect</b></p>

                <p>
                    4.1 What is Personal Data?
                </p>
                <p>
                    When we use the term “personal data” in this Privacy Notice, we mean information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to an individual. It does not include aggregated or de-identified information that is maintained in a form that is not capable of being associated with or reasonably linked to an individual.
                </p>
                <p>
                    4,2 Information We Collect As You Use Our Services
                </p>
                <p>
                    As is true of most digital platforms, we and our third-party providers collect certain personal data automatically when you visit or interact with our websites, mobile apps, and other online services.
                </p>
                <p>
                    Specifically, we and our third party partners may use tracking technologies to automatically collect commercial information, preferences, and internet, network and device information, including:
                </p>

                <ul>
                    <li>
                        Information about how you access our services, such as the website from which you came and the website to which you are going when you leave our websites, how frequently you access the services, when and whether you open emails or click the links contained in emails, whether you access the services from multiple devices and other actions you take on the services.
                    </li>
                    <li>
                        Information about how you use the services, such as the pages you visit, the links you click, the ads you view and click on, subscription information, your location when you access or interact with our services, and other similar actions.
                    </li>
                    <li>
                        Information about the computer, tablet, smartphone or other device you use, such as your IP address, browser type, Internet service provider, platform type, device type/model/manufacturer, operating system, date and time stamp, a unique ID that allows us to uniquely identify your browser, mobile device, or your account, and other such information (referred to herein as “Log Data”).
                    </li>
                    <li>
                        Analytics Data, such as information about your activity when you visit our sites or use our apps; this can include clicks, mouse movements, forms you fill out, and similar information. We use this to better understand what features and parts of the website are most useful, and which ones may need to be improved.
                    </li>
                </ul>

                <p>
                    4.3 Data You Provide
                </p>
                <p>
                    When you make a subscription, we collect personal data as part of your order information.
                </p>
                <p>
                    This data includes :
                </p>
                <ul>
                    <li>
                        Business and personal contact information, such as your first and last name, email and mailing addresses, and phone number.
                    </li>
                    <li>
                        Any information you voluntarily share with MooveTrax, such as by text message, email, through the mobile application, or through the website.
                    </li>
                    <li>
                        Feedback or correspondence, such as information you provide when you contact us with questions, feedback, or otherwise correspond with us online, or in any other capacity.
                    </li>
                    <li>
                        Billing information, such as your financial account numbers, or bank account or payment card information.
                    </li>
                    <li>
                        Transaction information, such as information about payments to and from you and other details related to products or services you have received from us.
                    </li>
                    <li>
                        Precise geolocation information, such as when you authorize our website to access your location for GPS tracking purposes.
                    </li>
                    <li>
                        Usage information, such as information about how you use the Platform and interact with us, including information associated with any content you upload to the Platform, or otherwise submit to us, and information you provide when you use any interactive features of the Platform.
                    </li>
                    <li>
                        Marketing information, such as your preferences for receiving communications about our Platform and future MooveTrax product offerings, and details about how you engage with our communications.
                    </li>
                    <li>
                        Other information that we may collect which is not specifically listed here, but which we will use in accordance with this Privacy Policy or as otherwise disclosed at the time of collection.
                    </li>
                </ul>

                <p>
                    If you provide us, or our service providers, with any Personal Data relating to other individuals, you represent that you have the authority to do so and acknowledge that it will be used in accordance with this Privacy Statement.
                    If you believe that your Personal Data has been provided to us improperly, or to otherwise exercise your rights relating to your Personal Data, please contact us by using the information set out in the Contact Us section below.
                </p>

                <p>
                    4.4  Device and Usage Data
                </p>
                <p>
                    When you visit our website, we automatically collect and store information about your visit using browser cookies (files which are sent by us to your computer), or similar technology.
                </p>
                <p>
                    You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. The Help Feature on most browsers will provide information on how to accept cookies, disable cookies or to notify you when receiving a new cookie. If you do not accept cookies, you may not be able to use some features of our Service and we recommend that you leave them turned on.
                </p>
                <p>
                    We also process information when you use our services and products. This information may include:
                </p>

                <ul>
                    <li>IP Address</li>
                    <li>Products and Services you view</li>
                    <li>Web terms or searches that led you to the site</li>
                    <li>Time zone</li>
                </ul>

                <p>
                    4.5 Data we collect from third parties
                </p>

                <p>
                    We may receive your personal data from third parties such as companies subscribing to our services, partners and other sources. This information is not collected by us but by a third party and is subject to the relevant third party's own separate privacy and data collection policies. We do not have any control or input on how your personal data is handled by third parties. As always, you have the right to review and rectify this information.
                </p>

                <p>
                    Our website and services may contain links to other websites, applications and services maintained by third parties. The information practices of such other services, or of social media networks that host our branded social media pages, are governed by third parties' privacy statements, which you should review to better understand those third parties' privacy practices.
                </p>

                <p>
                    4.6 Purpose and Legal Basis for the Processing of Personal Data
                </p>

                <p>
                    We collect and use personal data about you with your consent to provide, maintain, and develop our products and services and understand how to improve them.
                </p>
                <p>
                    These purposes include :
                </p>
                <ul>
                    <li>
                        Building a Safe and Secure Environment
                    </li>
                    <li>
                        To Verify or authenticate your identity; and
                    </li>
                    <li>
                        Investigate and prevent security incidents such as breaches, attacks and hacks.
                    </li>
                    <li>
                        Providing, Developing, and Improving our Products and Services
                    </li>
                    <li>
                        Deliver, maintain, debug and improve our products and services.
                    </li>
                    <li>
                        Enable you to access www.moovetrax.com services and products.
                    </li>
                    <li>
                        Provide you with technical and customer support.
                    </li>
                    <li>
                        Organize and Deliver Advertising and Marketing
                    </li>
                    <li>
                        Send you newsletters and other marketing communications about current and future products, programs and services, events, surveys and promotions held by us or hosted on our behalf.
                    </li>
                </ul>

                <p>
                    Where we process your personal data to provide a product or service, we do so because it is necessary to perform contractual obligations. All of the above processing is necessary in our legitimate interests to provide products and services and to maintain our relationship with you and to protect our business for example against fraud. Consent will be required to initiate services with you. New consent will be required If any changes are made to the type of data collected. Within our contract, if you fail to provide consent, some services may not be available to you.
                </p>

                <p>
                    4.7 Sharing and Disclosure
                </p>
                <p>
                    We will share your personal data with third parties only in the ways set out in this Policy or set out at the point when the personal data is collected.
                </p>

                <p>
                    4.8 Legal Requirement
                </p>
                <p>
                    We may use or disclose your personal data in order to comply with a legal obligation, in connection with a request from a public or government authority, or in connection with court or tribunal proceedings, to prevent loss of life or injury, or to protect our rights or property.
                </p>

                <p>
                    Where possible and practical to do so, we will tell you in advance of such disclosure.
                </p>

                <p>
                    4.9 Service Providers and Other Third Parties
                </p>
                <p>
                    We may use a third party service provider, Independent contractors, agencies, or consultants to deliver and help us improve our products and services (such as customer support, payment processing, hosting, analytics, email delivery, marketing, identification checks, and data and/or database management services).
                </p>

                <p className="page-subtitle"><b>Article 5: Cookie policy</b></p>

                <p> 5.1 What are cookies?</p>
                <p>
                    A “cookie” is a text file that websites send to a visitor’s computer or other Internet-connected device to uniquely identify the visitor’s browser or to store information or settings in the browser. Browser web storage, or LSOs, are used for similar purposes as cookies. Browser web storage enables the storage of a larger amount of data than cookies. A “web beacon,” also known as a pixel tag or clear GIF, is typically used to demonstrate that a webpage was accessed or that certain content was viewed, typically to measure the success of our marketing campaigns or engagement with our emails and to compile statistics about usage of our websites. The collection of this information may be used in the function of the site and/or to improve your experience.
                </p>

                <p>5.2 How we use cookies</p>
                <p>
                    We do this to ensure flow and smoothness of our customer experience. To use cookies we ask for your permission beforehand, by accessing our websites and returning at least once more. You give permission for us to use cookies.
                </p>

                <p className="page-subtitle"><b>Article 6: Retention & Deletion</b></p>

                <p>
                    We will only retain your personal data for as long as necessary for the purpose for which that data was collected and to the extent required by applicable law. When we no longer need personal data, we will remove it from our systems and/or take steps to anonymize it.
                </p>

                <p className="page-subtitle"><b>Article 7: Children's Privacy</b></p>

                <p>
                    As a general rule, children are not allowed to use the Platform, and we do not collect personal information from them. We define "children" as anyone under 18 years old. If we learn that we have collected personal information of a child without the consent of the child’s parent or guardian, we will delete it. We encourage parents with concerns to contact us.
                </p>

                <p className="page-subtitle"><b>Article 8: Your Rights to Your Personal Data</b></p>
                <p>
                    8.1 Depending on your geographical location and citizenship, your rights are subject to local data privacy regulations.
                </p>
                <p>
                    These rights may include :
                </p>
                <ul>
                    <li>
                        Right to Access. You have the right to request a copy of the personal data we are processing about you.
                    </li>
                    <li>
                        Right to Rectification. You have the right to have incomplete or inaccurate personal data that we process about you rectified.
                    </li>
                    <li>
                        Right to be Forgotten (right to erasure). You have the right to request that we delete personal data that we process about you, except we are not obliged to do so if we need to retain such data in order to comply with a legal obligation or to establish, exercise or defend legal claims.
                    </li>
                    <li>
                        Right to Restriction of Processing. You have the right to restrict our processing of your personal data where you believe such data to be inaccurate; our processing is unlawful; or if we no longer need to process such data for a particular purpose unless we are not able to delete the data due to a legal or other obligation or because you do not wish us to delete it.
                    </li>
                    <li>
                        Right to Portability. You have the right to obtain personal data we hold about you, in a structured, electronic format, and to transmit such data to another data controller, where this is (a) personal data which you have provided to us, and (b) if we are processing that data on the basis of your consent or to perform a contract with you or the third party that subscribes to our services.
                    </li>
                    <li>
                        Right to Objection. Where the legal justification for our processing of your personal data is our legitimate interest, you have the right to object to such processing on grounds relating to your particular situation. We will abide by your request unless we have compelling legitimate grounds for processing which override your interests and rights, or if we need to continue to process the data for the establishment, exercise or defense of a legal claim.
                    </li>
                    <li>
                        Right Not to Be Discriminated. You have the right not to be denied service or have an altered experience based on having executed any of your rights.
                    </li>
                </ul>

                <p>8.2 Withdrawing Consent</p>
                <p>
                    If you have consented to our processing of your personal data, you have the right to withdraw your consent at any time, free of charge, such as where you wish to unsubscribe from marketing messages that you receive from us. If you wish to withdraw your consent, please contact us using the information found at the bottom of this page.
                </p>
                <p>8.3 How to Exercise Your Rights</p>

                <p>
                    You can make a request to exercise any of these rights in relation to your personal data by sending the request to our privacy team by using the contact details below.
                </p>
                <p>
                    For your own privacy and security, at our discretion, we may require you to prove your identity before providing the requested information.
                </p>

                <p className="page-subtitle"><b>Article 9: Changes</b></p>

                <p>
                    We may modify this Policy at any time. If we make changes to this Policy then we will post an updated version of this Policy on our Platform. We may, and if required by law will, also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via email (if you have an account where we have your contact information) or another manner through the Platform.
                    Any modifications to this Privacy Policy will be effective upon our posting the new terms and/or upon implementation of the new changes on the Platform (or as otherwise indicated at the time of posting). In all cases, your continued use of the Platform after the posting of a modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.
                </p>

                <p className="page-subtitle"><b> Article 10: Security Practices</b></p>

                <p>
                    The security of your personal information is important to us. We employ a number of organizational, technical and physical safeguards designed to protect the personal information we collect. However, security risk is inherent in all internet and information technologies and we cannot guarantee the security of your personal information.
                </p>

                <p className="page-subtitle"><b>Article 11: Complaints</b></p>

                <p>
                    If you have a complaint about this policy or any element of your personal information that we hold then please contact us below. If you are not satisfied, then you have the right to lodge a complaint with the local data protection authority.
                </p>

                <p className='mt-5'><b>Contact Us</b></p>
                <p>
                    To request a copy for your information, unsubscribe from our email list, request for your data to be deleted, or ask a question about your data privacy, please contact us.
                </p>

            </React.Fragment>
        </CmsPageLayout>
    )
}
