import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  FormControl, InputLabel, Button, TextField, Grid, Link, Box, Switch, makeStyles
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { getDefaultSharedTextForClipboard, getSettingPersist, getTuroSummaryFirstLastWord, setSettingPersist } from '../../helpers/misc';
import { api_call, console_log, convertUtcToTimezone, empty, generateSharedFullUrl, generateSharedUrlId, getLocalTimezone, getUtcFromTimezone, get_data_value, get_utc_timestamp_ms, is_null } from '../../helpers/untils';
import { MACHINE_ENVIRONMENT, SHARED_LINK_TEXT_KEY } from '../../config/constant';
import copy from 'copy-to-clipboard';

const useStyles = makeStyles((theme) => ({
  formRowInlineController: {
    flex: 1
  },
  flexibleLineBox: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    [theme.breakpoints.down('md')]: {
      direction: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  }
}));

const BatchGenerateLinkRow = (props) => {
  const {
    trip,
    devices = [],
    batchGeneratedLinkList = [],
    userDeviceList = [],
    reloadTimestamp,
    setReloadTimestamp,
    updateTimestamp,
    setUpdateTimestamp,
    showToast,
  } = props

  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.session.user);

  const [apiLoading, setApiLoading] = useState(false)
  const [from, setFrom] = useState(moment());
  const [to, setTo] = useState(moment().add(1, 'day'));
  const [dob, setDob] = useState(moment());
  const localTimezone = getLocalTimezone()

  const [linkIsCreated, setLinkIsCreated] = useState(false)

  const defaultFormData = {
    auto_update: true
  }
  const [formData, setFormData] = useState(defaultFormData)
  const [shareUrl, setShareUrl] = useState("")
  const [generatedUrl, setGeneratedUrl] = useState("")
  const [oldBatchLink, setOldBatchLink] = useState()

  const initOldBatchLink = () => {
    const turo_trip_id = trip?.id
    const row = batchGeneratedLinkList.find((item) => item.turo_trip_id === turo_trip_id)
    console_log(`initOldBatchLink trip:::`, trip)
    console_log(`initOldBatchLink row:::`, row)
    if (row && row.id) {
      setOldBatchLink(row)
      setFormData(row)
      const deviceId = row.deviceId
      const iana_timezone = getIanaTimezone(deviceId)
      const linkDevice = devices.find((item) => item.id === deviceId)
      //console_log(`initOldBatchLink linkDevice:::`, linkDevice)
      let startUtc = moment.utc(row.from)
      let startUtcIsoString = startUtc.toISOString()
      const _from = convertUtcToTimezone(startUtcIsoString, iana_timezone)
      setFrom(_from)

      let endUtc = moment.utc(row.to)
      let endUtcIsoString = endUtc.toISOString()
      const _to = convertUtcToTimezone(endUtcIsoString, iana_timezone)
      setTo(_to)

      setDob(moment(row.dob))
      setDevice(linkDevice)
      setLinkIsCreated(true)
    } else {
      setOldBatchLink(null)

      const deviceId = getTuroTripDevice()
      const iana_timezone = getIanaTimezone(deviceId)

      let startUtc = getUtcFromTimezone(trip.start, trip.tzid)
      let startUtcIsoString = startUtc.toISOString()
      console_log(`startUtcIsoString::::`, startUtcIsoString)
      const _from = convertUtcToTimezone(startUtcIsoString, iana_timezone)
      setFrom(_from)

      let endUtc = getUtcFromTimezone(trip.end, trip.tzid)
      let endUtcIsoString = endUtc.toISOString()
      console_log(`endUtcIsoString::::`, endUtcIsoString)
      const _to = convertUtcToTimezone(endUtcIsoString, iana_timezone)
      setTo(_to)

      const linkDevice = devices.find((item) => item.id === deviceId)
      setDevice(linkDevice)
      setLinkIsCreated(false)
    }
  }

  useEffect(() => {
    initOldBatchLink()
  }, [devices.length, trip, batchGeneratedLinkList, userDeviceList.length])

  ///////////////////////////////////////////////////////
  const onChangeFrom = (e) => {
    const _from = moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL)
    console_log(`_from:::`, _from)
    setFrom(_from)
    onChangeFromTo(_from, null)

    if (oldBatchLink) {
      const payload = {
        linkId: oldBatchLink?.id
      }
      const deviceId = oldBatchLink.deviceId
      const iana_timezone = getIanaTimezone(deviceId)
      const fromUtc = getUtcFromTimezone(_from.format('YYYY-MM-DD HH:mm:ss'), iana_timezone)
      payload['from'] = fromUtc.toISOString()
      payload['from_local'] = _from.format('YYYY-MM-DD HH:mm:ss')
      updateBatchLink({ ...payload })
    }
  }
  const onChnageTo = (e) => {
    const _to = moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL)
    setTo(_to)
    onChangeFromTo(null, _to)

    if (oldBatchLink) {
      const payload = {
        linkId: oldBatchLink?.id
      }
      const deviceId = oldBatchLink.deviceId
      const iana_timezone = getIanaTimezone(deviceId)
      const toUtc = getUtcFromTimezone(_to.format('YYYY-MM-DD HH:mm:ss'), iana_timezone)
      payload['to'] = toUtc.toISOString()
      payload['to_local'] = _to.format('YYYY-MM-DD HH:mm:ss')
      updateBatchLink({ ...payload })
    }
  }
  const onChangeFromTo = (_from, _to, _form_data = null) => {
    if (is_null(_from)) {
      _from = from
    }
    if (is_null(_to)) {
      _to = to
    }
    if (is_null(_form_data)) {
      _form_data = formData
    }
    setFormData({ ..._form_data })
  }

  const onChnageDob = (e) => {
    console_log(`onChnageDob e.target.value:::`, e.target.value)
    const _dob = moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL)
    console_log(`onChnageDob _dob:::`, _dob)
    setDob(_dob)

    if (oldBatchLink) {
      updateBatchLink({ linkId: oldBatchLink?.id, dob: _dob.format("YYYY-MM-DD") })
    }
  }

  //////////////////////////// device autocomplete //////////////////////////////
  const defaultDeviceOption = {}
  const [device, setDevice] = useState(null);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const initDeviceOptions = () => {
    let v_options = []
    devices.map((definition) => {
      v_options.push({
        ...definition
      })
    });
    //console.log("v_options:::", v_options)
    setDeviceOptions(v_options)
    setDevice(defaultDeviceOption)
  }
  useEffect(() => {
    initDeviceOptions()
  }, [devices.length])
  const deviceFilter = createFilterOptions({
    stringify: (option) => option.key + "   " + option.name,
  });

  const getDeviceLabel = (deviceInfo, returnType = "text") => {
    //console_log(`getDeviceLabel deviceInfo::::`, deviceInfo)
    if (returnType === "object") {
      return (
        <div>
          <div>{`${deviceInfo?.name ?? ""}`}</div>
        </div>
      )
    } else {
      return deviceInfo?.name ?? ""
    }
  }

  const onChangeDevice = (option) => {
    console_log("onChangeDevice::::", option)
    setDevice(option);
  }

  const getTripSummaryText = (item) => {
    let summary = item?.summary ?? ""
    summary = summary.replace('reserved', '-')
    return summary
  }

  const getTextForClipboard = (shared_url, sharedTextTemplate) => {
    console_log(`sharedTextTemplate:::`, sharedTextTemplate)
    if (shared_url) {
      const clipboardText = sharedTextTemplate.replace(SHARED_LINK_TEXT_KEY, shared_url)
      return clipboardText
    } else {
      return ""
    }
  }

  const copyTextClipboard = (shared_url, sharedTextTemplate) => {
    console_log(`shared_url, sharedTextTemplate:::`, shared_url, sharedTextTemplate)
    let clipboardText = getTextForClipboard(shared_url, sharedTextTemplate)
    if (clipboardText) {
      //navigator.clipboard.writeText(clipboardText);
      copy(clipboardText);
      return true
    } else {
      return false
    }
  }

  const onChangeRenterName = (e) => {
    const val = e.target.value
    setFormData({ ...formData, renter_name: val })
    if (oldBatchLink) {
      updateBatchLink({ linkId: oldBatchLink?.id, renter_name: val })
    }
  }

  const openTripReservationPage = () => {
    if (MACHINE_ENVIRONMENT === 'dev') {
      return false
    }

    if (trip && trip.url) {
      const url = trip.url
      setTimeout(function () {
        var newWindow = window.open(url, '_blank');
      }, 1200)
    }
  }

  const getTuroTripDevice = () => {
    let turoTripDeviceData = {}
    const turoTripDeviceStr = getSettingPersist('turoTripDevices')
    if (turoTripDeviceStr) {
      turoTripDeviceData = JSON.parse(turoTripDeviceStr)
    }
    const tripId = trip.id
    if (tripId) {
      return turoTripDeviceData[tripId]
    }
    return null
  }

  const updateTuroTripDevice = (deviceId) => {
    let turoTripDeviceData = {}
    const turoTripDeviceStr = getSettingPersist('turoTripDevices')
    if (turoTripDeviceStr) {
      turoTripDeviceData = JSON.parse(turoTripDeviceStr)
    }
    const tripId = trip.id
    if (tripId) {
      turoTripDeviceData[tripId] = deviceId
    }

    const updatedTuroTripDeviceStr = JSON.stringify(turoTripDeviceData)
    setSettingPersist('turoTripDevices', updatedTuroTripDeviceStr)
    return turoTripDeviceData
  }

  const handleChangeCheckbox = (e) => {
    const field_name = e.target.name
    const checked = e.target.checked
    const form_data = { ...formData }
    form_data[field_name] = checked
    setFormData(form_data)
  }

  const updateBatchLink = async (payload) => {
    const linkId = payload['linkId']
    if (empty(linkId)) {
      return false
    }

    setApiLoading(true)
    const url = `/api/links/update-batch-link`;
    let response = await api_call(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    setApiLoading(false)

    if (response.ok) {
      const res = await response.json()
      //showToast("Link has been updated")
    }
  }

  const getDiffDays = (_from, _to) => {
    const fromStr = _from.format('YYYY-MM-DD HH:mm')
    const toStr = _to.format('YYYY-MM-DD HH:mm')
    console_log(`fromStr, toStr::::`, fromStr, toStr)
    const _from1 = moment(`${fromStr}:00`, 'YYYY-MM-DD HH:mm:ss')
    const _to1 = moment(`${toStr}:00`, 'YYYY-MM-DD HH:mm:ss')
    console_log(`_from1, _to1::::`, _from1, _to1)
    let diff_seconds = _to1.diff(_from1, 'seconds')
    if (diff_seconds < 0) {
      console_log(`diff_seconds::::`, diff_seconds)
      return 0
    }

    let diff_days = diff_seconds / (24 * 3600);  //let diff_days = _to1.diff(_from1, 'days')
    diff_days = Math.ceil(diff_days)

    console_log(`diff_days::::`, diff_days)
    // if (diff_days === 0) {
    //   diff_days = 1
    // }
    return diff_days
  }

  const getAllotMiles = (_from, _to, allot_per_day) => {
    if (is_null(_from)) {
      _from = from
    }
    if (is_null(_to)) {
      _to = to
    }

    const diff_days = getDiffDays(_from, _to)
    let allot_miles = diff_days * allot_per_day
    allot_miles = Math.ceil(allot_miles)
    return allot_miles
  }

  const getIanaTimezone = (deviceId) => {
    let iana_timezone = ""
    if (!empty(deviceId)) {
      const userDeviceItem = userDeviceList.find((x) => x.id === deviceId)
      if (userDeviceItem) {
        const share_settings = userDeviceItem.share_settings
        if (share_settings) {
          const shareSettingObj = JSON.parse(share_settings)
          if (shareSettingObj['timezone']) {
            iana_timezone = shareSettingObj['timezone']
          }
        }
      }
    }
    console_log(`iana_timezone::::`, iana_timezone)
    return iana_timezone
  }

  const onClickGenerateLink = async () => {
    var form_data = new FormData();
    form_data.append("user_id", user.id);

    const deviceId = device?.id
    if (empty(deviceId)) {
      showToast("Please choose a device")
      return false
    }
    form_data.append("device_id", deviceId);

    if (trip && trip.id) {
      console_log(`onClickGenerateLink trip:::`, trip)
      form_data.append("turo_trip_id", trip.id);
      const { summaryFirstWord, summaryLastWord } = getTuroSummaryFirstLastWord(trip)
      form_data.append("tripSummaryFirstWord", summaryFirstWord);
    }

    const renter_name = formData['renter_name']
    if (!empty(renter_name)) {
      form_data.append("renter_name", renter_name);
    }
    form_data.append("dob", dob.format("YYYY-MM-DD"));

    const auto_update = formData['auto_update'] ? '1' : '0'
    form_data.append("auto_update", auto_update)

    let allot_per_day = 200

    ///////////////// copy to clipboard should be done first due to ios higher version ////////////////////////////////////////////////
    const sharedUrlId = generateSharedUrlId(device?.name)
    const shared_url = generateSharedFullUrl(sharedUrlId)
    let sharedTextTemplate = getDefaultSharedTextForClipboard()
    const userDeviceItem = userDeviceList.find((x) => x.id === deviceId)
    if (userDeviceItem) {
      const share_settings = userDeviceItem.share_settings
      if (share_settings) {
        const shareSettingObj = JSON.parse(share_settings)
        if (shareSettingObj['sharedTextTemplate']) {
          sharedTextTemplate = shareSettingObj['sharedTextTemplate']
        }
        if (shareSettingObj['allot_per_day']) {
          allot_per_day = Number(shareSettingObj['allot_per_day'])
        }
      }
    }
    copyTextClipboard(shared_url, sharedTextTemplate)
    /////////////////////////////////////////////////////////////////
    let allot_miles = getAllotMiles(from, to, allot_per_day)
    form_data.append("allot_miles", allot_miles);

    const iana_timezone = getIanaTimezone(deviceId)
    form_data.append("timezone", iana_timezone);
    const fromUtc = getUtcFromTimezone(from.format('YYYY-MM-DD HH:mm:ss'), iana_timezone)
    form_data.append("from", fromUtc.toISOString());
    form_data.append("from_local", from.format('YYYY-MM-DD HH:mm:ss'));
    const toUtc = getUtcFromTimezone(to.format('YYYY-MM-DD HH:mm:ss'), iana_timezone)
    form_data.append("to", toUtc.toISOString());
    form_data.append("to_local", to.format('YYYY-MM-DD HH:mm:ss'));

    form_data.append("shareUrl", sharedUrlId);

    setApiLoading(true)
    const url = `/api/links/batch-generate`;
    let response = await api_call(url, {
      method: 'POST',
      body: form_data,
    })
    setApiLoading(false)

    if (response.ok) {
      const apiRes = await response.json()
      if (sharedUrlId === apiRes['shareUrl']) {
        setShareUrl(sharedUrlId)
        setGeneratedUrl(shared_url)
        showToast("Link has been copied…")

        setLinkIsCreated(true)
        setReloadTimestamp(get_utc_timestamp_ms())
        setUpdateTimestamp(Date.now());

        updateTuroTripDevice(deviceId)

        openTripReservationPage()
      } else {
        alert('Invalid request')
      }
    }
  }

  const onClickDeleteLink = async () => {
    if (window.confirm("Are you sure you want to delete?")) {
      if (oldBatchLink) {
        const payload = {
          linkId: oldBatchLink.id
        }
        if (trip && trip.id) {
          payload['turo_trip_id'] = trip.id
          setApiLoading(true)
          const url = `/api/links/delete-batch-link`;
          let response = await api_call(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          })
          setApiLoading(false)

          if (response.ok) {
            const res = await response.json()
            showToast("Link has been deleted")
            setLinkIsCreated(false)
            setReloadTimestamp(get_utc_timestamp_ms())
            setUpdateTimestamp(Date.now());
          }
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Grid item xs={12}>
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={1} direction={matchDownMD ? 'column' : 'row'}>
            <Grid item>
              <FormControl variant="filled" fullWidth>
                <InputLabel shrink>Trip</InputLabel>
                <Box sx={{ width: matchDownMD ? '100%' : '200px', padding: '9px', borderRadius: '4px', marginTop: '5px', backgroundColor: '#f6f6f6' }}>
                  {/* <Typography variant="body1" noWrap>{getTripSummaryText(trip)}</Typography> */}
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={getTripSummaryText(trip)}
                  >
                    <Link href={trip.url} variant="body1" noWrap target="_blank" style={{ display: 'block' }}>{getTripSummaryText(trip)}</Link>
                  </Tooltip>
                </Box>
              </FormControl>
            </Grid>
            <Grid item>
              <Box sx={{ minWidth: '100px' }}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel shrink>Auto Update</InputLabel>
                  <Box sx={{ pt: '6px' }}>
                    <Switch
                      checked={formData.auto_update ? true : false}
                      onChange={(e) => handleChangeCheckbox(e)}
                      name="auto_update"
                      color="primary"
                      inputProps={{ 'aria-label': 'Auto update' }}
                    />
                  </Box>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <FormControl variant="filled" fullWidth>
                <InputLabel shrink>Start</InputLabel>
                <TextField
                  variant="filled"
                  label=""
                  type="datetime-local"
                  value={from.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                  onChange={(e) => onChangeFrom(e)}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="filled" fullWidth>
                <InputLabel shrink>End</InputLabel>
                <TextField
                  variant="filled"
                  label=""
                  type="datetime-local"
                  value={to.format(moment.HTML5_FMT.DATETIME_LOCAL)}
                  onChange={(e) => onChnageTo(e)}
                  fullWidth
                />
              </FormControl>
            </Grid>

            {/* <Grid item>
                <FormControl variant="filled" fullWidth>
                  <InputLabel shrink>Devices</InputLabel>
                  <Select
                    value={deviceId}
                    onChange={(e) => onChaangeDeviceId(e)}
                    style={{ minWidth: '200px', marginTop: '5px' }}
                  >
                    {devices.map((device) => (
                      <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}

            <Grid item>
              <FormControl variant="filled" fullWidth>
                <InputLabel shrink>Device</InputLabel>
                <Autocomplete
                  // onOpen={() => {
                  //   setTimeout(() => {
                  //     const optionEl = document.querySelector(
                  //       `[class="MuiAutocomplete-option"][data-option-index="${TIMEZONE_LIST.indexOf(timezone?.label ?? localTimezone)}"]`
                  //     );
                  //     if (optionEl) {
                  //       optionEl.scrollIntoView();
                  //     }
                  //   }, 1);
                  // }}
                  onChange={(_, option) => {
                    console.log("option::::", option)
                    onChangeDevice(option)
                  }}
                  filterOptions={(options, params) => {
                    const filtered = deviceFilter(options, params);
                    return filtered;
                  }}
                  options={deviceOptions}
                  getOptionLabel={(option) => getDeviceLabel(option, "text")}
                  renderOption={(option) => getDeviceLabel(option, "text")}
                  value={device}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} variant="filled" />
                  )}
                  style={{ minWidth: '160px' }}
                  disabled={linkIsCreated}
                />
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl variant="filled" fullWidth>
                <InputLabel shrink>
                  <span>Renter Name</span>
                </InputLabel>
                <a href={`${trip.url}/car-sharing-agreement`} target="_blank" style={{ position: 'absolute', left: '85px', top: '-20px', transform: 'scale(0.7)' }}><HelpOutlineIcon /></a>

                <TextField
                  variant="filled"
                  label=""
                  type="text"
                  placeholder="optional"
                  value={get_data_value(formData, 'renter_name')}
                  onChange={(e) => onChangeRenterName(e)}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="filled" fullWidth>
                <InputLabel shrink>DOB</InputLabel>
                <TextField
                  variant="filled"
                  label=""
                  type="date"
                  value={dob.format("YYYY-MM-DD")}
                  onChange={(e) => onChnageDob(e)}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="filled" fullWidth>
                <InputLabel shrink>{` `}</InputLabel>
                <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1}>
                  <Tooltip title="Generate" placement="top">
                    <Button type="button" variant="contained" color="primary" disabled={apiLoading || linkIsCreated} onClick={() => onClickGenerateLink()} style={{ minWidth: '48px' }} >G</Button>
                  </Tooltip>
                  <Tooltip title="Delete" placement="top">
                    <Button type="button" variant="text" disabled={apiLoading || !linkIsCreated} onClick={() => onClickDeleteLink()} style={{ minWidth: '48px', paddingLeft: '6px', paddingRight: '6px' }}><CloseIcon color="error" /></Button>
                  </Tooltip>
                </Stack>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  )
}

export default BatchGenerateLinkRow;
