import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom"
import "../public/assets/css/main.css"
import 'react-modal-video/scss/modal-video.scss';
import { api_call, empty } from './helpers/untils';
import ModalVideo from 'react-modal-video';
import { HOME_VIDEO_ID } from './config/constant';

const FrontSiteNavbar = (props) => {
    const {setShoppingCartLink, setAppSetting} = props

    const history = useHistory()

    const LoginEvent = () => {
        history.push('/login')
    }

    const [isOpen, setOpen] = useState(false)
    const [youtubeId, setYoutubeId] = useState("")
    const [priceLink, setPriceLink] = useState("")

    useEffect(() => {
        getAppSetting();
    }, []);

    const getAppSetting = async () => {
        const response = await api_call(`/api/get-app-setting`);
        if (response.ok) {
            const app_setting = await response.json()
            setYoutubeId(app_setting['YOUTUBE_VIDEO_ID'])
            setPriceLink(app_setting['SHOPPING_CART_LINK'])
            if(setShoppingCartLink) {
                setShoppingCartLink(app_setting['SHOPPING_CART_LINK'])
            }
            if(setAppSetting) {
                setAppSetting(app_setting)
            }
        }
    }

    return (
        <React.Fragment>
            <header id="header" className="navbar navbar-expand-lg navbar-end navbar-absolute-top navbar-light navbar-show-hide"
                data-hs-header-options='{
    "fixMoment": 300,
    "fixEffect": "slide"
  }'>
                <div className="container">
                    <nav className="js-mega-menu navbar-nav-wrap">
                        <a className="navbar-brand cursor-pointer" onClick={() => history.push('/')} aria-label="LandingPage">
                            <img className="navbar-brand-logo" src="/assets/img/logo.png" alt="Logo" />
                        </a>
                        <button className="navbar-toggler custom-navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-default">
                                <i className="bi-list"></i>
                            </span>
                            <span className="navbar-toggler-toggled">
                                <i className="bi-x"></i>
                            </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <div className="navbar-absolute-top-scroller">
                                <ul className="navbar-nav">
                                    <li className="nav-item mb-1 mt-1">
                                        <a id="loginMenu" className="btn btn-primary " aria-current="page" onClick={LoginEvent} role="button" aria-expanded="false">Login</a>
                                    </li>
                                    <li className="nav-item mb-1 mt-1">
                                        <a id="contactMenu" className="btn btn-primary " aria-current="page" onClick={() => history.push('/contact')} role="button" aria-expanded="false">Contact</a>
                                    </li>
                                    <li className="nav-item mb-1 mt-1">
                                        <a id="pricingMenu" className="btn btn-primary " aria-current="page" onClick={() => history.push('/pricing')} role="button" aria-expanded="false">Pricing</a>
                                    </li>
                                    <li className="nav-item mb-1 mt-1">
                                        <a id="shopIntroMenu" className="btn btn-primary " aria-current="page" onClick={() => history.push('/shop-intro')} role="button" aria-expanded="false">Shop</a>
                                    </li>
                                    <li className="nav-item mb-1 mt-1">
                                        <a id="contactMenu" className="btn btn-primary " aria-current="page" onClick={() => setOpen(true)} role="button" aria-expanded="false">Video</a>
                                    </li>
                                    <li className="nav-item mb-1 mt-1">
                                        <Link className="btn btn-primary " to="/find-installer" target="_blank">Installers</Link>
                                    </li>
                                    <li className="nav-item mb-1 mt-1">
                                        <Link className="nav-link" to="/documentation">&nbsp;API&nbsp;</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
                <React.Fragment>
                    {
                        (isOpen) ? (
                            <>
                                <ModalVideo
                                    channel='youtube'
                                    autoplay={1}
                                    youtube={{
                                        autoplay: 1,
                                        mute: 1
                                    }}
                                    isOpen={isOpen}
                                    videoId={HOME_VIDEO_ID}
                                    onClose={() => setOpen(false)}
                                />
                            </>
                        ) : (<></>)
                    }

                </React.Fragment>
            </header>
        </React.Fragment>
    );
};

export default FrontSiteNavbar;
