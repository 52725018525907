import React from 'react';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import SignalCellular4BarIcon from '@material-ui/icons/SignalCellular4Bar';
import SignalCellular3BarIcon from '@material-ui/icons/SignalCellular3Bar';
import SignalCellular2BarIcon from '@material-ui/icons/SignalCellular2Bar';
import SignalCellular1BarIcon from '@material-ui/icons/SignalCellular1Bar';
import SignalCellular0BarIcon from '@material-ui/icons/SignalCellular0Bar';

import moment from 'moment';

const DeviceSignalBox = (props) => {
  const { device, callbackOnClickSingal, viewLocation = "StatusView" } = props

  const getDeviceSignal = () => {
    let device_signal = null
    if (moment().diff(moment(device.lastConnect), "seconds") < 600) {
      device_signal = device.signal
    } else {
      device_signal = device.signal_lowest
    }

    if (device_signal) {
      if (device_signal > 31) {
        device_signal = 31
      }
    } else {
      device_signal = 0
    }
    return device_signal
  }

  const deviceSignal = getDeviceSignal()

  const getSignalPercent = () => {
    let device_signal = getDeviceSignal()
    const percent = 100 * device_signal / 31
    const percentStr = percent.toFixed(0)
    return percentStr
  }

  const onClickSingal = () => {
    if (typeof callbackOnClickSingal === 'function') {
      callbackOnClickSingal()
    }
  }

  return (
    <>
      {
        (viewLocation === "StatusView") ? (
          <>
            <div className=''>
              <ListItemSecondaryAction style={{ cursor: 'pointer' }} onClick={(e) => onClickSingal()}>
                <div className='valign'>
                  {
                    deviceSignal === 0 && <SignalCellular0BarIcon /> ||
                    deviceSignal <= 8 && <SignalCellular1BarIcon /> ||
                    deviceSignal <= 16 && <SignalCellular2BarIcon /> ||
                    deviceSignal <= 24 && <SignalCellular3BarIcon /> ||
                    deviceSignal <= 31 && <SignalCellular4BarIcon />
                  }
                  <span className='valign'>
                    {/* <span className={classes[getStatusColor(device.status)]}>{device.status}</span> */}
                    <span className='vertical-text'>{getSignalPercent()}</span>
                    <span>{`%`}</span>
                  </span>
                </div>
              </ListItemSecondaryAction>
            </div>
          </>
        ) : (viewLocation === "InstallerStatusView") ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
              <span style={{ cursor: 'pointer', marginRight: 5 }} onClick={(e) => onClickSingal()}>{getSignalPercent()}</span>
              {
                deviceSignal === 0 && <SignalCellular0BarIcon /> ||
                deviceSignal <= 8 && <SignalCellular1BarIcon /> ||
                deviceSignal <= 16 && <SignalCellular2BarIcon /> ||
                deviceSignal <= 24 && <SignalCellular3BarIcon /> ||
                deviceSignal <= 31 && <SignalCellular4BarIcon />
              }
            </div>
          </>
        ) : (viewLocation === "TemporaryStatusView") ? (
          <>
            {
              deviceSignal === 0 && <SignalCellular0BarIcon /> ||
              deviceSignal <= 8 && <SignalCellular1BarIcon /> ||
              deviceSignal <= 16 && <SignalCellular2BarIcon /> ||
              deviceSignal <= 24 && <SignalCellular3BarIcon /> ||
              deviceSignal <= 31 && <SignalCellular4BarIcon />
            }
          </>
        ) : (
          <></>
        )
      }
    </>
  );
};

export default DeviceSignalBox;
