import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  makeStyles, withStyles, Paper, Grid, Button, FormControl, RadioGroup, FormControlLabel, Radio, Accordion, AccordionSummary, AccordionDetails, Typography, ButtonBase, Box, useTheme, useMediaQuery
} from '@material-ui/core';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from "@material-ui/core/LinearProgress";
import { styled } from "@material-ui/styles";
import { Snackbar } from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import {
  formatPosition, formatDate
} from '../common/formatter';
import { useAttributePreference } from '../common/preferences';
import { getPosition } from '../common/selectors';
import { useTranslation } from '../LocalizationProvider';
import moment from 'moment';
import { addItemToArray, api_call, console_log, getDistanceFromMiles, getSpeedFromMiles, get_utc_timestamp_ms, is_null, removeItemFromArray, validateEmail } from '../helpers/untils';
import { APP_NAME, DEVICE_TYPE, MACHINE_ENVIRONMENT } from '../config/constant';
import ConfirmDialog from '../components/ConfirmDialog';
import { Stack } from '@mui/material';
import { useRef } from 'react';
import { sleep } from '../helpers/misc';
import PaypalPaymentModal from '../settings/PaypalPaymentModal';
import GoogleGeolocationAddressBox from '../components/GoogleGeolocationAddressBox';
import DeviceSignalBox from '../components/DeviceSignalBox';

const useStyles = makeStyles((theme) => ({
  ...theme.palette.colors,
  statusViewPopup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '660px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '480px',
    },
    position: 'relative'
  },
  installerStatusViewContainer: {
    padding: '15px',
  },
  flex1: {
    flex: 1,
    width: '100%'
  },
  controlBtn: {
    background: 'rgba(76, 175, 80, 0.1)',
    border: '2px solid rgba(76, 175, 80, 1)',
    marginBottom: 10,
    // paddingTop: 3,
    // paddingBottom: 3,
  },
  signalBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: 1,
  },
  signalPercent: {
    cursor: 'pointer',
    marginRight: 5,
  },
  minusMarginTop: {
    marginTop: '-25px',
  },
  advancedBlock: {
    width: '100%',
    paddingBottom: '15px',
    '& > .MuiAccordion-root': {
      boxShadow: 'none'
    },
  },
  inviteBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    width: '100%',
    paddingRight: '15px',
    paddingLeft: '30px',
    paddingBottom: '0px',
    gap: '10px',
  },
  inviteBlockLabel: {
    minWidth: '110px'
  },
  inviteContainer: {
    paddingBottom: '15px',
  },
  paperCloseBtn: {
    cursor: 'pointer',
    position: 'absolute',
    background: '#ffffff',
    right: '-18px',
    top: '-18px',
    padding: '7px',
    borderRadius: '30px',
    border: '1px solid #cccccc',
    [theme.breakpoints.down('md')]: {
      right: '8px',
      top: '8px',
    },
    zIndex: 2
  },
  accordionDetailsContent: {
    marginLeft: '16px',
    marginTop: '-16px'
  },
  installerSignupLink: {
    fontSize: '1rem',
    textDecoration: 'underline',
    marginBottom: '1rem'
  },
  paymentBlock: {
    padding: '0 16px 16px 16px'
  }
}));

const MyListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "rgba(76, 175, 80, 0.2)",
    },
    "&$selected:hover": {
      backgroundColor: "rgba(76, 175, 80, 0.2)",
    },
    "&:hover": {
      backgroundColor: "rgba(76, 175, 80, 0.2)",
    }
  },
  selected: {}
})(ListItem);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  width: 150,
  borderRadius: 5,
}));

const MT2VInstallerStatusView = ({
  deviceId, onShowDetails, onShowHistory, onShareLink, onEditClick, onLogClick, onClickLogout, deviceInfo = {}, refreshTimestamp, setRefreshTimestamp, installer
}) => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme()
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const [removeDialogShown, setRemoveDialogShown] = useState(false);

  // const device_list = useSelector((state) => state.devices);
  // console_log("device_list:::", device_list);
  // const position_list = useSelector((state) => state.positions);
  // console_log("position_list:::", position_list);

  const device = useSelector((state) => state.devices.items[deviceId]);
  //console_log(`device::::`, device)
  const position = useSelector(getPosition(deviceId));

  const distanceUnit = useAttributePreference('distanceUnit');
  const speedUnit = useAttributePreference('speedUnit');

  const [commandsDetail, setCommandsDetail] = useState(null);

  const handleSendCommand = async () => {
    var url = `/api/installer-commandsDetail/${deviceId}`;
    const response = await api_call(url);
    if (response.ok) {
      setCommandsDetail(await response.json());
    }
  };

  ////////////// for loop call api ///////////////////////////////////////////////
  const c_loopFlag = useRef(null)
  const startLoopGetCommandStatus = async () => {
    while (c_loopFlag.current) { //loop 
      await handleSendCommand()
      await sleep(3000)
      // if (MACHINE_ENVIRONMENT === 'dev') {
      //   c_loopFlag.current = false
      // }
    }
  }

  useEffect(() => {
    c_loopFlag.current = true
    startLoopGetCommandStatus();
    return () => c_loopFlag.current = false
  }, []);
  ////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {

  }, []);

  const [item, setItem] = useState(device);
  useEffect(() => {
    const item_data = {
      ...device
    }
    if (deviceInfo && deviceInfo.id) {
      item_data['isDoubleLock'] = deviceInfo.isDoubleLock ? true : false
      item_data['isDoubleUnlock'] = deviceInfo.isDoubleUnlock ? true : false
      item_data['ignition_status'] = deviceInfo.ignition_status
    }
    setItem(item_data)
  }, [device, deviceInfo]);

  //////////////////////////////////////////////////////////
  const commands = ['Unlock', 'Lock', 'LightHorn', 'Unkill', 'Kill'];
  const commandLabels = {
    Unlock: "Unlock",
    Lock: "Lock",
    LightHorn: "Horn + Light",
    Unkill: "Unkill",
    Kill: "Kill",
  }

  const handleListItemClick = async (value, reloadCommnadList = true) => {
    let msg = "Sent Command"
    const response = await api_call(`/api/installer-command?command=${value}&deviceId=${device.id}&userId=-1`); // -1 means installer
    if (response.ok) {
      msg = "Sent Command"
    } else {
      msg = "Failed sending command"
    }
    // if (reloadCommnadList) {
    //   await handleSendCommand()
    // }
    //setNotificationMessage(msg);
    //setNotificationShow(true);
  }

  const getDetail = (command) => {
    // var tempCommand = command;
    // if(command === "Kill/Unkill") tempCommand = "Kill";
    for (var key in commandsDetail) {
      if (key === command) {
        if (key === "Overspeed") return commandsDetail[key].count + "mph";
        else if (key === "Geofence") return commandsDetail[key].count + "mile";
        return formatDate(commandsDetail[key].createdAt);
      }
    }
    return false;
  }
  const getStatus = (command) => {
    return commandsDetail[command].status;
  }

  const [notificationShow, setNotificationShow] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const showToast = (msg) => {
    setNotificationMessage(msg)
    setNotificationShow(true)
  }

  let sendingSingal = false;
  const OnClickSingal = async () => {
    if (sendingSingal) {
      return false;
    }
    sendingSingal = true;
    console_log("OnClickSingal::::");
    let value = "Iccid"
    let response = await api_call(`/api/installer-command?command=${value}&deviceId=${device.id}&userId=${0}`);
    if (response.ok) {
      console_log("Sent Iccid Command.");
    }
    value = "Rssid"
    response = await api_call(`/api/installer-command?command=${value}&deviceId=${device.id}&userId=${0}`);
    if (response.ok) {
      console_log("Sent Rssid Command.");
    }
    sendingSingal = false;
  }

  const onChangeDoubleCheckbox = (field_name, event) => {
    console_log("field_name, event", field_name, event.target.value)
    const field_value = (event.target.value === "true")
    updateDeviceDoubleStatus(field_name, field_value)
  }

  const updateDeviceDoubleStatus = async (field_name, field_value) => {
    console_log("field_name, field_value::::", field_name, field_value);
    const update_data = {
      id: item.id,
      uniqueId: item.uniqueId
    }
    update_data[field_name] = field_value

    let url = `/api/installer-devices/${item.id}`;
    const response = await api_call(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(update_data),
    });

    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      if (responseObj['error']) {
        alert(responseObj['error'])
      } else {
        setItem({ ...item, ...update_data })
        setRefreshTimestamp(get_utc_timestamp_ms())
      }
    } else {
      alert("Duplicated GPS ID")
    }
  }

  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteLicenseTag, setInviteLicenseTag] = useState("");
  const [inviteVin, setInviteVin] = useState("");

  const onClickInviteUser = async () => {
    if (inviteEmail === "") {
      alert("Enter the invlite email address");
      return false
    }
    if (!validateEmail(inviteEmail)) {
      alert("Please enter valid email address");
      return false
    }

    let url = `/api/users/invite-user-device`;
    const row = {
      email: inviteEmail,
      phone: "",
      name: "",
      uniqueId: item.uniqueId,
      license_tag: inviteLicenseTag.trim(),
      vin: inviteVin.trim().toUpperCase()
    }
    const payload = [row]
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      let responseObj = await response.json()
      console_log("responseObj::::", responseObj)
      let msg = responseObj?.message ?? responseObj?.error
      if (msg) {
        alert(msg)
      }
    } else {
      const errorMsg = response?.message ?? (response?.error ?? "Internal server error")
      alert(errorMsg)
    }
  }

  const [apiCalling, setApiCalling] = useState([])

  const apiLoading = (api_type, status) => {
    let api_calling = [...apiCalling]
    if (status) {
      api_calling = addItemToArray(api_calling, api_type)
    } else {
      api_calling = removeItemFromArray(api_calling, api_type)
    }
    setApiCalling(api_calling)
  }

  const getOpenVoltageConfirmText = (msg, volt) => {
    return (
      <Stack direction={`column`} spacing={2}>
        <Typography variant="body1">{msg}</Typography>
        {
          !is_null(volt) ? (
            <Typography variant="h6">{volt}V</Typography>
          ) : (
            <></>
          )
        }
      </Stack>
    )
  }

  const onClickDoorOpenVoltage = () => {
    setConfirmAction('door_voltage')
    const msg = "Has the Door been open for 5 or more minutes?"
    setConfirmText(getOpenVoltageConfirmText(msg, device?.mt2v_door_volt))
    setShowConfirmModal(true)
    // setCarVoltage("door_voltage")
  }

  const onClickHoodOpenVoltage = () => {
    setConfirmAction('hood_voltage')
    const msg = "Has the Hood been open for 5 or more minutes?"
    setConfirmText(getOpenVoltageConfirmText(msg, device?.mt2v_hood_volt))
    setShowConfirmModal(true)
    // setCarVoltage("hood_voltage")
  }

  const setCarVoltage = async (type) => {
    apiLoading(type, true)

    let url = `/api/installer-device/set-device-voltage`;
    const payload = {
      type: type,
      device_id: item.id,
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    apiLoading(type, false)

    if (response.ok) {
      let responseObj = await response.json()
      alert(`${responseObj.message}`)
    } else {
      alert("Error encounted")
    }
  }

  ///////////////////////////// confirmation ///////////////////////////////////
  const [confirmAction, setConfirmAction] = useState('')
  const [confirmText, setConfirmText] = useState("")
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const onClickAgreeConfirm = () => {
    if (confirmAction) {
      setCarVoltage(confirmAction)
      setShowConfirmModal(false)
    }
  }
  const onClickCancelConfirm = () => {
    setShowConfirmModal(false)
  }

  const onChangeTrueIgnition = async (event) => {
    const value = event.target.value
    let commandType = ''
    if (value === 'on') {
      commandType = 'TrueIgnitionOn'
    } else { //off
      commandType = 'TrueIgnitionOff'
    }
    await handleListItemClick(commandType, false)

    const item_data = {
      ...item
    }
    item_data['ignition_status'] = value
    setItem(item_data)
    setRefreshTimestamp(get_utc_timestamp_ms())
  }

  //////////////////////////////////////paypal payment ////////////////////////////////////////////////
  const [paypalPaymentModalOpen, setPaypalPaymentModalOpen] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState(0)

  const onClickPaypalPayment = () => {
    setPaypalPaymentModalOpen(true)
  }

  const paymentCallback = (result, apiRes) => {
    if (result === 'success') {
      if (apiRes && apiRes['device']) {
        const newDevice = apiRes['device']
        setItem(newDevice) // setItem({...item, credit: newDevice.credit })
      }
      showToast("Paid successfully!")
    } else {
      showToast("Paypal payment error!")
    }
  }

  return (
    <>
      <div className={classes.statusViewPopup}>
        <Paper className={classes.paper} elevation={0} square>
          <div className={classes.paperCloseBtn} onClick={() => onClickLogout()}> <CloseIcon /></div>
          <Grid container className={classes.installerStatusViewContainer} spacing={2}>
            <Grid item xs={12} md={6}>
              <List>
                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary={`${device.name}:`} />
                </ListItem>
                {
                  (device.deviceType === DEVICE_TYPE.MOOVETRAX) ? (
                    <>
                      <ListItem classes={{ container: classes.listItemContainer }}>
                        <ListItemText primary="Signal" />
                        <DeviceSignalBox
                          viewLocation="InstallerStatusView"
                          device={device}
                          callbackOnClickSingal={() => OnClickSingal()}
                        />
                      </ListItem>
                    </>
                  ) : (
                    <></>
                  )
                }

                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary="Last Position" />
                  <>
                    {formatDate(device.lastPosition)}
                  </>
                </ListItem>

                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary="Last Connect" />
                  <>
                    {formatDate(device.lastConnect)}
                  </>
                </ListItem>

                <ListItem classes={{ container: classes.listItemContainer }}>
                  {/* <ListItemText primary="Address" /> */}
                  <>
                    <GoogleGeolocationAddressBox
                      lat={device.latitude}
                      lng={device.longitude}
                      autoShow={true}
                    />
                  </>
                </ListItem>

                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary={t('positionSpeed')} />
                  {(moment().diff(moment(device.lastPosition), "seconds") < 60) && <>
                    {getSpeedFromMiles(position?.speed, device.distance_unit)}
                  </>}
                </ListItem>
                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary="Odometer" />
                  <>
                    {getDistanceFromMiles(position?.attributes?.distance, device.distance_unit)}
                  </>
                </ListItem>
                <ListItem classes={{ container: classes.listItemContainer }}>
                  <ListItemText primary={t('positionDirection')} />
                  <>
                    {formatPosition(position?.direction, 'direction', t)}
                  </>
                </ListItem>

                <div className='hidden d-none temporary-hidden'>
                  <ListItem classes={{ container: classes.listItemContainer }}>
                    <ListItemText primary="Door" />
                    <ListItemSecondaryAction>
                      {device.door ? "Closed" : "Open"}
                    </ListItemSecondaryAction>
                  </ListItem>
                </div>

                <MyListItem button disabled={apiCalling.includes("door_voltage")} onClick={() => onClickDoorOpenVoltage()} className={classes.controlBtn}>
                  <ListItemText primary={`Calibrate Door`} style={{ textAlign: 'center' }} />
                </MyListItem>
                <MyListItem button disabled={apiCalling.includes("hood_voltage")} onClick={() => onClickHoodOpenVoltage()} className={classes.controlBtn}>
                  <ListItemText primary={`Calibrate Hood`} style={{ textAlign: 'center' }} />
                </MyListItem>

              </List>
            </Grid>

            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <ListItemText primary={`Lock`} />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={item.isDoubleLock}
                      onChange={(event) => onChangeDoubleCheckbox("isDoubleLock", event)}
                      style={{ justifyContent: "space-around" }}
                      row
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Double" />
                      <FormControlLabel value={false} control={<Radio />} label="Single" />
                    </RadioGroup>
                  </FormControl>
                </ListItem>
                <ListItem className={classes.minusMarginTop}>
                  <ListItemText primary={`Unlock`} />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={item.isDoubleUnlock}
                      onChange={(event) => onChangeDoubleCheckbox("isDoubleUnlock", event)}
                      style={{ justifyContent: "space-around" }}
                      row
                    >
                      <FormControlLabel value={true} control={<Radio />} label="Double" />
                      <FormControlLabel value={false} control={<Radio />} label="Single" />
                    </RadioGroup>
                  </FormControl>
                </ListItem>

                <div>
                  {(commandsDetail) && commands.map((command) => (
                    <MyListItem button onClick={() => handleListItemClick(command)} key={command} className={classes.controlBtn}>
                      <ListItemText primary={commandLabels[command]} secondary={getDetail(command)} />
                      {getDetail(command) &&
                        (
                          getStatus(command) === 'success' ?
                            <DoneAllIcon color="secondary" /> :
                            <CheckIcon color="disabled" />
                        )
                      }
                    </MyListItem>
                  ))}
                </div>
              </List>
            </Grid>
          </Grid>
          {
            (item?.credit > 0) ? (
              <div className={classes.paymentBlock}>
                <Stack direction={`row`} spacing={2} justifyContent={`flex-start`} alignItems={`center`}>
                  <Typography variant="subtitle1">${item.credit}</Typography>
                  <ButtonBase type="button" disableRipple={true} onClick={() => onClickPaypalPayment()} style={{ borderRadius: '80px', overflow: 'hidden' }}>
                    <img src="/images/paynow-paypal.png" alt="pay now" style={{ width: '110px', height: 'auto' }} />
                  </ButtonBase>
                </Stack>
              </div>
            ) : (
              <></>
            )
          }

          <div className={classes.advancedBlock}>
            <Accordion>
              <AccordionSummary
                //expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="text-highlight">{`Advance >>`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.accordionDetailsContent}>
                  <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1}>
                    <div className={classes.formRowInline}>
                      <Typography style={{ marginRight: '16px' }}>True Ignition</Typography>
                    </div>
                    <div className={classes.formRowInline}>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={item.ignition_status}
                          onChange={(event) => onChangeTrueIgnition(event)}
                          style={{ justifyContent: "flex-start" }}
                          row
                        >
                          <FormControlLabel value="on" control={<Radio />} label="On" />
                          <FormControlLabel value="off" control={<Radio />} label="Off" />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Stack>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className={classes.inviteContainer}>
            <div className={classes.inviteBlock}>
              <Stack direction={matchesXs ? `column` : `row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1} style={{ width: '100%' }}>
                <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} style={{ width: '100%' }}>
                  <Typography className={classes.inviteBlockLabel} variant='body1' component={`span`}>License Plate</Typography>
                  <TextField
                    margin="none"
                    value={inviteLicenseTag}
                    onChange={(event) => setInviteLicenseTag(event.target.value)}
                    label=""
                    placeholder=''
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        fontStyle: 'normal',
                      }
                    }}
                    className={classes.flex1}
                  />
                </Stack>
                <Box sx={{ width: matchesXs ? '100%' : 'auto', display: matchesXs ? 'none' : 'block' }}>
                  <Button type="button" color="primary" variant="contained" fullWidth onClick={() => { onClickInviteUser() }} style={{ visibility: 'hidden' }}>
                    Submit
                  </Button>
                </Box>
              </Stack>
            </div>
            <div className={classes.inviteBlock}>
              <Stack direction={matchesXs ? `column` : `row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1} style={{ width: '100%' }}>
                <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} style={{ width: '100%' }}>
                  <Typography className={classes.inviteBlockLabel} variant='body1' component={`span`}>VIN</Typography>
                  <TextField
                    margin="none"
                    value={inviteVin}
                    onChange={(event) => setInviteVin(event.target.value.toUpperCase())}
                    label=""
                    placeholder=''
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        fontStyle: 'normal',
                      }
                    }}
                    className={classes.flex1}
                  />
                </Stack>
                <Box sx={{ width: matchesXs ? '100%' : 'auto', display: matchesXs ? 'none' : 'block' }}>
                  <Button type="button" color="primary" variant="contained" fullWidth onClick={() => { onClickInviteUser() }} style={{ visibility: 'hidden' }}>
                    Submit
                  </Button>
                </Box>
              </Stack>
            </div>
            <div className={classes.inviteBlock}>
              <Stack direction={matchesXs ? `column` : `row`} justifyContent={`flex-start`} alignItems={`center`} spacing={1} style={{ width: '100%' }}>
                <Stack direction={`row`} justifyContent={`flex-start`} alignItems={`center`} style={{ width: '100%' }}>
                  <Typography className={classes.inviteBlockLabel} variant='body1' component={`span`}>Invite Owner</Typography>
                  <TextField
                    margin="none"
                    value={inviteEmail}
                    onChange={(event) => setInviteEmail(event.target.value)}
                    label=""
                    placeholder='Enter car owner email address'
                    variant="filled"
                    InputLabelProps={{
                      style: {
                        fontStyle: 'normal',
                      }
                    }}
                    className={classes.flex1}
                  />
                </Stack>
                <Box sx={{ width: matchesXs ? '100%' : 'auto' }}>
                  <Button type="button" color="primary" variant="contained" fullWidth onClick={() => { onClickInviteUser() }}>
                    Submit
                  </Button>
                </Box>
              </Stack>
            </div>
          </div>
          <div className={classes.inviteContainer}>
            <div className={classes.inviteBlock}>
              <Link to="/installer-signup" className={classes.installerSignupLink} target="_blank">Installer Signup</Link>
            </div>
          </div>
        </Paper>
      </div>

      <>
        <PaypalPaymentModal
          open={paypalPaymentModalOpen}
          setOpen={setPaypalPaymentModalOpen}
          amount={item?.credit}
          itemType={`deviceCredit`}
          item={item}
          setItem={setItem}
          updateTimestamp={updateTimestamp}
          setUpdateTimestamp={setUpdateTimestamp}
          callback={paymentCallback}
          user_type={`installer`}
          user_id={installer?.id}
        />
      </>

      <>
        <ConfirmDialog
          open={showConfirmModal}
          setOpen={setShowConfirmModal}
          title={APP_NAME}
          content={confirmText}
          textYes={`Yes`}
          textNo={`Cancel`}
          onClickYes={() => onClickAgreeConfirm()}
          onClickNo={() => onClickCancelConfirm()}
        />
      </>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={notificationShow}
        message={notificationMessage}
        autoHideDuration={1500}
        onClose={() => { setNotificationShow(false) }}
      />
    </>

  );
};

export default MT2VInstallerStatusView;
