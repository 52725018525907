import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, FormControlLabel, Checkbox, Box, Modal, Grid, Toolbar, IconButton
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditItemView from './EditItemView';
import EditAttributesView from './attributes/EditAttributesView';
import SelectField from './form/SelectField';
import deviceCategories from './common/deviceCategories';
import LinkField from './form/LinkField';
import { prefixString } from './common/stringUtils';
import { useTranslation } from './LocalizationProvider';
import useDeviceAttributes from './attributes/useDeviceAttributes';
import { Button } from '@material-ui/core';
import CreditLogsModal from './settings/CreditLogsModal';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import CarTypeTab from './components/CarTypeTab';
import AdditionMoovetraxModal from './settings/AdditionMoovetraxModal';
import { Stack } from '@mui/material';
import { getIsAdmin } from './common/selectors';
import { addItemToArray, api_call, removeItemFromArray } from './helpers/untils';
import SearchIcon from '@material-ui/icons/Search';
import { DEVICE_TYPE } from './config/constant';

const useStyles = makeStyles(() => ({
  checkboxColumn: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  details: {
    flexDirection: 'column',
  },
}));

const SmartcarDeviceEditPage = (props) => {
  const { id, item, setItem, showCreditLogsPage, getCarColorListData, randomStr, t, onClickGoUserDevicePage } = props;

  const classes = useStyles();
  const user = useSelector((state) => state.session.user);
  const isAdmin = useSelector(getIsAdmin);

  const [apiCalling, setApiCalling] = useState([])

  const apiLoading = (api_type, status) => {
    let api_calling = [...apiCalling]
    if (status) {
      api_calling = addItemToArray(api_calling, api_type)
    } else {
      api_calling = removeItemFromArray(api_calling, api_type)
    }
    setApiCalling(api_calling)
  }

  ////////////////////////////////addition moovetrax modal////////////////////////////////
  const onClickMoovetraxDevice = () => {
    setMoovetraxModalOpen(true)
  }
  const [moovetraxModalOpen, setMoovetraxModalOpen] = useState(false);
  ////////////////////////////////////////////////////////////////////////////////

  const onChangeAbiCheckbox = (event) => {
    const checked = event.target.checked
    // if(checked) {
    //   if(empty(item.vin)) {
    //     alert("please update your VIN.")
    //     return false
    //   }
    // }
    setItem({ ...item, abi: checked })
  }

  const [allAbiChecked, setAllAbiChecked] = useState(false)
  const onChangeAllAbiCheckbox = (event) => {
    const checked = event.target.checked
    setAllAbiChecked(checked)
    setItem({ ...item, allAbiChecked: checked })
  }

  const onChangeTintAiCheckbox = (event) => {
    const checked = event.target.checked
    // if(checked) {
    //   if(empty(item.vin)) {
    //     alert("please update your VIN.")
    //     return false
    //   }
    // }
    setItem({ ...item, tint_ai: checked })
  }

  const onClickAllUserCarABI = async (type) => {
    apiLoading(type, true)

    let url = `/api/users/set-user-devices-abi`;
    const payload = {
      abi: item.abi
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    apiLoading(type, false)

    if (response.ok) {
      let responseObj = await response.json()
      alert(`${responseObj.message}`)
    } else {
      alert("Error encounted")
    }
  }

  const onClickAllUserCarTintAi = async (type) => {
    apiLoading(type, true)

    let url = `/api/users/set-user-devices-tint-ai`;
    const payload = {
      tint_ai: item.tint_ai
    }
    const response = await api_call(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    apiLoading(type, false)

    if (response.ok) {
      let responseObj = await response.json()
      alert(`${responseObj.message}`)
    } else {
      alert("Error encounted")
    }
  }

  return (
    <>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Typography variant="subtitle1">
            OEM Car App
          </Typography>
          {(item && item['id']) ? (
            <Button variant="text" onClick={() => { showCreditLogsPage() }}>Balance:&nbsp;<span>{item.credit || '0.00'}</span></Button>
          ) : (
            <Button variant="text">Balance:&nbsp;<span>0.00</span></Button>
          )}

          <TextField
            id="outlined-required"
            label="Monthly ($)"
            value={item.monthly_cost || '10.00'}
            onChange={(event) => setItem({ ...item, monthly_cost: event.target.value })}
            type="number"
            inputProps={{ step: 0.01 }}
            style={{ width: "100px" }}
            disabled={!user.administrator && "true"}
          />
        </Box>
      </AccordionDetails>
      <AccordionDetails className={classes.details}>
        <TextField
          required
          margin="normal"
          value={item.name || ''}
          onChange={(event) => setItem({ ...item, name: event.target.value })}
          label="CAR Name"
          variant="filled"
          InputLabelProps={{
            style: {
              fontStyle: 'italic',
            }
          }
          }
        />
        <TextField
          required
          margin="normal"
          value={item.vehicleId || ''}
          onChange={(event) => setItem({ ...item, vehicleId: event.target.value })}
          label="Vehicle ID"
          variant="filled"
          // disabled={id && !user.administrator && "true"}
          disabled={"true"}
          InputLabelProps={{
            style: {
              fontStyle: 'italic',
            }
          }
          }
        />

        <div item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
          <TextField
            margin="normal"
            value={item.vin || ''}
            onChange={(event) => setItem({ ...item, vin: event.target.value })}
            label="VIN"
            variant="filled"
            fullWidth
            disabled={item.deviceType === DEVICE_TYPE.SMARTCAR || item.deviceType === DEVICE_TYPE.TESLA}
          />

          {
            (user.administrator) ? (
              <>
                {
                  (item?.id) ? (
                    <div style={{ paddingLeft: '8px', paddingTop: '12px' }}>
                      <Stack direction={`row`} justifyContent={`center`} alignItems={`center`} spacing={2}>
                        <IconButton size={`medium`} type="button" onClick={() => onClickGoUserDevicePage()}>
                          <SearchIcon />
                        </IconButton>
                      </Stack>
                    </div>
                  ) : (
                    <></>
                  )
                }
              </>
            ) : (
              <></>
            )
          }

        </div>

        <TextField
          margin="normal"
          value={item.odometer ? Number(item.odometer).toFixed(0) : ''}
          onChange={(event) => setItem({ ...item, odometer: event.target.value })}
          label="Odometer"
          variant="filled"
        />

        <TextField
          margin="normal"
          value={item.make || ''}
          onChange={(event) => setItem({ ...item, make: event.target.value })}
          label={t('deviceMake')}
          variant="filled"
        />
        <TextField
          margin="normal"
          value={item.model || ''}
          onChange={(event) => setItem({ ...item, model: event.target.value })}
          label={t('deviceModel')}
          variant="filled"
        />
        <SelectField
          margin="normal"
          value={item.color || 'Black'}
          emptyValue={null}
          onChange={(event) => setItem({ ...item, color: event.target.value })}
          data={getCarColorListData()}
          label='color'
          variant="filled"
        />
        <SelectField
          margin="normal"
          value={item.category || 'Default'}
          emptyValue={null}
          onChange={(event) => setItem({ ...item, category: event.target.value })}
          data={deviceCategories.map((category) => ({
            id: category,
            name: category,
          }))}
          label={t('deviceCategory')}
          variant="filled"
        />

        <div className=''>
          <Button variant="text" onClick={() => onClickMoovetraxDevice()}>{`MooveTrax Device >>`}</Button>
        </div>

        {id &&
          <>
            <TextField
              margin="normal"
              value={item.fuel}
              // onChange={(event) => setItem({ ...item, maxFuel: event.target.value })}
              label='Current Fuel'
              variant="filled"
              disabled="true"
            />
          </>
        }
        {id &&
          <>
            <TextField
              margin="normal"
              value={item.apiKey || ''}
              label="Api-Key"
              variant="filled"
              disabled="true"
            />
            <Button variant="contained" onClick={() => { setItem({ ...item, apiKey: randomStr(16) }) }}>Generate</Button>
          </>
        }

        {id &&
          <>
            <div className={classes.checkboxColumn}>
              <Stack direction={`row`} style={{ width: '100%' }}>
                <FormControlLabel
                  control={<Checkbox checked={item.abi} onChange={(event) => onChangeAbiCheckbox(event)} />}
                  label="Share With ABI Insurance"
                />
                {
                  !isAdmin ? (
                    <>
                      <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{ borderRadius: '80px', height: '30px', textTransform: 'none', lineHeight: 1 }}
                        disabled={apiCalling.includes("all_car_abi")}
                        onClick={() => onClickAllUserCarABI('all_car_abi')}
                      >
                        All Cars
                      </Button>
                    </>
                  ) : (
                    <></>
                  )
                }
              </Stack>
              <Tooltip
                arrow
                title="By enabling this option you will be sharing your car position and other details with American Business Insurance."
              >
                <InfoIcon color="action" />
              </Tooltip>
            </div>
            <div className={classes.checkboxColumn}>
              <Stack direction={`row`} style={{ width: '100%' }}>
                <FormControlLabel
                  control={<Checkbox checked={item.tint_ai} onChange={(event) => onChangeTintAiCheckbox(event)} />}
                  label="Share With Tint.Ai"
                />
                {
                  !isAdmin ? (
                    <>
                      <Button
                        type="button"
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{ borderRadius: '80px', height: '30px', textTransform: 'none', lineHeight: 1 }}
                        disabled={apiCalling.includes("all_car_tint_ai")}
                        onClick={() => onClickAllUserCarTintAi('all_car_tint_ai')}
                      >
                        All Cars
                      </Button>
                    </>
                  ) : (
                    <></>
                  )
                }
              </Stack>
              <Tooltip
                arrow
                title="By enabling this, you will be sharing your car position and other information with Tint.Ai"
              >
                <InfoIcon color="action" />
              </Tooltip>
            </div>
          </>
        }

        {id && <FormControlLabel
          control={<Checkbox checked={item.disabled} onChange={(event) => setItem({ ...item, disabled: event.target.checked })} />}
          label={t('sharedDisabled')}
          disabled={id && !user.administrator && "true"}
        />}


        {
          (moovetraxModalOpen) && (
            <>
              <AdditionMoovetraxModal
                open={moovetraxModalOpen}
                setOpen={setMoovetraxModalOpen}
                item={item}
                setItem={setItem}
              />
            </>
          )
        }


      </AccordionDetails>
    </>
  );
};

export default SmartcarDeviceEditPage;
