import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import {
  makeStyles, FormControlLabel, Checkbox, TextField, Button, IconButton
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { api_call, console_log, createArrayFromCount, createEmptyArrayFromCount, empty, is_null } from '../helpers/untils';

const useStyles = makeStyles((theme) => ({
  formRowInline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    //  marginRight: '30px',

  },
  formRowInlineController: {
    flex: 1,
    paddingRight: '8px'
  },
  textList: {
    display: 'block',
    paddingTop: '8px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },

}));


const UnlimitedTuroCalTextBoxList = (props) => {
  const { defaultName, defaultCount, defaultValues, inputProps, setValues, placeholder = "", showAddButton = true } = props;
  //console.log("defaultValues:::", defaultValues)
  const classes = useStyles();
  useEffect(() => {
    initForm()
  }, [defaultValues])

  const [fieldValues, setFieldValues] = useState([])

  const initForm = () => {
    if (is_null(defaultValues)) {
      setFieldValues(createEmptyArrayFromCount(defaultCount, { turo_email: "", url: "" }))
    } else {
      setFieldValues(defaultValues)
    }
  }

  const onClickRemoveItem = (index) => {
    const field_values = [...fieldValues]
    field_values.splice(index, 1)
    //console.log("field_values:::", field_values)
    setFieldValues(field_values)
    setValues(field_values)
  }

  const onClickAddNew = () => {
    const field_values = [...fieldValues]
    field_values.push(
      {
        turo_email: "",
        url: ""
      }
    )
    //console.log("field_values:::", field_values)
    setFieldValues(field_values)
    setValues(field_values)
  }

  const onChangeTuroForm = (event, index, field_name) => {
    const field_values = [...fieldValues]
    field_values[index][field_name] = event.target.value
    setFieldValues(field_values)
    setValues(field_values)
    //console.log("field_values", field_values)
  }

  const DrawForm = () => { //field_name, field_count, field_values
    //let formIndexList = createArrayFromCount(defaultValues.length)
    //console.log("formIndexList:::", formIndexList)
    const field_values = [...fieldValues]

    return (
      field_values.map((item, index) => {
        return (
          <div className={classes.formRowInline} key={index}>
            <TextField
              fullWidth
              className={classes.formRowInlineController}
              value={fieldValues[index]['turo_email']}
              onChange={(event) => onChangeTuroForm(event, index, 'turo_email')}
              label=""
              variant="filled"
              inputProps={{
                ...inputProps,
                type: "email",
                placeholder: "Turo Email"
              }}
            />
            <TextField
              fullWidth
              className={classes.formRowInlineController}
              value={fieldValues[index]['url']}
              onChange={(event) => onChangeTuroForm(event, index, 'url')}
              label=""
              variant="filled"
              inputProps={{
                ...inputProps,
                placeholder: "Optional Turo Calendar"
              }}
            />
            <div className={classes.formActionBox}>
              <IconButton className={classes.removeButton} color="primary" size="medium" onClick={() => onClickRemoveItem(index)}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        )
      })
    )
  }

  return (
    <>
      <div className={classes.textList}>
        {
          DrawForm()
        }
        
        {
          (showAddButton) ? (
            <div className={classes.formRowInline}>
              <Button type="button" color="primary" variant="contained" startIcon={<AddIcon />} onClick={() => { onClickAddNew() }}>
                Add New
              </Button>
            </div>
          ) : (
            <></>
          )
        }
      </div>
    </>
  )
}

export default UnlimitedTuroCalTextBoxList;
