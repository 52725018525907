import React, { useEffect, useState } from 'react';
import {
  Grid, IconButton, makeStyles, Paper, Toolbar, Typography, Snackbar} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Map from '../map/Map';
import ShareFilter from './ShareFilter';
import { useTranslation } from '../LocalizationProvider';
import { addItemToArray, api_call, console_log, empty, generateSharedFullUrl, generateSharedUrlId, get_utc_timestamp } from '../helpers/untils';
import copy from 'copy-to-clipboard';
import { SHARED_LINK_TEXT_KEY } from '../config/constant';
import { getTuroSummaryFirstLastWord } from '../helpers/misc';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  title: {
    ...theme.typography.title,
  },
  sidebar: {
    position: 'absolute',
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  formControlLabel: {
    height: '100%',
    width: '100%',
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  shareFilterContainer: {
    flex: 1,
    padding: theme.spacing(2),
    minWidth: '400px',
    maxHeight: 'calc(100vh - 110px)',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
      minWidth: '0px',
      maxHeight: 'calc(100vh - 90px)',
    },
  },
  sliderContainer: {
    padding: theme.spacing(2),
  },
  mobileBlock: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

const SharePage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams();

  const [selectedDeviceId, setSelectedDeviceId] = useState(id);
  const [selectedDevice, setSelectedDevice] = useState();
  const [tripList, setTripList] = useState([]);
  const [trip, setTrip] = useState(null);

  const [shareUrl, setShareUrl] = useState("");
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [pageData, setPageData] = useState(null);

  const [apiLoading, setApiLoading] = useState(false)

  const userId = useSelector((state) => state.session.user.id);

  useEffect(() => {
    if (selectedDeviceId) {
      loadPageData(selectedDeviceId)
    }
    // console.log("TIMEZONE_LIST:::", TIMEZONE_LIST)
  }, [selectedDeviceId])

  const sortWithTripStartDateAsc = (a, b) => {
    const curTimestamp = get_utc_timestamp()

    if (a < curTimestamp) {
      return 1
    }
    else if (b < curTimestamp) {
      return -1
    }
    else {
      return a - b
    }
  }

  const sortTuroTripList = (deviceTripList) => {
    if (deviceTripList && deviceTripList.length > 0) {
      deviceTripList = deviceTripList.sort((a, b) => {
        return sortWithTripStartDateAsc(a.start_timestamp, b.start_timestamp)
      })
    }
    return deviceTripList
  }

  const usedTripKey = `usedTripIds`
  const [usedTripIds, setUsedTripIds] = useState([]) // useState(getSettingPersist(usedTripKey, []))

  const loadPageData = async (deviceId) => {
    setSelectedDeviceId(deviceId);
    const url = `/api/get-share-device-page-data/${deviceId}`
    const response = await api_call(url);
    if (response.ok) {
      const res = await response.json();
      setSelectedDevice(res.device)
      setTripList(sortTuroTripList(res.turoTripList))
      setUsedTripIds(res.usedTripIdList)
      setPageData(res)
    } else {

    }
  }

  const deviceName = useSelector((state) => {
    if (selectedDeviceId) {
      const device = state.devices.items[selectedDeviceId];
      if (device) {
        return device.name;
      }
    }
    return null;
  });

  const getTextForClipboard = (shared_url, sharedTextTemplate) => {
    if (shared_url) {
      const clipboardText = sharedTextTemplate.replace(SHARED_LINK_TEXT_KEY, shared_url)
      return clipboardText
    } else {
      return ""
    }
  }

  const copyTextClipboard = (shared_url, sharedTextTemplate) => {
    let clipboardText = getTextForClipboard(shared_url, sharedTextTemplate)
    if (clipboardText) {
      //navigator.clipboard.writeText(clipboardText);
      copy(clipboardText);
      return true
    } else {
      return false
    }
  }

  const scrollDown = () => {
    var objDiv = document.getElementById("shareFilterContainer");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  const openTripReservationPage = () => {
    if (trip && trip.url) {
      const url = trip.url
      setTimeout(function () {
        var newWindow = window.open(url, '_blank');
      }, 1200)
    }
  }

  const updateUsedTripIds = () => {
    if (trip && trip.id) {
      // let used_trip_ids = getSettingPersist(usedTripKey, [])
      // if (empty(used_trip_ids)) {
      //   used_trip_ids = []
      // }
      // used_trip_ids = addItemToArray(used_trip_ids, trip.id)
      // setSettingPersist(usedTripKey, used_trip_ids)
      // setUsedTripIds(used_trip_ids)

      let used_trip_ids = [...usedTripIds]
      used_trip_ids = addItemToArray(used_trip_ids, trip.id)
      setUsedTripIds(used_trip_ids)
    }
  }

  const handleSubmit = async (form_data) => {
    //setSelectedDeviceId(form_data.deviceId);
    if (trip && trip.id) {
      console.log(`handleSubmit trip:::`, trip)
      form_data.append("turo_trip_id", trip.id);
      const { summaryFirstWord, summaryLastWord } = getTuroSummaryFirstLastWord(trip)
      form_data.append("tripSummaryFirstWord", summaryFirstWord);
    }

    const shareSettingStr = form_data.get("shareSetting")
    const shareSettingObj = JSON.parse(shareSettingStr)
    const sharedTextTemplate = shareSettingObj['sharedTextTemplate']

    ///////////////// copy to clipboard should be done first due to ios higher version ////////////////////////////////////////////////
    const sharedUrlId = generateSharedUrlId(selectedDevice?.name)
    const shared_url = generateSharedFullUrl(sharedUrlId, shareSettingObj['useSecondDomain'])
    copyTextClipboard(shared_url, sharedTextTemplate)
    /////////////////////////////////////////////////////////////////

    form_data.append("shareUrl", sharedUrlId);
    setApiLoading(true)
    const url = `/api/links/updateSetting`;
    let response = await api_call(url, {
      method: 'POST',
      body: form_data,
    });

    setApiLoading(false)
    if (response.ok) {
      const responseObj = await response.json()
      console_log(`updateSetting responseObj:::`, responseObj)
      if (responseObj.id && responseObj.shareUrl && responseObj.shareUrl === sharedUrlId) {
        setShareUrl(sharedUrlId)
        setGeneratedUrl(shared_url)
        setToastMessage("Link has been copied…")
        setSnackbarOpen(true);
        scrollDown()

        updateUsedTripIds()
        openTripReservationPage()
      } else {
        alert("Invalid request")
      }
    } else {
      let errorObj = response
      let errorMsg = errorObj['error'] ?? errorObj['message']
      if (errorMsg) {
        alert(errorMsg)
      } else {
        alert("Unknown error")
      }
    }
  }

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const showToast = (msg) => {
    setToastMessage(msg)
    setSnackbarOpen(true);
  }

  const onClickSMS = (sharedTextTemplate) => {
    const shared_url = generatedUrl
    let phoneNumber = trip?.phone

    let smsBaseUrl = ""
    if (empty(phoneNumber)) {
      smsBaseUrl = `sms:`
    } else {
      smsBaseUrl = `sms://${phoneNumber}/`
    }

    if (shared_url) {
      let windowUrl = ""
      const smsBody = getTextForClipboard(shared_url, sharedTextTemplate)
      if (navigator.userAgent.match(/Android/i)) {
        windowUrl = `${smsBaseUrl}?body=${encodeURIComponent(smsBody)}`
      }
      else if (navigator.userAgent.match(/iPhone/i)) {
        windowUrl = `${smsBaseUrl}&body=${encodeURIComponent(smsBody)}`
      }

      //showToast(windowUrl)

      if (windowUrl) {
        window.open(windowUrl, '_blank')
      } else {
        showToast("Unsupported device!")
      }
    }
  }

  const onClickVoice = (sharedTextTemplate) => {
    const shared_url = generatedUrl
    let phoneNumber = trip?.phone

    let voiceBaseUrl = ""
    if (empty(phoneNumber)) {
      //voiceBaseUrl = `googlevoice:`
      voiceBaseUrl = `https://voice.google.com/u/0/messages?itemId=t.%2B`
      //voiceBaseUrl = `https://voice.google.com/u/0/calls?a=nc,%2B`
    } else {
      //voiceBaseUrl = `googlevoice://${phoneNumber}/`
      voiceBaseUrl = `https://voice.google.com/u/0/messages?itemId=t.%2B${phoneNumber}`
      //voiceBaseUrl = `https://voice.google.com/u/0/calls?a=nc,%2B${phoneNumber}`
    }

    if (shared_url) {
      let windowUrl = ""
      const voiceBody = getTextForClipboard(shared_url, sharedTextTemplate)
      if (navigator.userAgent.match(/Android/i)) {
        //windowUrl = `${voiceBaseUrl}?body=${encodeURIComponent(voiceBody)}`
        windowUrl = `${voiceBaseUrl}`
      }
      else if (navigator.userAgent.match(/iPhone/i)) {
        //windowUrl = `${voiceBaseUrl}&body=${encodeURIComponent(voiceBody)}`
        windowUrl = `${voiceBaseUrl}`
      }

      //showToast(windowUrl)

      if (windowUrl) {
        window.open(windowUrl, '_blank')
      } else {
        showToast("Unsupported device!")
      }
    }
  }

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        autoHideDuration={1500}
        message={toastMessage}
      />

      <Map />
      <div className={classes.sidebar}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Paper elevation={3} square>
              <Toolbar disableGutters>
                <Grid container alignItems="center">
                  <Grid item>
                    <IconButton onClick={() => history.push('/main')}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs>
                    <Typography className={classes.share} color="primary">
                      Share Link
                    </Typography>
                  </Grid>
                </Grid>
              </Toolbar>
            </Paper>
          </Grid>
          <Grid item>
            <Paper elevation={3} className={classes.shareFilterContainer} id="shareFilterContainer" square>
              <ShareFilter
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                toastMessage={toastMessage}
                setToastMessage={setToastMessage}
                handleSubmit={handleSubmit}
                fullScreen
                showOnly
                selectedDeviceId={id}
                setSelectedDeviceId={setSelectedDeviceId}
                selectedDevice={selectedDevice}
                tripList={tripList}
                trip={trip}
                setTrip={setTrip}
                history={history}
                shareUrl={shareUrl}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
                pageData={pageData}
                setPageData={setPageData}
                generatedUrl={generatedUrl}
                showToast={showToast}
                copyTextClipboard={copyTextClipboard}
                onClickSMS={onClickSMS}
                onClickVoice={onClickVoice}
                usedTripIds={usedTripIds}
              >
              </ShareFilter>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default SharePage;
